import { useMutation, useQuery, useQueryClient } from "react-query";
import { Auth } from "shared/@auth/Auth";

export function useHomesitesGet() {
  const { makeAuthedRequest } = Auth.useContainer();
  const queryClient = useQueryClient();

  const { data, isError, isLoading, refetch } = useQuery(
    ["homesites"],
    () =>
      makeAuthedRequest(`v1/events/homesites`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    { staleTime: 0 }
  );

  return {
    data,
    isLoading,
    refetch,
  };
}
