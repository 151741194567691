import { useState, useMemo } from "react";
import { Section } from "shared/layout";
import Button, { ButtonPurpose, ButtonSize } from "shared/Button";
import TextInput from "shared/@forms/TextInput";
import Table from "shared/Table";
import styles from "../index.module.css";
import PhoneInput from "shared/@forms/PhoneInput";
import { Event } from "Events/Components/useEvent";

export default function Security({ eventId, scanningSecurities }) {
  const { saveSecurity, deleteSecurity } = Event.useContainer();
  const [editingId, setEditingId] = useState(null);
  const [tempAdd, setTempAdd] = useState(null);

  function onCancel() {
    setEditingId(null);
    setTempAdd(null);
  }

  function onAddClick() {
    setTempAdd({ id: 0 });
  }

  function onDelete(id) {
    deleteSecurity.mutate(id);
  }

  function onSave(temp) {
    if (!temp.firstName || temp.firstName.length == 0) {
      return;
    }

    if (!temp.lastName || temp.lastName.length == 0) {
      return;
    }

    temp.eventId = eventId;

    if (temp.phone) {
      const phone = temp.phone.replace(/\D/g, "");
      if (phone.length != 10) {
        //invalid US phone number
        return;
      }

      temp.areaCode = phone.substring(0, 3);
      temp.prefix = phone.substring(3, 6);
      temp.suffix = phone.substring(6);
    }

    saveSecurity.mutate({
      id: temp.id,
      eventId: temp.eventId,
      firstName: temp.firstName,
      lastName: temp.lastName,
      areaCode: temp.areaCode,
      prefix: temp.prefix,
      suffix: temp.suffix,
    });
    onCancel(); //clear form
  }

  return (
    <>
      <Section className={styles.sectionTopMargin}>
        <header>
          <h3>Staffing</h3>
          <div>
            <Button
              size="small"
              onClick={() => {
                onAddClick();
              }}
            >
              Add
            </Button>
          </div>
        </header>
        <Table>
          <thead>
            <tr>
              <th>Edit</th>
              <th>Name</th>
              <th>Phone</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {scanningSecurities &&
              scanningSecurities.length > 0 &&
              scanningSecurities.map((a) => {
                if (editingId != a.id)
                  return (
                    <tr key={a.id}>
                      <td width="25px">
                        <Button size="small" onClick={() => setEditingId(a.id)}>
                          Edit
                        </Button>
                      </td>
                      <td>
                        {a.firstName} {a.lastName}
                      </td>
                      <td>
                        {a.areaCode}.{a.prefix}.{a.suffix}
                      </td>
                      <td className={styles.buttonsColumn}>
                        <Button
                          size={ButtonSize.small}
                          purpose={ButtonPurpose.delete}
                          onClick={() => onDelete(a.id)}
                        >
                          Remove
                        </Button>
                      </td>
                    </tr>
                  );
                else
                  return (
                    <SecurityEdit
                      security={scanningSecurities.find(
                        (s) => s.id == editingId
                      )}
                      onCancel={onCancel}
                      onSave={onSave}
                    ></SecurityEdit>
                  );
              })}
            {tempAdd != null && (
              <SecurityEdit
                security={tempAdd}
                onCancel={onCancel}
                onSave={onSave}
              ></SecurityEdit>
            )}
          </tbody>
        </Table>
      </Section>
    </>
  );
}

function SecurityEdit({ security, onCancel, onSave }) {
  const [temp, setTemp] = useState({
    ...security,
    firstName: security.firstName || "",
    lastName: security.lastName || "",
    phone:
      security.areaCode && security.prefix && security.suffix
        ? security.areaCode + security.prefix + security.suffix
        : "",
  });

  return (
    <tr key={security.id}>
      <td width="25px"></td>
      <td>
        <TextInput
          placeholder="First Name"
          style={{ width: "50%" }}
          value={temp.firstName}
          onChange={(e) => setTemp({ ...temp, firstName: e.target.value })}
        ></TextInput>
        <TextInput
          placeholder="Last Name"
          style={{ width: "50%" }}
          value={temp.lastName}
          onChange={(e) => setTemp({ ...temp, lastName: e.target.value })}
        ></TextInput>
      </td>
      <td>
        <PhoneInput
          placeholder="555.555.5555"
          value={temp.phone}
          onChange={(e) => setTemp({ ...temp, phone: e.target.value })}
        ></PhoneInput>
      </td>
      <td className={styles.buttonsColumn}>
        <Button
          size={ButtonSize.small}
          purpose={ButtonPurpose.save}
          onClick={() => onSave(temp)}
        >
          Save
        </Button>
        <Button
          size={ButtonSize.small}
          purpose={ButtonPurpose.generic}
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
      </td>
    </tr>
  );
}
