import { useQuery, useMutation } from "react-query";
import { Auth } from "shared/@auth/Auth";

// Exporting venue options hook separately
export function useVenueOptionsGet(ruleset = "all") {
  const { makeAuthedRequest } = Auth.useContainer();

  const { data, status, isLoading, refetch } = useQuery(
    ["venueOptions", ruleset],
    () =>
      makeAuthedRequest(`v1/events/venues/options/${ruleset}`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    {
      staleTime: 0,
    }
  );

  return {
    data,
    status,
    refetch,
    isLoading,
  };
}

// Exporting the main venues management hook
export function useManageVenuesPage() {
  const { makeAuthedRequest } = Auth.useContainer();

  const fetchV2Venues = async () => {
    const response = await makeAuthedRequest(
      `v1/events/venues`,
      {
        method: "GET",
      }
    );

    if (response.ok) {
      return response.json();
    } else {
      const body = await response.json();
      throw new Error(body.message);
    }
  };

  const { data: v2Data, status: v2Status, isLoading: isV2Loading, refetch: v2refetch } = useQuery(
    ["v2venues"],
    fetchV2Venues,
    {
      onError: (error) => {
        console.log("Error fetching V2 venues:", error.message.message);
      },
    }
  );

  const resolveAddress = useMutation((resolveAddressData) =>
    makeAuthedRequest(`v1/events/venues/resolve-address`, {
      method: "POST",
      body: JSON.stringify(resolveAddressData),
    }).then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    })
  );

  const createVenue = useMutation((newVenueData) =>
    makeAuthedRequest(`v1/events/venues`, {
      method: "POST",
      body: JSON.stringify(newVenueData),
    }).then(async (res) => {
      if (res.ok) {
        return res.status;
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    })
  );

  const updateVenue = useMutation((updatedVenueData) =>
    makeAuthedRequest(`v1/events/venues`, {
      method: "PUT",
      body: JSON.stringify(updatedVenueData),
    }).then(async (res) => {
      if (res.ok) {
        return res.status;
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    })
  );

  const archiveVenue = useMutation((venueToArchiveData) =>
    makeAuthedRequest(`v1/events/venues/archive`, {
      method: "POST",
      body: JSON.stringify(venueToArchiveData),
    }).then(async (res) => {
      if (res.ok) {
        return res.status;
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    })
  );

  return {
    resolveAddress,
    createVenue,
    updateVenue,
    archiveVenue,

    v2Data,
    v2Status,
    isV2Loading,
    v2refetch
  };
}
