import React, { useState, useMemo } from "react";
import { Select } from "../../../shared/Select/Select";
import { SearchBar } from "../../../shared/SearchBar/SearchBar";
import { Tag } from "../Tag/Tag";
import { Button } from "../../../shared/Button/Button";
import { DateRangePickerButton } from "../../../shared/DateRangePickerButton/DateRangePickerButton";
import styles from "./FilterMenu.module.css";
import { nanoid } from "nanoid";
import classNames from "classnames";
import { useEffect } from "react";

/**
 * A component that can be used to filter events by rulesets
 * and event name. With filter items selected, clicking the filter button
 * will display selected tags underneath. Clicking the "X" will remove the
 * associated tag.
 */

export function FilterMenu({
  handleFilter,
  venueOptions,
  eventNameOptions,
  rulesetOptions,
  eventStatusOptions,
  onRulesetChange,
  dataPresent,
  settings,
  settingsPut,
  contextIdentifier,
  filters,
  setFilters,
}) {
  const [eventStatus, setEventStatus] = useState("Select");
  const [rulesetStatus, setRulesetStatus] = useState("Select");
  const [venueStatus, setVenueStatus] = useState("Select");
  const [eventNameStatus, setEventNameStatus] = useState("Select");
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [dateRange, setDateRange] = useState(false);
  const [search, setSearch] = useState("");
  const [showTags, setShowTags] = useState(false);
  const [tagArray, setTagArray] = useState({});
  const [buttonStatus, setButtonStatus] = useState(false);

  const [unformattedStartDate, setUnformattedStartDate] = useState("");
  const [unformattedEndDate, setUnformattedEndDate] = useState("");

  useEffect(() => {
    if (dateRange) {
      const [start, end] = dateRange.split("/");

      setUnformattedStartDate(start);
      setUnformattedEndDate(end);
    }
  }, [dateRange]);

  useEffect(() => {
    if (contextIdentifier == "manageEvents") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        eventsFilterBy: {
          ruleset: rulesetStatus !== "Select" ? rulesetStatus : null,
          eventName: eventNameStatus !== "Select" ? eventNameStatus : null,
          venue: venueStatus !== "Select" ? venueStatus : null,
          status: eventStatus !== "Select" ? eventStatus : null,
          dateRange: dateRange || null,
          search: search !== "" ? search : null,
        },
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        archivedFilterBy: {
          ruleset: rulesetStatus !== "Select" ? rulesetStatus : null,
          eventName: eventNameStatus !== "Select" ? eventNameStatus : null,
          venue: venueStatus !== "Select" ? venueStatus : null,
          status: eventStatus !== "Select" ? eventStatus : null,
          dateRange: dateRange || null,
          search: search !== "" ? search : null,
        },
      }));
    }
  }, [
    eventStatus,
    rulesetStatus,
    venueStatus,
    eventNameStatus,
    dateRange,
    search,
    setFilters,
  ]);

  useEffect(() => {
    const startUTC = startDate ? new Date(startDate).toISOString() : "";
    const endUTC = endDate ? new Date(endDate).toISOString() : "";

    const combinedDateRange = `${startUTC}${endDate ? "/" : ""}${
      endDate ? endUTC : ""
    }`;

    setDateRange(combinedDateRange == "" ? null : combinedDateRange);
  }, [startDate, endDate]);

  const isRulesetDisabled = rulesetOptions.length <= 1;
  const isEventNameDisabled = eventNameOptions.length <= 1;
  const isEventStatusDisabled = eventStatusOptions.length <= 1;
  const isEventVenueDisabled = venueOptions.length <= 1;
  const isDatePickerDisabled = false; //!startDate && !endDate ? false : true;
  const isFilterButtonDisabled =
    isRulesetDisabled &&
    isEventNameDisabled &&
    isEventStatusDisabled &&
    isEventVenueDisabled &&
    isDatePickerDisabled;

  const id = nanoid();
  const [dateKey, setDateKey] = useState(id);
  const [searchKey, setSearchKey] = useState(id);

  const eventPlaceholder = (option) => {
    setEventStatus(option);
  };

  useEffect(() => {
    const initializeFiltersFromSettings = () => {
      // Determine which settings to use based on contextIdentifier
      const filterKey =
        contextIdentifier === "manageEvents"
          ? "eventsFilterBy"
          : "archivedFilterBy";

      // Ensure settings object exists and has the necessary structure
      const filterSettings =
        settings && settings[0] && settings[0][filterKey]
          ? settings[0][filterKey]
          : {};

      // Initialize select states and tagArray based on filterSettings
      setRulesetStatus(filterSettings.ruleset || "Select");
      setEventNameStatus(filterSettings.eventName || "Select");
      let venue;
      if (filterSettings.venue === "onsite") {
        venue = "Onsite";
      } else if (filterSettings.venue === "virtual") {
        venue = "Virtual";
      } else {
        venue = "Select";
      }
      setVenueStatus(venue);
      setEventStatus(filterSettings.status || "Select");
      setSearch(filterSettings.search || "");

      let startDate = false;
      let endDate = false;

      // Handle the date range
      if (filterSettings.dateRange) {
        const [startISO, endISO] = filterSettings.dateRange.split("/");

        // Convert from ISO string to human-readable format
        startDate = new Date(startISO).toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        });

        endDate = new Date(endISO).toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        });

        setStartDate(startDate);
        setEndDate(endDate);
      } else {
        setStartDate(false);
        setEndDate(false);
      }

      // Initialize tagArray based on the existing settings
      const initialTags = {};
      if (filterSettings.ruleset) initialTags.ruleset = filterSettings.ruleset;
      if (filterSettings.eventName)
        initialTags.eventName = filterSettings.eventName;
      if (filterSettings.venue) {
        let venue;
        if (filterSettings.venue === "onsite") {
          venue = "Onsite";
        } else if (filterSettings.venue === "virtual") {
          venue = "Virtual";
        }
        initialTags.venue = venue;
      }
      if (filterSettings.status) initialTags.status = filterSettings.status;
      if (filterSettings.dateRange) {
        initialTags.startDate = startDate;
        initialTags.endDate = endDate;
      }
      if (filterSettings.search) initialTags.search = filterSettings.search;

      setTagArray(initialTags);
      setShowTags(Object.keys(initialTags).length > 0);
    };

    initializeFiltersFromSettings();
  }, [settings, contextIdentifier]);

  const onClearFilter = () => {
    const defaultFilterBy = {
      ruleset: null,
      eventName: null,
      venue: null,
      status: null,
      dateRange: null,
      search: null,
    };

    settingsPut(defaultFilterBy);
  };

  const onFilter = () => {
    // Extract startDate and endDate from the dateRange state if it exists
    const [startISO, endISO] = dateRange ? dateRange.split("/") : [null, null];

    // Convert ISO strings to human-readable dates
    const startDateFormatted = startISO
      ? new Date(startISO).toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })
      : null;

    const endDateFormatted = endISO
      ? new Date(endISO).toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })
      : null;

    // Construct the new tag array
    const newTagArray = {
      ...(rulesetStatus !== "Select" && { ruleset: rulesetStatus }),
      ...(eventNameStatus !== "Select" && { eventName: eventNameStatus }),
      ...(venueStatus !== "Select" && { venue: venueStatus }),
      ...(eventStatus !== "Select" && { status: eventStatus }),
      ...(startDateFormatted && { startDate: startDateFormatted }),
      ...(endDateFormatted &&
        endDateFormatted !== startDateFormatted && {
          endDate: endDateFormatted,
        }),
      ...(search && { search: search }),
    };

    const filtersApplied = Object.keys(newTagArray).length > 0;

    // Set the tag array and visibility of tags based on whether any filters are applied
    setTagArray(newTagArray);
    setShowTags(filtersApplied);

    // Apply the new filters
    handleFilter(newTagArray);

    const getFilterKey = () => {
      switch (contextIdentifier) {
        case "manageEvents":
          return "eventsFilterBy";
        case "archivedEvents":
          return "archivedFilterBy";
        default:
          return null;
      }
    };

    const filterKey = getFilterKey();

    // Prepare updated settings object with only the relevant part being updated
    const updatedSettings = {
      // ...settings[0],
      [filterKey]: filtersApplied
        ? { ...newTagArray, dateRange: dateRange }
        : resetAllFiltersToNull(),
    };

    settingsPut(updatedSettings);
  };

  // Helper function to reset all filters to null
  function resetAllFiltersToNull() {
    return {
      ruleset: null,
      eventName: null,
      venue: null,
      status: null,
      dateRange: null,
      search: null,
    };
  }

  // delete tag that corresponds to x clicked
  const onDelete = (option) => {
    // Define or redefine the logic to determine the filter key based on contextIdentifier
    const getFilterKey = () => {
      switch (contextIdentifier) {
        case "manageEvents":
          return "eventsFilterBy";
        case "archivedEvents":
          return "archivedFilterBy";
        default:
          return null; // Handle default case as appropriate
      }
    };

    // Now, use the function to get the current filter key
    const filterKey = getFilterKey();

    // Reset the corresponding state based on the option
    if (option === "ruleset") {
      setRulesetStatus("Select");
      onRulesetChange("Select");
    } else if (option === "eventName") setEventNameStatus("Select");
    else if (option === "venue") setVenueStatus("Select");
    else if (option === "status") setEventStatus("Select");
    else if (option === "startDate" || option === "endDate") {
      setStartDate("");
      setEndDate("");
      setDateRange("");
      setDateKey(nanoid());
    } else if (option === "search") {
      setSearch("");
      setSearchKey(nanoid());
    }

    // Update the tag array
    const updatedTagArray = { ...tagArray };
    if (option == "startDate" || option == "endDate") {
      delete updatedTagArray["startDate"];
      delete updatedTagArray["endDate"];
    } else {
      delete updatedTagArray[option];
    }
    setTagArray(updatedTagArray);
    setShowTags(Object.keys(updatedTagArray).length > 0);
    const updatedSettings = {
      [filterKey]: {
        ...settings[0][filterKey],
        [option]: null,
        ...(option === "startDate" || option === "endDate"
          ? { dateRange: null }
          : {}),
      },
    };
    settingsPut(updatedSettings);
  };

  return (
    <div className={styles.container}>
      <div className={styles.menuRow}>
        <div className={styles.selectDiv}>
          <label className={styles.label}>Ruleset</label>
          <div className={styles.selectSpan}>
            <Select
              options={rulesetOptions.filter(
                (option) => option !== "Select" || rulesetStatus !== "Select"
              )}
              placeholder={rulesetStatus}
              onChange={(option) => {
                setRulesetStatus(option);
                setEventNameStatus("Select");
                onRulesetChange(option);
                setButtonStatus(true);
              }}
              disabled={isRulesetDisabled}
              className={isRulesetDisabled ? styles.nonClickable : ""}
            />
          </div>
        </div>
        <div className={styles.selectDiv}>
          <label className={styles.label}>Event Name</label>
          <div className={styles.selectSpan}>
            <Select
              className={
                isEventNameDisabled ? styles.nonClickable : styles.customSelect
              }
              options={eventNameOptions.filter(
                (option) => option !== "Select" || eventNameStatus !== "Select"
              )}
              placeholder={eventNameStatus}
              onChange={(option) => {
                setEventNameStatus(option);
                setButtonStatus(true);
              }}
              disabled={isEventNameDisabled}
            />
          </div>
        </div>
        <div className={styles.selectDiv}>
          <label className={styles.label}>Location</label>
          <div className={styles.selectSpan}>
            <Select
              options={venueOptions.filter(
                (option) => option !== "Select" || venueStatus !== "Select"
              )}
              placeholder={venueStatus}
              onChange={(option) => {
                setVenueStatus(option);
                setButtonStatus(true);
              }}
              disabled={isEventVenueDisabled}
              className={isEventVenueDisabled ? styles.nonClickable : ""}
            />
          </div>
        </div>
        {contextIdentifier !== "archivedEvents" && (
          <div className={styles.selectDiv}>
            <label className={styles.label}>Status</label>
            <div className={styles.selectSpan}>
              <Select
                options={eventStatusOptions.filter(
                  (option) => option !== "Select" || eventStatus !== "Select"
                )}
                placeholder={eventStatus}
                onChange={(option) => {
                  setEventStatus(option);
                  setButtonStatus(true);
                }}
                disabled={isEventStatusDisabled}
                className={isEventStatusDisabled ? styles.nonClickable : ""}
              />
            </div>
          </div>
        )}
        <div className={styles.selectDiv}>
          <label className={classNames(styles.label)}>Date Range</label>
          <div className={styles.selectSpan}>
            <DateRangePickerButton
              key={dateKey}
              startDateString={unformattedStartDate}
              endDateString={unformattedEndDate}
              setStartDate={(e) => {
                setStartDate(e);
                if (startDate || endDate || e) {
                  setButtonStatus(true);
                }
              }}
              setEndDate={(e) => {
                setEndDate(e);
                if (startDate || endDate || e) {
                  setButtonStatus(true);
                }
              }}
              disabled={isDatePickerDisabled}
            />
          </div>
        </div>
        {dataPresent && (
          <div className={styles.selectDiv}>
            <label className={classNames(styles.label)}>Search</label>
            <div className={classNames(styles.searchBar, styles.selectSpan)}>
              <SearchBar
                key={searchKey}
                placeholderText="(Search by…)"
                onInput={(value) => {
                  setSearch(value);
                  search !== "" ||
                  eventStatus !== "Select" ||
                  rulesetStatus !== "Select" ||
                  venueStatus !== "Select" ||
                  eventNameStatus !== "Select" ||
                  startDate !== ""
                    ? setButtonStatus(true)
                    : setButtonStatus(false);
                }}
              />
            </div>
          </div>
        )}

        <div className={styles.button}>
          <Button
            className={
              styles[isFilterButtonDisabled ? "btnDisabled" : "btnEnabled"]
            }
            children="FILTER"
            onClick={onFilter}
            disabled={isFilterButtonDisabled}
          />
        </div>
      </div>
      <div className={styles.tagRow}>
        {showTags &&
          Object.entries(tagArray).map(([key, value], index) => (
            <div key={index} className={styles.tag}>
              <Tag
                title={value}
                isBtnLight={true}
                onClick={() => onDelete(key)}
              />
            </div>
          ))}
      </div>
      {Object.keys(tagArray).length > 1 && (
        <div className={styles.clearButton} onClick={onClearFilter}>
          Clear All Filters
        </div>
      )}
    </div>
  );
}
