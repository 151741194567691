import React, { useState, useEffect } from "react";
import { Modal } from "../../../shared/Modal";
import { Select } from "../../../shared/Select/Select";
import SearchBar from "shared/SearchBar";
import SpeakerItem from "Events/Components/SpeakerItem";
import PaginationControls from "shared/PaginationControls";
import Button from "shared/Button";
import TableSortHeader, { TableSortValue } from "shared/TableSortHeader";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment-timezone";
import Loader from "shared/Loader";

import styles from "./AddSpeakerModal.module.css";
import ConfirmSpeakerModal from "Events/Components/ConfirmSpeakerModal";

import { useEventIBOSearch } from "./useEventIBOSearch";

/**
 * Modal that displays the speakers and allows you to filter them
 */
export function AddSpeakerModal({
  isOpen,
  onCollapse,
  onClick,
  isHosts = false,
}) {
  const [iboSearchParams, setIboSearchParams] = useState({
    term: "",
    pin: "",
    limit: "25",
    page: "1",
    sort: "name-asc",
  });
  const [searchTerm, setSearchTerm] = useState(iboSearchParams.term);
  const pinNumberToName = {
    2: "Platinum",
    3: "Emerald",
    4: "Diamond",
  };
  const [dropdownSelection, setDropdownSelection] = useState(
    pinNumberToName[iboSearchParams.pin] || "All"
  );

  const {
    data: iboSearchData,
    refetch: refetchIboSearch,
    isError: isIboSearchError,
    isLoading: isIboSearchLoading,
  } = useEventIBOSearch(iboSearchParams);

  const [selectedSpeakers, setSelectedSpeakers] = useState([]);
  const [doRefetch, setDoRefetch] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const getSortValue = (sortCategory) => {
    let direction;
    let currentCategory = iboSearchParams.sort.split("-")[0];
    let currentDirection = iboSearchParams.sort.split("-")[1];
    if (currentCategory == sortCategory) {
      if (currentDirection == "asc") {
        direction = "asc";
      } else {
        direction = "desc";
      }
    } else {
      direction = null;
    }

    return direction;
  };

  useEffect(() => {
    if (doRefetch) {
      refetchIboSearch();
      setDoRefetch(false);
    }
  }, [iboSearchParams]);

  // useEffect(() => {
  //   console.log(doRefetch);
  //   if (doRefetch) {
  //     console.log("Refetching with params:", iboSearchParams);
  //     refetchIboSearch();
  //     setDoRefetch(false); // Reset refetch flag after the fetch
  //   }
  // }, [doRefetch]); // Only run when `doRefetch` changes

  // Update iboSearchParams for searching
  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  // Update iboSearchParams for dropdown filtering
  const handleDropDownSelection = (pin) => {
    setDropdownSelection(pin);
  };

  const handleUpdateFilters = () => {
    const nameToPinNumber = {
      Platinum: "2",
      Emerald: "3",
      Diamond: "4",
    };
    setIboSearchParams((prev) => ({
      ...prev,
      term: searchTerm,
      pin:
        dropdownSelection === "All" ? "" : nameToPinNumber[dropdownSelection],
      page: "1", // Reset to the first page when filtering
    }));
  };

  // Update iboSearchParams for sorting
  const handleSort = (field, direction) => {
    setDoRefetch(true);
    setIboSearchParams((prev) => ({
      ...prev,
      sort: `${field}-${direction}`,
      page: "1", // Reset to the first page when sorting
    }));
  };

  // Update iboSearchParams for pagination
  const handlePaging = (page) => {
    // setDoRefetch(true);
    setIboSearchParams((prev) => ({
      ...prev,
      page: String(page),
    }));
  };

  // Handle selecting and deselecting speakers
  const handleSpeakerSelection = (speaker) => {
    setSelectedSpeakers((prev) =>
      prev.some((s) => s.iboNumber === speaker.iboNumber)
        ? prev.filter((s) => s.iboNumber !== speaker.iboNumber)
        : [...prev, speaker]
    );
  };

  // Handle form submission
  const handleSubmit = (speakers) => {
    onClick(speakers);
    setSelectedSpeakers([]);
    setOpenConfirmModal(false);
    onCollapse();
  };

  const handleAddSpeaker = () => {
    alert("Added speaker!");
    onCollapse();
  };

  // Render data from iboSearchData
  const displayedSpeakers = iboSearchData?.data || [];
  const totalRecords = iboSearchData?.totalRecords || 0;
  const totalPages = iboSearchData?.totalPages || 1;
  const currentPage = Number(iboSearchParams.page);

  const selectOptions = ["All", "Emerald", "Diamond", "Platinum"];

  const toReadableDate = (originalDate) => {
    const newDate = moment(originalDate).format("MM/DD/YYYY HH:mm:ss");

    if (newDate.toLowerCase() == "invalid date") {
      return "";
    }
    return newDate;
  };

  return (
    <div className={styles.container}>
      <Modal
        title={isHosts ? "SELECT HOSTS" : "SELECT SPEAKER"}
        onClose={() => onCollapse()}
        isOpen={isOpen}
        className={isIboSearchLoading ? styles.loader : styles.modal}
        children={
          <>
            {isIboSearchLoading ? (
              <Loader />
            ) : (
              <>
                <div className={styles.filterContainer}>
                  <div className={classNames(styles.flexCol)}>
                    <span className={styles.title}>Pin Level</span>
                    <div className={styles.pinLevel}>
                      <Select
                        placeholder="All"
                        options={selectOptions}
                        onChange={(value) => handleDropDownSelection(value)}
                      />
                    </div>
                  </div>
                  <div className={classNames(styles.flexCol, styles.search)}>
                    <SearchBar
                      placeholderText="Click to type"
                      onInput={(value) => handleSearch(value)}
                    />
                  </div>
                  <div className={classNames(styles.flexCol, styles.button)}>
                    <Button
                      children="UPDATE"
                      onClick={() => handleUpdateFilters()}
                    />
                  </div>
                </div>
                <div className={styles.speakersListOutput}>
                  <span className={styles.tableTitle}>
                    {isHosts ? "Hosts" : "Speakers"}
                  </span>
                  <div className={styles.hr} />
                  <table className={styles.tableContainer}>
                    <tbody>
                      <tr className={styles.tableHeader}>
                        <th className={styles.checkboxHeader} />
                        <th
                          className={classNames(
                            styles.tableSorter,
                            styles.nameHeader
                          )}
                        >
                          <TableSortHeader
                            value={
                              iboSearchParams.sort === "name-asc"
                                ? "asc"
                                : iboSearchParams.sort === "name-desc"
                                ? "desc"
                                : null
                            }
                            onChange={
                              (newValue) => handleSort("name", newValue) // Set the newValue as "asc" or "desc"
                            }
                            children="Name"
                          />
                        </th>
                        <th
                          className={classNames(
                            styles.tableSorter,
                            styles.iboHeader
                          )}
                        >
                          <TableSortHeader
                            value={
                              iboSearchParams.sort === "ibo-asc"
                                ? "asc"
                                : iboSearchParams.sort === "ibo-desc"
                                ? "desc"
                                : null
                            }
                            onChange={
                              (newValue) => handleSort("ibo", newValue) // Set the newValue as "asc" or "desc"
                            }
                            children="IBO #"
                          />
                        </th>
                        <th
                          className={classNames(
                            styles.tableSorter,
                            styles.usernameHeader
                          )}
                        >
                          <TableSortHeader
                            value={
                              iboSearchParams.sort === "user-asc"
                                ? "asc"
                                : iboSearchParams.sort === "user-desc"
                                ? "desc"
                                : null
                            }
                            onChange={
                              (newValue) => handleSort("user", newValue) // Set the newValue as "asc" or "desc"
                            }
                            children="Username"
                          />
                        </th>
                        <th
                          className={classNames(
                            styles.tableSorter,
                            styles.emailHeader
                          )}
                        >
                          <TableSortHeader
                            value={
                              iboSearchParams.sort === "email-asc"
                                ? "asc"
                                : iboSearchParams.sort === "email-desc"
                                ? "desc"
                                : null
                            }
                            onChange={
                              (newValue) => handleSort("email", newValue) // Set the newValue as "asc" or "desc"
                            }
                            children="Email"
                          />
                        </th>
                        <th
                          className={classNames(
                            styles.tableSorter,
                            styles.lastScheduledHeader
                          )}
                        >
                          <TableSortHeader
                            value={
                              iboSearchParams.sort === "last-asc"
                                ? "asc"
                                : iboSearchParams.sort === "last-desc"
                                ? "desc"
                                : null
                            }
                            onChange={
                              (newValue) => handleSort("last", newValue) // Set the newValue as "asc" or "desc"
                            }
                            children="Last Scheduled"
                          />
                        </th>
                        <th
                          className={classNames(
                            styles.tableSorter,
                            styles.pinLevelHeader
                          )}
                        >
                          <TableSortHeader
                            value={
                              iboSearchParams.sort === "pin-asc"
                                ? "asc"
                                : iboSearchParams.sort === "pin-desc"
                                ? "desc"
                                : null
                            }
                            onChange={
                              (newValue) => handleSort("pin", newValue) // Set the newValue as "asc" or "desc"
                            }
                            children="Pin Level"
                          />
                        </th>
                      </tr>

                      <tbody className={styles.iboList}>
                        {displayedSpeakers.map((speaker, key) => (
                          <SpeakerItem
                            speaker={{
                              ...speaker,
                              lastScheduled: toReadableDate(
                                speaker.lastScheduled
                              ),
                              pinLevel: String(speaker.pinLevel),
                            }}
                            key={key}
                            onSelect={() => handleSpeakerSelection(speaker)}
                          />
                        ))}
                      </tbody>
                    </tbody>
                  </table>
                  <div className={styles.pageContainer}>
                    <PaginationControls
                      color="#1f3bd8"
                      lastPage={totalPages}
                      onPageChange={(page) => handlePaging(page)}
                      page={currentPage}
                      disableNext={currentPage === totalPages}
                    />
                  </div>
                </div>
                <div className={styles.addButton}>
                  <Button
                    children="ADD"
                    onClick={() => setOpenConfirmModal(true)}
                  />
                </div>
              </>
            )}
          </>
        }
      />
      <ConfirmSpeakerModal
        selectedSpeakers={selectedSpeakers}
        isOpen={openConfirmModal}
        onCollapse={() => setOpenConfirmModal(false)}
        onClick={(speakers) => handleSubmit(speakers)}
        isHosts={isHosts}
      />
    </div>
  );
}

AddSpeakerModal.propTypes = {
  /**
   * Getter to determine if modal is displayed
   */
  isOpen: PropTypes.bool,
  /**
   * Callback function for when the modal is closed
   */
  onCollapse: PropTypes.func,
  /**
   * Function called when the add btn is clicked (opens the confirm speaker modal)
   */
  onClick: PropTypes.func,
  /**
   * Array of speakers to display
   */
  speakers: PropTypes.arrayOf(PropTypes.shape({})),
};
