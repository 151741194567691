import React, { useCallback, useState } from "react";
import styles from "./index.module.css";
import classNames from "classnames";

export default function TextArea({
  value,
  className,
  maxLength = Infinity,
  onInput,
  ...props
}) {
  const [fresh, setFresh] = useState(true);

  const _onInput = useCallback(
    (e) => {
      if (fresh) {
        setFresh(false);
      }

      if (onInput) {
        onInput(e);
      }
    },
    [fresh, onInput]
  );

  const charsLeft = maxLength - ((value && value.length) || 0);

  return (
    <div className={styles.container}>
      <textarea
        type="text"
        className={classNames(styles.input, className, {
          [styles.withMaxLength]: charsLeft < Infinity,
          [styles.fresh]: fresh,
        })}
        maxLength={maxLength}
        value={value || ""}
        {...props}
        onInput={_onInput}
      />
      {charsLeft < Infinity && (
        <span className={styles.charsLeft}>{charsLeft} remaining</span>
      )}
    </div>
  );
}
