import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./HomesitesList.module.css";
import classNames from "classnames";
import HomesiteRow from "../HomesiteRow";
import TableSortItem from "shared/TableSortItem";
import EditHomesitesModal from "Events/Components/EditHomesitesModal";
import DeleteModal from "Events/Components/DeleteModal";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";
import Loader from "shared/Loader";
import ComparisonModal from "Events/Components/ComparisonModal";
import { useManageHomesitesPage } from "Events/Pages/ManageHomesitesPage/useManageHomesitesPage";

export function HomesitesList({
  homesitesList,
  onEdit,
  sort,
  setSort,
  isHomesitesLoading,
  refetchHomesites,
}) {
  const { data: states, isLoading: isLoadingStates } = useCurrentRoute();

  const { resolveAddress, updateHomesite, archiveHomesite } =
    useManageHomesitesPage({
      RegionId: "all",
      stateId: "all",
      city: "all",
      search: "none",
      sort: "name-asc",
      page: 1,
      limit: 15,
    });
  const [stateIdToNameMap, setStateIdToNameMap] = useState({});

  useEffect(() => {
    if (states && !isLoadingStates) {
      const map = states.reduce((acc, state) => {
        acc[state.stateId] = state.state;
        return acc;
      }, {});
      setStateIdToNameMap(map);
    }
  }, [states, isLoadingStates]);

  const [isLoading, setIsLoading] = useState(isHomesitesLoading);
  useEffect(() => {
    setIsLoading(isHomesitesLoading);
  }, [isHomesitesLoading]);

  const [showEditHomesite, setShowEditHomesite] = useState(false);
  const [showDeleteHomesite, setShowDeleteHomesite] = useState(false);
  const [selectedHomesite, setSelectedHomesite] = useState(null);
  const [comparisonModalOpen, setComparisonModalOpen] = useState(false);
  const [isCompLoading, setCompLoading] = useState(false);
  const [suggestedAddress, setSuggestedAddress] = useState({
    street1: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });

  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    if (suggestions.length > 0) {
      setComparisonModalOpen(false);
      setShowEditHomesite(true);
    }
  }, [suggestions]);
  const [formData, setFormData] = useState({
    id: 0,
    regionId: "",
    name: "",
    code: "",
    isActive: "",
    location: "",
    city: "",
    street1: "",
    street2: "",
    zip: "",
    state: "", //StateName - is transformed to StateId when being passed
    city: "",
    country: "", //CountryName - ||
    latitude: null,
    longitude: null,
    isVerified: false,
  });

  const getStateNameById = (stateId) => {
    return stateIdToNameMap[stateId] || "Unknown State";
  };

  const editHomesite = async (updatedHomesite) => {
    setCompLoading(true);
    setFormData(updatedHomesite);
    setShowEditHomesite(false);
    setComparisonModalOpen(true);

    const requiredFields = [
      { field: updatedHomesite.name, errorMessage: "Name Missing" },
      { field: updatedHomesite.code, errorMessage: "Code Missing" },
      { field: updatedHomesite.location, errorMessage: "Location Missing" },
      { field: updatedHomesite.city, errorMessage: "City Missing" },
      { field: updatedHomesite.street1, errorMessage: "Street 1 Missing" },
      { field: updatedHomesite.zip, errorMessage: "Zip/Postal Code Missing" },
      { field: updatedHomesite.state, errorMessage: "State Missing" },
      { field: updatedHomesite.country, errorMessage: "Country Missing" },
      // { field: updatedHomesite.latitude, errorMessage: "Latitude Missing" },
      // { field: updatedHomesite.longitude, errorMessage: "Longitude Missing" },
      { field: updatedHomesite.startsOn, errorMessage: "Start Date Missing" },
    ];

    let newSuggestions = [];

    requiredFields.forEach(({ field, errorMessage }) => {
      if (!field) {
        newSuggestions.push(errorMessage);
      }
    });

    if (newSuggestions.length > 0) {
      setSuggestions(newSuggestions);
      setComparisonModalOpen(false);
      setShowEditHomesite(true);
      return;
    }

    const resolveAddressData = {
      line1: updatedHomesite.street1,
      line2: updatedHomesite.street2,
      city: updatedHomesite.city,
      state: updatedHomesite.state,
      country: updatedHomesite.country,
      postalCode: updatedHomesite.zip,
    };

    try {
      const returnAddress = await resolveAddress.mutateAsync(
        resolveAddressData
      );

      if (returnAddress?.suggestions?.length > 0) {
        setSuggestions(returnAddress.suggestions);
      } else {
        setSuggestedAddress(returnAddress);
        setSuggestions([]);
      }
      setCompLoading(false);
    } catch (error) {
      console.error("Error Resolving Address:", error);
      setCompLoading(false);
    }
  };

  const handleUseSuggested = () => {
    const newFormData = {
      ...formData,
      street1: suggestedAddress.line1,
      street2: suggestedAddress.line2,
      city: suggestedAddress.city,
      state: suggestedAddress.state,
      zip: suggestedAddress.postalCode,
      country: suggestedAddress.country,
      latitude: suggestedAddress.latitude,
      longitude: suggestedAddress.longitude,
      isVerified: true,
    };
    setFormData(newFormData);
    submitHomesite(newFormData);
  };

  const handleUseOriginal = () => {
    const newFormData = {
      ...formData,
      latitude: suggestedAddress.latitude,
      longitude: suggestedAddress.longitude,
      isVerified: false,
    };
    setFormData(newFormData);
    submitHomesite(newFormData);
  };

  const submitHomesite = async (formDataToSubmit) => {
    setCompLoading(true);
    setIsLoading(true);
    const { rooms, ...otherFormData } = formDataToSubmit;
    let selectedStateObject;
    if (formDataToSubmit.state.length == 2) {
      selectedStateObject = states.find(
        (state) =>
          state.abbreviation.toLowerCase() ==
          formDataToSubmit.state.toLowerCase()
      );
    } else {
      selectedStateObject = states.find(
        (state) =>
          state.state.toLowerCase() == formDataToSubmit.state.toLowerCase()
      );
    }
    const stateId = selectedStateObject.stateId;
    const countryId = selectedStateObject.countryId;

    const updatedHomesite = {
      id: selectedHomesite.id,
      regionalId: formDataToSubmit.regionalId,
      name: formDataToSubmit.name,
      code: formDataToSubmit.code,
      isActive: formDataToSubmit.isActive,
      location: formDataToSubmit.location,
      city: formDataToSubmit.city,
      street1: formDataToSubmit.street1,
      street2: formDataToSubmit.street2 || "",
      zip: formDataToSubmit.zip,
      stateId: stateId,
      countryId: countryId,
      latitude: formDataToSubmit.latitude,
      longitude: formDataToSubmit.longitude,
      isVerified: formDataToSubmit.isVerified,
      startsOn: formDataToSubmit.startsOn,
      validUntil: formDataToSubmit.validUntil,
    };

    try {
      await updateHomesite.mutateAsync(updatedHomesite).then(() => {
        refetchHomesites().then(() => {
          setComparisonModalOpen(false);
          setCompLoading(false);
          setIsLoading(false);
          setSelectedHomesite(null);
        });
      });
    } catch (error) {
      console.error("Error Updating Homesite:", error);
      setComparisonModalOpen(false);
      setCompLoading(false);
      setIsLoading(false);
    }
  };

  const handleEditHomesite = (homesite) => {
    setSelectedHomesite(homesite);
    setShowEditHomesite(true);
  };

  const handleDeleteHomesite = async () => {
    //Is actually Archive not delete
    setCompLoading(true);
    setIsLoading(true);

    const archiveData = {
      id: selectedHomesite.id,
      regionalId: selectedHomesite.regionalId,
    };

    try {
      await archiveHomesite.mutateAsync(archiveData).then(() => {
        refetchHomesites().then(() => {
          setShowDeleteHomesite(false);
          setIsLoading(false);
        });
      });
    } catch (error) {
      console.error("Error Archiving Event:", error);

      setShowDeleteHomesite(false);
      setIsLoading(false);
    }
  };

  const onDeleteClicked = () => {
    setShowDeleteHomesite(true);
    setShowEditHomesite(false);
  };

  const handleSortChange = (property) => {
    // Toggle between "asc" and "desc" only, resetting others
    const newSortOrder = sort === `${property}-asc` ? "desc" : "asc";

    // Update sortOrder state to reflect the change
    setSort(`${property}-${newSortOrder}`);
  };

  const [sortedHomesiteList, setSortedHomesiteList] = useState(homesitesList);

  useEffect(() => {
    if (!sort) {
      setSortedHomesiteList(homesitesList);
      return;
    }

    const [property, order] = sort.split("-");
    const isAsc = order === "asc";

    const sortedList = [...homesitesList].sort((a, b) => {
      let valA = (a[property] || "").toString();
      let valB = (b[property] || "").toString();

      // if (property === "state") {
      //   valA = stateIdToNameMap[a.state] || "Unknown State";
      //   valB = stateIdToNameMap[b.state] || "Unknown State";
      // }

      return isAsc ? valA.localeCompare(valB) : valB.localeCompare(valA);
    });

    setSortedHomesiteList(sortedList);
  }, [homesitesList, sort]);
  return (
    <div className={styles.container}>
      <div className={styles.sortGrid}>
        <div className={`${styles.flexItem} `}>
          <TableSortItem
            value={sort && sort.startsWith("name") ? sort.split("-")[1] : ""}
            title="Name"
            onChange={() => handleSortChange("name")}
            isBlank={!sort || !sort.startsWith("name")}
          />
        </div>
        {/* <div className={`${styles.flexItem} `}>
          <TableSortItem
            value={sort && sort.startsWith("code") ? sort.split("-")[1] : ""}
            title="Code"
            onChange={() => handleSortChange("code")}
            isBlank={!sort || !sort.startsWith("code")}
          />
        </div> */}
        <div className={`${styles.flexItem} `}>
          <TableSortItem
            value={
              sort && sort.startsWith("location") ? sort.split("-")[1] : ""
            }
            title="Location"
            onChange={() => handleSortChange("location")}
            isBlank={!sort || !sort.startsWith("location")}
          />
        </div>
        <div className={`${styles.flexItem} `}>
          <TableSortItem
            value={sort && sort.startsWith("city") ? sort.split("-")[1] : ""}
            title="City"
            onChange={() => handleSortChange("city")}
            isBlank={!sort || !sort.startsWith("city")}
          />
        </div>
        <div className={`${styles.flexItem} `}>
          <TableSortItem
            value={sort && sort.startsWith("state") ? sort.split("-")[1] : ""}
            title="State"
            onChange={() => handleSortChange("state")}
            isBlank={!sort || !sort.startsWith("state")}
          />
        </div>
        <div className={`${styles.flexItem} `}>
          <TableSortItem
            value={sort && sort.startsWith("country") ? sort.split("-")[1] : ""}
            title="Country"
            onChange={() => handleSortChange("country")}
            isBlank={!sort || !sort.startsWith("country")}
          />
        </div>
        <div className={`${styles.flexItem} `}>
          <TableSortItem
            value={
              sort && sort.startsWith("startsOn") ? sort.split("-")[1] : ""
            }
            title="Starts On"
            onChange={() => handleSortChange("startsOn")}
            isBlank={!sort || !sort.startsWith("startsOn")}
          />
        </div>
        <div className={`${styles.flexItem} `}>
          <TableSortItem
            value={
              sort && sort.startsWith("validUntil") ? sort.split("-")[1] : ""
            }
            title="Valid Until"
            onChange={() => handleSortChange("validUntil")}
            isBlank={!sort || !sort.startsWith("validUntil")}
          />
        </div>
      </div>

      <div className={styles.homesitesList}>
        {sortedHomesiteList && sortedHomesiteList.length > 0 ? (
          <>
            {sortedHomesiteList.map((row, index) => (
              <div
                className={classNames(
                  index % 2 === 1
                    ? styles.slightlyDarkerBackground
                    : styles.whiteBG
                )}
                key={index}
              >
                <HomesiteRow
                  Homesite={{ ...row }}
                  onClick={() => handleEditHomesite(row)}
                />
              </div>
            ))}
          </>
        ) : (
          <p className={styles.noHomesitesText}>No Homesites Found</p>
        )}
      </div>

      {showEditHomesite && (
        <EditHomesitesModal
          isOpen={showEditHomesite}
          onCollapse={() => {
            setShowEditHomesite(false);
            setSuggestions([]);
          }}
          onClick={(updatedHomesite) => {
            editHomesite(updatedHomesite);
            // onEdit();
          }}
          onDelete={() => onDeleteClicked()}
          homesite={selectedHomesite}
          errorsList={suggestions}
        />
      )}

      <ComparisonModal
        suggestedAddress={suggestedAddress}
        address={formData}
        handleUseSuggested={() => handleUseSuggested()}
        handleUseOriginal={() => handleUseOriginal()}
        handleEditClick={() => {
          setComparisonModalOpen(false);
          setShowEditHomesite(true);
        }}
        onClose={() => setComparisonModalOpen(false)}
        isOpen={comparisonModalOpen}
        title={"Confirm Address"}
        loading={isCompLoading}
      />
    </div>
  );
}

HomesitesList.propTypes = {
  homesitesList: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func,
  sort: PropTypes.string,
  setSort: PropTypes.func,
};
