import styles from "./PageSelect.module.css";
import PropTypes from "prop-types";
import { ChevronDown, ChevronUp } from "react-ikonate";
import { useState, useEffect, useRef } from "react";

/**
 * A component that displays a customizable Page Select Dropdown
 */

export function PageSelect({ options, onChange, placeholder, disabled }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  // watches mouse position; close menu if clicked outside of wrapper
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropdownOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <>
      <div ref={wrapperRef} className={styles.container}>
        {disabled ? (
          <button disabled className={styles.button_closed}>
            {placeholder}
            <ChevronDown fontSize="1.8em" className={styles.chevronClosed} />
          </button>
        ) : dropdownOpen ? (
          <button
            onClick={() => setDropdownOpen(!dropdownOpen)}
            className={styles.button_open}
          >
            SELECT
            <ChevronUp fontSize="1.8em" className={styles.chevronOpen} />
          </button>
        ) : (
          <button
            onClick={() => setDropdownOpen(!dropdownOpen)}
            className={styles.button_closed}
          >
            {placeholder}
            <ChevronDown fontSize="1.8em" className={styles.chevronClosed} />
          </button>
        )}

        {dropdownOpen && (
          <div className={styles.dropdown}>
            {options.map((option, index) => (
              <div
                className={styles.items}
                onClick={() => {
                  onChange(option);
                }}
                key={option.id || index}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

PageSelect.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};
