import styles from "./CreateNewVenueModal.module.css";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { AddButton } from "Events/Components/AddButton/AddButton";
import { InputItem } from "../InputItem/InputItem";
import { Button } from "../../../shared/Button/Button";
import ModalActionBar from "../../../shared/ModalActionBar";
import { Modal } from "../../../shared/Modal";
import Select from "../Select";
import { RadioButtonList } from "Events/Components/RadioButtonList/RadioButtonList";
import { EditSelect } from "../EditSelect/EditSelect";
/**
 * An interactive component that displays the Create Venue Modal
 */

export function CreateNewVenueModal({
  isOpen,
  onCollapse,
  onClick,
  initialData,
  states,
  rulesets,
  errorsList,
}) {
  useEffect(() => {
    if (isOpen && errorsList.length > 0) {
      const modalContainer = document.querySelector(`.${styles.modal}`);
      if (modalContainer) {
        modalContainer.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }, [isOpen, errorsList]);

  const countryIdName = {
    220: "U.S.",
    38: "CDN",
    40: "JAMAICA",
    221: "JAMAICA",
    300: "ANZ",
    151: "ANZ",
  };

  const [newRuleset, setNewRuleset] = useState("");
  const [newName, setNewName] = useState("");
  const [newAddress1, setNewAddress1] = useState("");
  const [newAddress2, setNewAddress2] = useState("");
  const [newCity, setNewCity] = useState("");
  const [newState, setNewState] = useState("");
  const [newZip, setNewZip] = useState("");
  const [newCountry, setNewCountry] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [newFax, setNewFax] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newURL, setNewURL] = useState("");
  const [newRooms, setNewRooms] = useState([{ name: "", capacity: "" }]);

  useEffect(() => {
    if (initialData) {
      setNewName(initialData.venueName || "");
      setNewAddress1(initialData.street1 || "");
      setNewAddress2(initialData.street2 || "");
      setNewCity(initialData.city || "");
      setNewState(initialData.state || "");
      setNewCountry(initialData.country || "")
      setNewZip(initialData.zip || "");
      setNewPhone(initialData.phone || "");
      setNewFax(initialData.fax || "");
      setNewEmail(initialData.email || "");
      setNewURL(initialData.url || "");
      setNewRooms(initialData.rooms || [{ name: "", capacity: "" }]);
    }
  }, [initialData]);

  const onRoomRemove = (index) => {
    newRooms.length > 1 &&
      setNewRooms((arr) => arr.filter((e) => arr.indexOf(e) !== index));
    newRooms.length === 1 && setNewRooms(() => [{ name: "", capacity: "" }]);
  };

  const onVenueAdd = () => {
    setNewRooms((arr) => [...arr, { name: "", capacity: "" }]);
  };

  const handleFormSubmittal = () => {
    const formData = {
      ruleset: newRuleset,
      venueName: newName,
      street1: newAddress1,
      street2: newAddress2,
      city: newCity,
      state: newState,
      country: newCountry,
      zip: newZip,
      phone: newPhone,
      fax: newFax,
      email: newEmail,
      url: newURL,
      rooms: newRooms,
    };
    onClick(formData);
  };

  const handleCountrySelectionVenue = (country) => {
    setNewCountry(country);
    // ... other logic
  };

  const getLabelForCountry = (country) => {
    switch (country) {
      case "CDN":
        return "Province*";
      case "JAMAICA":
        return "Parish*";
      default:
        return "State*";
    }
  };
  const getPostalLabelForCountry = (country) => {
    switch (country) {
      case "CDN":
        return "Postal Code*";
      case "JAMAICA":
        return "Postal Code*";
      default:
        return "ZIP Code*";
    }
  };

  const handleRulesetChange = (value) => {
    setNewRuleset(value);
  };

  const handleStateSelection = (value) => {
    setNewState(value.split(" - ")[1]);
    // const extractState = value.split(" - ")[1];

    // const foundState = states?.find((state) => state.state === extractState);

    // if (foundState) {
    //   const stateId = String(foundState.stateId);

    //   handleInputChange("event", "state", stateId);
    //   handleBlur("event", "state", stateId);
    // }
  };

  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    if (states) {
      if (!newCountry) {
        // Display all states when no country is selected
        const allStates = states
          .filter((state) => state.state !== "N/A")
          .map((state) => `${state.abbreviation} - ${state.state}`);

        setStateOptions((prevStateOptions) => {
          const newOptions = allStates;
          return prevStateOptions.join() !== newOptions.join()
            ? newOptions
            : prevStateOptions;
        });
      } else {
        // If country is selected, filter by countryId
        const matchingCountryIds = Object.keys(countryIdName).filter(
          (key) => countryIdName[key] === newCountry
        );

        if (matchingCountryIds.length > 0) {
          const filteredStates = states
            .filter((state) =>
              matchingCountryIds.includes(state.countryId.toString())
            )
            .filter((state) => state.state !== "N/A")
            .map((state) => `${state.abbreviation} - ${state.state}`);

          setStateOptions((prevStateOptions) => {
            const newOptions = filteredStates;
            return prevStateOptions.join() !== newOptions.join()
              ? newOptions
              : prevStateOptions;
          });
        } else {
          setStateOptions([]);
        }
      }
    }
  }, [states, newCountry]);

  const renderCountryField = () => {
    // Sequence of countries
    if (states) {
      const countrySequence = ["U.S.", "CDN", "ANZ", "JAMAICA"];
      const existingCountry = new Set(states.map((state) => state.countryId));

      // Filter valid country options
      const countryOptions = countrySequence.filter((countryName) =>
        Object.entries(countryIdName).some(
          ([id, name]) =>
            name === countryName && existingCountry.has(parseInt(id))
        )
      );

      return (
        <span className={styles.radioButtonList}>
          <RadioButtonList
            onClick={handleCountrySelectionVenue}
            options={countryOptions}
            defaultValue={newCountry}
            groupName="venueCountry"
          />
        </span>
      );
    }
  };

  const renderStateSelection = () => {
    // const stateValue =
    //   eventData?.state?.value === 0 || eventData?.state === 0
    //     ? "N/A"
    //     : eventData?.state?.value || eventData?.state?.saved;

    // const hasStateError = validationErrors["state"];

    // Display state in words, don't display if value is "0"
    const getStateNameById = (id) => {
      if (id === "0") return "N/A";

      const state = states?.find(
        (state) => state?.stateId?.toString() === id?.toString()
      );

      if (state) {
        return `${state.abbreviation} - ${state.state}`;
      } else {
        return "N/A";
      }
    };

    return (
      <div className={styles.stateContainer}>
        <EditSelect
          label={getLabelForCountry(newCountry) || "State*"}
          options={stateOptions}
          placeholder={newState || "Select"}
          onChange={(value) => handleStateSelection(value)}
          className={styles.selectDropdown}
        />
      </div>
    );
  };

  return (
    <Modal
      title="CREATE VENUE"
      isOpen={isOpen}
      onClose={onCollapse}
      className={styles.modal}
      children={
        <>
          {errorsList.length > 0 && (
            <div className={styles.errorsContainer}>
              {errorsList.map((error) => (
                <div className={styles.error}>{error}</div>
              ))}
            </div>
          )}
          <div className={styles.container}>
            <div className={styles.section}>
              <div className={styles.inputRow}>
                <EditSelect
                  label="Ruleset*"
                  options={rulesets}
                  placeholder={newRuleset || "Select"}
                  onChange={(value) => handleRulesetChange(value)}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Name*"
                  placeholder=""
                  value={newName}
                  onChange={(e) => {
                    setNewName(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.inputRow}>
                <InputItem
                  label="Address 1*"
                  placeholder=""
                  value={newAddress1}
                  onChange={(e) => {
                    setNewAddress1(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Address 2*"
                  placeholder=""
                  value={newAddress2}
                  onChange={(e) => {
                    setNewAddress2(e.target.value);
                  }}
                />
              </div>
              <div className={styles.radioCol}>
                <span className={styles.label}>Country*</span>
                {renderCountryField()}
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="City*"
                  placeholder=""
                  value={newCity}
                  onChange={(e) => {
                    setNewCity(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>{renderStateSelection()}</div>
              <div className={styles.inputRow}>
                <InputItem
                  label={getPostalLabelForCountry(newCountry) || "Zip*"}
                  placeholder=""
                  value={newZip}
                  onChange={(e) => {
                    setNewZip(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Phone"
                  placeholder=""
                  value={newPhone}
                  onChange={(e) => {
                    setNewPhone(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Fax"
                  placeholder=""
                  value={newFax}
                  onChange={(e) => {
                    setNewFax(e.target.value);
                  }}
                />
              </div>
              {!newRuleset.toLowerCase().includes("v1.0") && (
                <div className={styles.inputRow}>
                  <InputItem
                    label="Venue Email"
                    placeholder=""
                    value={newEmail}
                    onChange={(e) => {
                      setNewEmail(e.target.value);
                    }}
                  />
                </div>
              )}
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue URL"
                  placeholder=""
                  value={newURL}
                  onChange={(e) => {
                    setNewURL(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={styles.section}>
              {newRooms.map((room, index) => (
                <div className={styles.inputRow} key={index}>
                  <InputItem
                    label="Room"
                    value={room.name}
                    placeholder=""
                    onChange={(e) => {
                      room.name = e.target.value;
                      setNewRooms([...newRooms]);
                    }}
                  />
                  <InputItem
                    label="Capacity"
                    value={room.capacity}
                    placeholder=""
                    onChange={(e) => {
                      room.capacity = e.target.value;
                      setNewRooms([...newRooms]);
                    }}
                  />
                  <div className={styles.removeBtn}>
                    <Button
                      className={styles.remove}
                      children="Remove"
                      onClick={() => onRoomRemove(index)}
                    />
                  </div>
                </div>
              ))}
              <div className={styles.button}>
                <AddButton
                  color="#9AB5BB"
                  description={
                    newRooms.length == 0 ? "Add Room" : "Add Another Room"
                  }
                  onClick={onVenueAdd}
                />
              </div>
            </div>

            <div className={styles.saveBtn}>
              <ModalActionBar
                children={
                  <Button
                    className={styles.save}
                    children="SAVE VENUE"
                    onClick={() => handleFormSubmittal()}
                  />
                }
              />
            </div>
          </div>
        </>
      }
    />
  );
}

CreateNewVenueModal.propTypes = {
  // Getter to determine if modal is displayed
  isOpen: PropTypes.bool,

  // Callback function for when the modal is closed
  onCollapse: PropTypes.func,

  // Function called when the save venue btn is clicked
  onClick: PropTypes.func,
};
