import { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { Auth } from "shared/@auth/Auth";

export const useManageHomesitesPage = () => {
  const { makeAuthedRequest } = Auth.useContainer();

  const { data, status, isLoading, refetch } = useQuery(["homesites"], () =>
    makeAuthedRequest(`v1/events/homesites`, {
      method: "GET",
    }).then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    })
  );

  const createHomesite = useMutation(
    (newHomesiteData) =>
      makeAuthedRequest(`v1/events/homesites`, {
        method: "POST",
        body: JSON.stringify(newHomesiteData),
      }).then(async (res) => {
        if (res.ok) {
          return res.status;
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const updateHomesite = useMutation((updatedHomesiteData) =>
    makeAuthedRequest(`v1/events/homesites`, {
      method: "PUT",
      body: JSON.stringify(updatedHomesiteData),
    }).then(async (res) => {
      if (res.ok) {
        return res.status;
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    }),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const resolveAddress = useMutation((resolveAddressData) =>
    makeAuthedRequest(`v1/events/venues/resolve-address`, {
      method: "POST",
      body: JSON.stringify(resolveAddressData),
    }).then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    })
  );

  return {
    data,
    status,
    isLoading,
    refetch,
    createHomesite,
    updateHomesite,
    resolveAddress,
  };
};
