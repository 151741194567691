import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Button from "shared/Button";
import AddButton from "../AddButton";
import Checkbox from "shared/@forms/Checkbox";
// import Select from "../Select";
import { Select } from "shared/Select/Select";
import Input from "shared/@forms/Input";
import InputItem from "../InputItem";

import styles from "./TicketGroupItem.module.css";
import classNames from "classnames";

/**Displays the information about a ticket option. When edit is clicked it will open the edit ticket modal. */
export function TicketGroupItem({
  ticket,
  onEdit,
  isMajorV1,
  isV2,
  onAddGroup,
  onRemoveGroup,
  onRemoveCreditType,
  onCheckbox,
  onSelect,
  onCreditChange,
  onEditDates,
  isSub,
  onInputChange,
  onRemoveTicket,
}) {
  return isMajorV1 ? (
    <div className={styles.ticketGroupItemContainerV1}>
      <span>{ticket.ticketId}</span>
      <span>{ticket.name}</span>
      <span className={styles.price}>{` $${ticket.price} USD`}</span>{" "}
      <span className={styles.price}>{ticket.cancelPrice}</span>
      {/* <Button
        size="small"
        className={styles.editButton}
        onClick={() => onEdit(ticket)}
      >
        Edit
      </Button> */}
    </div>
  ) : isV2 ? (
    <div className={styles.ticketGroupItemContainerV2}>
      <span>
        {isSub ? (
          <div className={styles.subItemName}>
            <Input
              value={ticket.name?.saved ?? ticket.name?.value}
              className={styles.input}
              onChange={(e) => onInputChange(e.target.value, "name")}
            />
          </div>
        ) : (
          ticket.name?.saved ?? ticket.name?.value
        )}
      </span>
      <span>
        {isSub ? (
          <div className={styles.subItem}>
            <Input
              type="number"
              value={ticket.price?.saved ?? ticket.price?.value}
              className={styles.input}
              onChange={(e) => onInputChange(e.target.value, "price")}
            />
          </div>
        ) : (
          ` $${ticket.price?.saved ?? ticket.price?.value} USD`
        )}
      </span>
      {isSub ? (
        <span>
          <div className={styles.subItem}>
            <Input
              type="number"
              value={
                ticket.cancelFee?.saved ?? ticket.cancelFee?.value
                  ? (
                      ticket.cancelFee?.saved ?? ticket.cancelFee?.value
                    ).toString()
                  : null
              }
              className={styles.input}
              onChange={(e) => onInputChange(e.target.value, "cancelFee")}
            />
          </div>
        </span>
      ) : (
        <span>{ticket.cancelFee.saved ?? ticket.cancelFee.value}</span>
      )}
      <span>
        {isSub ? (
          <div className={styles.selectContainer}>
            <Select
              options={[
                "FE_ADM",
                "FE_COMP",
                "FE_FPL",
                "FE_STD",
                "FE_STDPL",
                "FE_VR_LS",
                "FE_VR_PR",
                "FR_ADM",
                "FR_COMP",
                "FR_FPL",
                "FR_STD",
                "FR_STDPL",
                "FR_VR_LS",
                "FR_VR_PR",
                "LF_ADM",
                "LF_USD",
                "LF_VR_LS",
                "LF_VR_PR",
                "LS_ADM",
                "LS_COMP",
                "LS_FPL",
                "LS_STD",
                "LS_STDPL",
                "LS_VR_LS",
                "LS_VR_PR",
              ]}
              placeholder={
                ticket?.commonItemId?.saved ??
                ticket?.commonItemId?.value ??
                "Select"
              }
              onChange={(newValue) => onSelect(newValue, ticket.id)}
              removeSelectedOption={false}
            />
          </div>
        ) : (
          ticket?.commonItemId.saved ?? ticket?.commonItemId.value
        )}
      </span>

      <span className={styles.creditTypesContainer}>
        <div className={styles.editCreditTypesButtonContainer}>
          <Button
            className={styles.editCreditTypesButton}
            children={"Edit"}
            onClick={() => onCreditChange(ticket.id)}
          />
        </div>
        <div className={styles.creditTypesList}>
          {(ticket.acceptedCreditTypes.saved?.length > 0
            ? ticket.acceptedCreditTypes.saved
            : ticket.acceptedCreditTypes?.value || []
          )?.map((type) => (
            <span key={type.id} className={styles.creditTypesItem}>
              {type.creditTypeId}
              <div
                className={styles.removeCreditType}
                onClick={() => onRemoveCreditType(type, ticket.id)}
              >
                &times;
              </div>
            </span>
          ))}
        </div>
      </span>

      <span className={styles.checkbox}>
        <Checkbox
          onChange={() => onCheckbox(ticket.id)}
          checked={ticket.creditsRequired.saved ?? ticket.creditsRequired.value}
        />
      </span>

      <span className={styles.groups}>
        <div className={styles.groupsContainer}>
          <div className={styles.addButtonWrapper}>
            <div
              className={styles.addGroupsButton}
              onClick={() => onAddGroup(ticket.id)}
            >
              <AddButton description={"Add Groups"} color="#2A3847" />
            </div>
          </div>
          <div className={styles.groupsGrid}>
            {(
              ticket.limitedToGroups.saved ?? ticket.limitedToGroups.value
            )?.map((group) => (
              <div className={styles.groupItem} key={group?.id || group?.name}>
                {group?.name || "No Name For Group"}
                <div
                  className={styles.removeGroup}
                  onClick={() => onRemoveGroup(group, ticket.id)}
                >
                  &times;
                </div>
              </div>
            ))}
          </div>
        </div>
      </span>

      <span className={styles.checkbox}>
        <div className={styles.editCreditTypesButtonContainer}>
          <Button
            className={styles.editCreditTypesButton}
            children={"Edit"}
            onClick={() => onEditDates(ticket.id)}
          />
        </div>
      </span>

      <span className={styles.checkbox}>
        <div className={styles.editCreditTypesButtonContainer}>
          <Button
            className={styles.removeButton}
            children={"Remove"}
            onClick={() => onRemoveTicket(ticket.id)}
          />
        </div>
      </span>
    </div>
  ) : (
    <div className={styles.ticketGroupItemContainer}>
      <span>{ticket.id}</span>
      <span>{ticket.name}</span>
      <span className={styles.price}>{` $${ticket.price} USD`}</span>{" "}
      <span>{`${ticket.startDate} ${ticket.startTime}`}</span>
      <span>{`${ticket.endDate} ${ticket.endTime}`}</span>
      <span className={styles.timezone}>{ticket.timeZone}</span>
      <Button
        size="small"
        className={styles.editButton}
        onClick={() => onEdit(ticket)}
      >
        Edit
      </Button>
    </div>
  );
}

TicketGroupItem.propTypes = {
  /**Object with the tickets info (id-String, name-String, price-Number, timeZone-String, startDate-String, endDate-String, startTime-String, endTime-String)  */
  ticket: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number,
    timeZone: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }),
  /**Function called to edit the ticket information. Opens the edit ticket modal */
  onEdit: PropTypes.func,
  isMajorV1: PropTypes.bool,
};
