import React, { useEffect, useState, useCallback, useMemo } from "react";
import styles from "./ManageEventsPage.module.css";
import { EventCard } from "Events/Components/EventCard/EventCard";
import PageSelect from "Events/Components/PageSelect";
import classNames from "classnames";
import FilterMenu from "Events/Components/FilterMenu";
import NewEventModal from "Events/Components/NewEventModal";
import CloneModal from "Events/Components/CloneModal";
import DeleteModal from "Events/Components/DeleteModal";
import { useHistory, useLocation } from "react-router-dom";
import { useManageEventsPage, useSettings } from "./useManageEventsPage";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";
import formatDate from "shared/@utils/formatDate";
import Loader from "shared/Loader";

export function ManageEventsPage() {
  const history = useHistory();

  const {
    data: settingsData,
    isLoading: isLoadingSettings,
    settingsPut,
    refetch: refetchSettings,
  } = useSettings();
  const {
    rulesetData,
    rulesetStatus,
    refetchRulesetData,
    isRulesetDataLoading,
  } = useCurrentRoute();

  const [viewCountLimit, setViewCountLimit] = useState("10");
  const [shouldFetch, setShouldFetch] = useState(false);

  //Check eventsViewLimit first before fetching data
  useEffect(() => {
    if (isLoadingSettings) {
      setShouldFetch(false);
    } else if (settingsData && settingsData[0] && settingsData[0].eventsView) {
      const eventsViewLimit = settingsData[0].eventsView;

      setViewCountLimit(eventsViewLimit);
      setShouldFetch(true);
    } else {
      setShouldFetch(true);
    }
  }, [isLoadingSettings, settingsData]);

  const {
    data: eventData,
    cloneEvent,
    archiveEvent,
    isLoading: isLoadingEvents,
    // deleteEvent,
    refetch,
  } = useManageEventsPage({ viewCountLimit, shouldFetch });

  const [settings, setSettings] = useState({
    dateRange: "",
    eventName: "",
    ruleset: "",
    venue: "",
    search: "",
  });

  const [filteredEvents, setFilteredEvents] = useState(null);
  const [filterObj, setFilterObj] = useState(undefined);
  const [selectedRuleset, setSelectedRuleset] = useState(null);
  const [currentEvent, setCurrentEvent] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [createNewOpened, setCreateNewOpened] = useState(false);
  const [cloneOpened, setCloneOpened] = useState(false);
  const [deleteOpened, setDeleteOpened] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [initialFilteringDone, setInitialFilteringDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleOptionsDropdown(option) {
    // eslint-disable-next-line default-case
    switch (option) {
      case "Manage Venues":
        history.push("/events/manage-venues");
        break;
      case "View Rulesets":
        history.push("/events/view/rulesets");
        break;
      case "Manage Homesites":
        history.push("/events/manage-homesites");
        break;
    }
  }

  useEffect(() => {
    if (eventData) {
      const nonArchivedEvents = eventData.filter((event) => event.event.status);
      setFilteredEvents(nonArchivedEvents);
    }
  }, [eventData, viewCountLimit]);

  const [hasInitialFilteringBeenDone, setHasInitialFilteringBeenDone] =
    useState(false);

  useEffect(() => {
    const applyInitialFilters = async () => {
      if (hasInitialFilteringBeenDone || !eventData) {
        return;
      }

      const initialFilters =
        settingsData.length > 0 && settingsData[0]?.eventsFilterBy
          ? settingsData[0].eventsFilterBy
          : {
              ruleset: null,
              eventName: null,
              venue: null,
              status: null,
              dateRange: null,
              search: null,
            };

      try {
        await handleFilter(initialFilters);
        setInitialFilteringDone(true);
        setHasInitialFilteringBeenDone(true);
      } catch (error) {
        console.error("Failed to apply filters:", error);
      }
    };

    applyInitialFilters();
  }, [settingsData, eventData, hasInitialFilteringBeenDone]);

  useEffect(() => {
    if (eventData && initialFilteringDone) {
      handleFilter(filterObj);
    }
  }, [viewCountLimit, filterObj, eventData, initialFilteringDone]);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const clearDateSelections = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const [filters, setFilters] = useState({});

  useEffect(() => {
    if (Array.isArray(settingsData) && settingsData.length > 0) {
      const ruleset = settingsData[0]?.eventsFilterBy?.ruleset;
      if (ruleset) {
        setSelectedRuleset(ruleset);
      }
    }
  }, [settingsData]);

  // Filter Options
  const rulesetOptions = useMemo(() => {
    return [
      "Select",
      ...new Set((rulesetData || []).map((ruleset) => ruleset.name)),
    ];
  }, [eventData, rulesetData]);

  const eventNameOptions = useMemo(() => {
    // If the selectedRuleset is "Select" or if no ruleset is selected, display all event names
    if (!selectedRuleset || selectedRuleset === "Select") {
      return [
        "Select",
        ...new Set(
          (rulesetData || []).flatMap((item) =>
            item.events.map((event) => event.name)
          )
        ),
      ];
    }

    // If a specific ruleset is selected, display only the events for that ruleset
    return [
      "Select",
      ...new Set(
        (rulesetData || [])
          .filter((ruleset) => ruleset.name === selectedRuleset)
          .flatMap((item) => item.events.map((event) => event.name))
      ),
    ];
  }, [rulesetData, selectedRuleset]);

  const eventVenueLocationOptions = useMemo(
    () => [
      "Select",
      "Onsite",
      "Virtual",
      // ...new Set(
      //   (eventData || [])
      //     .map((item) => item.event.location?.value)
      //     .filter((value) => value !== undefined)
      //     .map((value) => capitalizeFirstLetter(value))
      // ),
    ],
    [eventData]
  );

  const eventStatusOptions = useMemo(
    () => [
      "Select",
      "Published",
      "Unpublished",
      // ...new Set(
      //   (eventData || [])
      //     .map((item) => item.event.status?.value)
      //     .filter((value) => value !== undefined)
      // ),
    ],
    [eventData]
  );

  //Original
  const updateSettings = useCallback(
    (newSettings) => {
      setIsLoading(true);

      const defaultFilter = {
        ruleset: null,
        eventName: null,
        venue: null,
        status: null,
        dateRange: null,
        search: null,
      };

      const isValidDateRange = (range) => {
        const dateRangePattern =
          /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z)\/(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z)$/;
        return dateRangePattern.test(range);
      };

      const eventsFilterBy = {
        ...defaultFilter,
        ...newSettings.eventsFilterBy,
        venue: newSettings.eventsFilterBy?.venue?.toLowerCase() || null,
        dateRange:
          newSettings.eventsFilterBy?.dateRange &&
          isValidDateRange(newSettings.eventsFilterBy.dateRange)
            ? newSettings.eventsFilterBy.dateRange
            : null,
      };

      const settingsDataToUse = {
        ...newSettings,
        eventsFilterBy,
      };

      settingsPut(settingsDataToUse)
        .then((updatedSettings) => {
          // Update the settingsData state with the new settings
          setSettings(updatedSettings);
          setForceUpdate((prev) => !prev);
          refetchSettings().then(() =>
            refetch().then(() => setIsLoading(false))
          );
        })
        .catch((error) => console.error("Error updating settings:", error));
    },
    [settingsPut]
  );

  // async function handleFilter(filterObject) {
  const handleFilter = useCallback(
    async (filterObject) => {
      if (!eventData) {
        return;
      }
      let filteredData = eventData.filter(
        (event) => event.event.status.value !== "Archived"
      );
      setFilterObj(filterObject);
      // if (filterObject.search) {
      //   filteredData = filteredData.filter(
      //     (item) =>
      //       (item.event.name.value &&
      //         item.event.name.value
      //           .toLowerCase()
      //           .includes(filterObject.search.toLowerCase())) ||
      //       (item.event.jobCode &&
      //         item.event.jobCode.value &&
      //         item.event.jobCode.value
      //           .toLowerCase()
      //           .includes(filterObject.search.toLowerCase())) ||
      //       (item.event.location &&
      //         item.event.location.value &&
      //         item.event.location.value
      //           .toLowerCase()
      //           .includes(filterObject.search.toLowerCase())) ||
      //       (item.marketing.title &&
      //         item.marketing.title.value &&
      //         item.marketing.title.value
      //           .toLowerCase()
      //           .includes(filterObject.search.toLowerCase())) ||
      //       (item.marketing.title &&
      //         item.marketing.title.saved &&
      //         item.marketing.title.saved
      //           .toLowerCase()
      //           .includes(filterObject.search.toLowerCase()))
      //   );
      // }

      // Apply ruleset filter
      // if (filterObject.ruleset) {
      //   filteredData = filteredData.filter(
      //     (item) =>
      //       item.event.ruleset &&
      //       item.event.ruleset.value.toLowerCase() ===
      //         filterObject.ruleset.toLowerCase()
      //   );
      // }

      // Apply event name filter
      // if (filterObject.eventName) {
      //   filteredData = filteredData.filter((item) =>
      //     item.event.name.value
      //       .toLowerCase()
      //       .includes(filterObject.eventName.toLowerCase())
      //   );
      // }

      // Filters the data with venue results
      // if (filterObject.venue) {
      //   filteredData = filteredData.filter((item) =>
      //     item.event.location.value
      //       .toLowerCase()
      //       .includes(filterObject.venue.toLowerCase())
      //   );
      // }

      // Apply status filter
      // if (filterObject.status) {
      //   filteredData = filteredData.filter(
      //     (item) =>
      //       item.event.status.value.toLowerCase() ===
      //       filterObject.status.toLowerCase()
      //   );
      // }

      // Apply date range filter
      // if (filterObject.startDate && filterObject.endDate) {
      //   filteredData = filteredData.filter((item) => {
      //     const eventStartDate = item.event.dates?.eventStartDate?.value;
      //     const eventEndDate = item.event.dates?.eventEndDate?.value;
      //     return (
      //       dateCheck(
      //         formatDate(filterObject.startDate, "MM/DD/YYYY"),
      //         formatDate(filterObject.endDate, "MM/DD/YYYY"),
      //         formatDate(eventStartDate, "MM/DD/YYYY")
      //       ) ||
      //       dateCheck(
      //         formatDate(filterObject.startDate, "MM/DD/YYYY"),
      //         formatDate(filterObject.endDate, "MM/DD/YYYY"),
      //         formatDate(eventEndDate, "MM/DD/YYYY")
      //       )
      //     );
      //   });
      // }

      setFilteredEvents(filteredData);
      // }
    },
    [eventData]
  );

  const handleViewCountLimitChange = async (newLimit) => {
    setIsLoading(true);
    setViewCountLimit(newLimit);

    const defaultFilterBy = {
      ruleset: null,
      eventName: null,
      venue: null,
      status: null,
      dateRange: null,
      search: null,
    };

    const isValidDateRange = (range) => {
      const dateRangePattern =
        /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z)\/(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z)$/;
      return dateRangePattern.test(range);
    };

    const updatedFilterBy = {
      ...defaultFilterBy,
      ...filters.eventsFilterBy,
      venue: filters.eventsFilterBy?.venue?.toLowerCase() || null,
      dateRange:
        filters.eventsFilterBy?.dateRange &&
        isValidDateRange(filters.eventsFilterBy.dateRange)
          ? filters.eventsFilterBy.dateRange
          : null,
    };

    const updatedSettings = {
      ...(settingsData[0] || {}), // Use an empty object if settingsData[0] doesn't exist
      eventsView: newLimit,
      eventsFilterBy: updatedFilterBy,
    };

    try {
      await settingsPut(updatedSettings)
        .then(() => refetchSettings())
        .then(() => refetch())
        .finally(() => setIsLoading(false));
    } catch (error) {
      console.error("Failed to update view count limit in settings:", error);
      setIsLoading(false);
    }
  };

  //Cloning event
  function handleOpenClone(event) {
    setCurrentEvent(event);
    setCloneOpened(true);
    setOpenOptions(false);
  }

  const handleClone = async () => {
    const event = JSON.parse(JSON.stringify(currentEvent));

    const newClonedEvent = await cloneEvent.mutateAsync({
      eventId: currentEvent._id,
      id: event._id,
      ruleset: event.ruleset,
    });

    // Check if the event's ruleset is 'majorv 1.0' and redirect accordingly
    if (currentEvent.event.ruleset.value === "Major v1.0") {
      history.push(`/events/edit/event/majorv1/${newClonedEvent.id}`);
    } else if (currentEvent.event.ruleset.value === "Local v1.0") {
      history.push(`/events/edit/event/localv1/${newClonedEvent.id}`);
    } else if (currentEvent.event.ruleset.value === "LOS v1.0") {
      history.push(`/events/edit/event/losv1/${newClonedEvent.id}`);
    } else if (currentEvent.event.ruleset.value === "Major v2.0") {
      history.push(`/events/edit/event/majorv2/${newClonedEvent.id}`);
    } else if (currentEvent.event.ruleset.value === "Local v2.0") {
      history.push(`/events/edit/event/localv2/${newClonedEvent.id}`);
    } else if (currentEvent.event.ruleset.value === "LOS v2.0") {
      history.push(`/events/edit/event/losv2/${newClonedEvent.id}`);
    } else {
      history.push(`/events/edit/${newClonedEvent.id}`);
    }
  };

  //Delete event
  function handleOpenDelete(event) {
    setDeleteOpened(true);
    setSelectedEvent(event);
    setCloneOpened(false);
  }

  const eventName =
    selectedEvent && selectedEvent.event.name && selectedEvent.event.name.value
      ? selectedEvent.event.name.value
      : "";

  function handleOnArchive(eventId) {
    setDeleteOpened(true);
    archiveEvent.mutate(eventId, {
      onSuccess: () => {
        setFilteredEvents((prevEvents) =>
          prevEvents.filter((event) => event._id !== eventId)
        );
      },
      onError: (error) => {
        console.error("Error archiving event:", error);
      },
    });
  }
  function openDeleteModalWithEventId(eventId) {
    setSelectedEventId(eventId);
    setDeleteOpened(true);
  }

  const confirmArchive = () => {
    if (selectedEventId) {
      handleOnArchive(selectedEventId);
      setDeleteOpened(false);
      setSelectedEventId(null);
    } else {
      console.error("No event ID provided for archiving.");
    }
  };

  const capitalizeVenue = (settingsData) => {
    if (!settingsData || settingsData.length === 0) return settingsData;

    // Make a shallow copy of the settingsData to avoid direct mutation
    const updatedSettingsData = [...settingsData];

    // Loop through the data and capitalize the venue if necessary
    updatedSettingsData.forEach((setting) => {
      if (setting.eventsFilterBy && setting.eventsFilterBy.venue) {
        if (setting.eventsFilterBy.venue === "onsite") {
          setting.eventsFilterBy.venue = "Onsite";
        } else if (setting.eventsFilterBy.venue === "virtual") {
          setting.eventsFilterBy.venue = "Virtual";
        }
      }
    });

    return updatedSettingsData;
  };

  if (
    isLoadingEvents ||
    isLoadingSettings ||
    isRulesetDataLoading ||
    !initialFilteringDone ||
    isLoading
  ) {
    return (
      <div className={styles.loader}>
        <div>
          <Loader />
        </div>
        <div className={styles.loaderText}>Loading Events</div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.subContainer}>
          <div className={styles.headerContainer}>
            <h1 className={styles.header}>EVENTS</h1>

            <div className={styles.optionSelectContainer}>
              <PageSelect
                disabled={false}
                placeholder="OPTIONS"
                options={["Manage Venues", "View Rulesets", "Manage Homesites"]}
                onChange={(option) => handleOptionsDropdown(option)}
              />
            </div>
          </div>

          <div className={styles.filterAndNewContainer}>
            <div className={styles.filterByText}>Filter By</div>

            <div className={styles.filterDiv}>
              <FilterMenu
                handleFilter={(filterObject) => handleFilter(filterObject)}
                onRulesetChange={setSelectedRuleset}
                venueOptions={eventVenueLocationOptions}
                eventNameOptions={eventNameOptions}
                rulesetOptions={rulesetOptions}
                eventStatusOptions={eventStatusOptions}
                clearDates={clearDateSelections}
                dataPresent={eventData && eventData.length > 0}
                settings={settingsData.length > 0 ? settingsData : [{}]} // Fallback to [{}] if settingsData is empty
                settingsPut={updateSettings}
                contextIdentifier="manageEvents"
                filters={filters}
                setFilters={setFilters}
              />
            </div>

            <div
              className={styles.button}
              onClick={() => setCreateNewOpened(true)}
            >
              NEW EVENT
            </div>
          </div>

          <div className={styles.viewCountContainer}>
            <div>VIEW</div>
            <div
              className={classNames(
                styles.viewCountItem,
                !isLoadingEvents && viewCountLimit === "10" && styles.underline
              )}
              onClick={() => handleViewCountLimitChange("10")}
            >
              10
            </div>
            <div
              className={classNames(
                styles.viewCountItem,
                !isLoadingEvents && viewCountLimit === "25" && styles.underline
              )}
              onClick={() => handleViewCountLimitChange("25")}
            >
              25
            </div>
            <div
              className={classNames(
                styles.viewCountItem,
                !isLoadingEvents && viewCountLimit === "50" && styles.underline
              )}
              onClick={() => handleViewCountLimitChange("50")}
            >
              50
            </div>
            <div
              className={classNames(
                styles.viewCountItem,
                !isLoadingEvents && viewCountLimit === "all" && styles.underline
              )}
              onClick={() => handleViewCountLimitChange("all")}
            >
              All
            </div>
          </div>

          <div className={styles.eventsGrid}>
            <div className={styles.gridItem}></div>
            <div className={styles.gridItem}>EVENT</div>
            <div className={styles.gridItem}>TICKETS SOLD</div>
            <div className={styles.gridItem}>STATUS</div>
            <div className={styles.gridItem}></div>
          </div>
        </div>

        {/* {isLoading || !initialFilteringDone ? (
          <Loader />
        ) :  */}
        {filteredEvents && filteredEvents.length > 0 ? (
          filteredEvents.map((item, index) => (
            <div className={styles.eventCard} key={index}>
              <div className={styles.eventCard} key={index}>
                <EventCard
                  onView={() =>
                    history.push(`/events/view/${item.id}`, {
                      eventDetails: item,
                    })
                  }
                  onClone={() => handleOpenClone(item)}
                  onArchive={openDeleteModalWithEventId}
                  event={{
                    image: item.event.icon.saved || item.event.icon.value || "",
                    name: item.event.name.saved || item.event.name.value || "",
                    title:
                      item.marketing?.title?.saved ||
                      item.marketing?.title?.value ||
                      "",
                    startDate:
                      item.event.dates?.eventStartDate?.saved ||
                      item.event.dates?.eventStartDate?.value ||
                      "",
                    endDate:
                      item.event.dates?.eventEndDate?.saved ||
                      item.event.dates?.eventEndDate?.value ||
                      "",
                    jobCode:
                      item.event.jobCode?.saved ||
                      item.event.jobCode?.value ||
                      "",
                    tickets:
                      item.event.tickets?.saved ||
                      item.event.tickets?.value ||
                      "",
                    // ticketsSold:
                    //   item.analytics &&
                    //   item.analytics.sales &&
                    //   item.analytics.sales.sold
                    //     ? item.analytics.sales.sold.value
                    //     : NaN,
                    // totalTickets:
                    //   item.analytics &&
                    //   item.analytics.sales &&
                    //   item.analytics.sales.capacity
                    //     ? item.analytics.sales.capacity.value
                    //     : NaN,
                    status:
                      item.event.status.saved || item.event.status.value || "",
                    ruleset:
                      item.event.ruleset?.saved ||
                      item.event.ruleset?.value ||
                      "",
                    id: item._id,
                    venue: item.event.location.value,
                  }}
                />
              </div>
            </div>
          ))
        ) : (
          <div className={styles.noEventsMessage}>No Events Found.</div>
        )}
      </div>

      <NewEventModal
        isOpen={createNewOpened}
        onCollapse={() => setCreateNewOpened(false)}
        isFromEventDetails={true}
      />

      <CloneModal
        eventName={
          currentEvent && currentEvent.event && currentEvent.event.name.value
            ? currentEvent.event.name.value
            : ""
        }
        ruleset={
          currentEvent && currentEvent.event && currentEvent.event.ruleset.value
            ? currentEvent.event.ruleset.value
            : ""
        }
        location={
          currentEvent &&
          currentEvent.event &&
          currentEvent.event.location.value
            ? capitalizeFirstLetter(currentEvent.event.location.value)
            : ""
        }
        isOpen={cloneOpened}
        onCollapse={() => setCloneOpened(false)}
        onClick={handleClone}
      />
      <DeleteModal
        isOpen={deleteOpened}
        onCollapse={() => setDeleteOpened(false)}
        onClick={confirmArchive}
        header="Confirm Archive"
        description="Are you sure you want to archive this event? This action cannot be undone."
        btnName="Archive"
      />
    </>
  );
}

function dateCheck(from, to, check) {
  if (!check) return false; // Return false if check date is missing or invalid

  var fDate = Date.parse(from);
  var lDate = Date.parse(to);
  var cDate = Date.parse(check);

  if (isNaN(fDate) || isNaN(lDate) || isNaN(cDate)) return false; // Return false if any date is invalid

  return cDate <= lDate && cDate >= fDate;
}
