import React from "react";
import ReactModal from "react-modal";
import classNames from "classnames";
import PropTypes from "prop-types";
import { IoIosCloseCircle } from "react-icons/io";
import { IconContext } from "react-icons";
import styles from "./Modal.module.css";

/**
 * Modal component for defining modals and controlling their state in your components.
 */
export function Modal({
  title,
  isOpen,
  onClose,
  closeBtn,
  className,
  children,
}) {
  return (
    <ReactModal
      contentLabel={title}
      isOpen={isOpen}
      className={classNames(styles.modal, className)}
      overlayClassName={styles.overlay}
    >
      <div className={styles.header}>
        <span className={styles.title}>{title}</span>
        {closeBtn === "none" ? null : (
          <IconContext.Provider
            value={{
              size: "24px",
            }}
          >
            <IoIosCloseCircle
              onClick={onClose}
              className={styles.closeButton}
            />
          </IconContext.Provider>
        )}
      </div>
      <div className={styles.body}>{children}</div>
    </ReactModal>
  );
}

Modal.propTypes = {
  title: PropTypes.string,
  /**
   * Boolean to determine if modal is open.
   */
  isOpen: PropTypes.bool,
  /**
   * Function to close modal.
   */
  onClose: PropTypes.func,
  /**
   * Path for close button image
   */
  closeBtn: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /**
   * Passed in to make changes to the modals style
   */
  className: PropTypes.string,
};
