import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./ConfirmSpeakerModal.module.css";
import classNames from "classnames";
import { Modal } from "shared/Modal";
import { RadioButtonList } from "Events/Components/RadioButtonList/RadioButtonList";
import Button from "shared/Button";

/**
 * When adding a new speaker on one of the create new event pages the user must confirm the speaker first.
 * This modal is displayed after the add button is clicked in the add speaker modal.
 */
export function ConfirmSpeakerModal({
  selectedSpeakers,
  isOpen,
  onCollapse,
  onClick,
  isHosts,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selections, setSelections] = useState([]);

  const handleSelection = (selection) => {
    let result = {
      type: null, // 'iboA', 'iboB', or 'both'
      iboNumber: null, // The associated iboNumber
    };

    if (selection.startsWith("Both:")) {
      // Format for Both IBO's are: "Both: JONATHAN & MICHELLE ABBOTT"
      const displayName = selection.split(":")[1]?.trim();
      const speaker = selectedSpeakers.find(
        (speaker) => speaker.displayName === displayName
      );

      if (speaker) {
        result.type = "both";
        result.iboNumber = speaker.iboNumber;
      }
    } else {
      // Format for individual IBO's are: "MICHELLE ABBOTT (IBO: 1415049)"
      const idMatch = selection.match(/IBO:\s*(\d+)/);
      const id = idMatch ? idMatch[1] : null;

      if (id) {
        const speaker = selectedSpeakers.find(
          (speaker) =>
            speaker.iboAContactId == id || speaker.iboBContactId == id
        );

        if (speaker) {
          result.type = speaker.iboAContactId == id ? "iboA" : "iboB";
          result.iboNumber = speaker.iboNumber;
        }
      }
    }

    if (result.iboNumber) {
      // Update or add the result in selections
      setSelections((prevSelections) => {
        const existingIndex = prevSelections.findIndex(
          (item) => item.iboNumber === result.iboNumber
        );

        if (existingIndex > -1) {
          const updatedSelections = [...prevSelections];
          updatedSelections[existingIndex] = result;
          return updatedSelections;
        } else {
          return [...prevSelections, result];
        }
      });
    } else {
      console.log("Something went wrong");
    }
  };

  const handleConfirmClick = () => {
    const formattedSpeakerData = [];

    selections.map((selection) => {
      const speaker = selectedSpeakers.find(
        (item) => item.iboNumber == selection.iboNumber
      );

      const type = selection.type;
      if (speaker) {
        const type = selection?.type;
        if (type === "both") {
          formattedSpeakerData.push({
            iboNumber: speaker?.iboNumber || "",
            wwgId: speaker?.iboAContactId || "",
            isPrimary: true,
            name: speaker?.iboA || "",
            username: speaker?.username || "",
            email: speaker?.email || "",
            location: speaker?.location || "",
          });
          formattedSpeakerData.push({
            iboNumber: speaker?.iboNumber || "",
            wwgId: speaker?.iboBContactId || "",
            isPrimary: false,
            name: speaker?.iboB || "",
            username: speaker?.username || "",
            email: speaker?.email || "",
            location: speaker?.location || "",
          });
        } else {
          formattedSpeakerData.push({
            iboNumber: speaker?.iboNumber || "",
            wwgId:
              type === "iboA"
                ? speaker?.iboAContactId
                : speaker?.iboBContactId || "",
            isPrimary: type === "iboA",
            name: type === "iboA" ? speaker?.iboA : speaker?.iboB || "",
            username: speaker?.username || "",
            email: speaker?.email || "",
            location: speaker?.location || "",
          });
        }
      }
    });

    onClick(formattedSpeakerData);
  };

  return (
    <>
      <Modal
        // title="Confirm Speaker(s):"
        title={
          isHosts
            ? `CONFIRM HOST: ${currentIndex + 1}/${selectedSpeakers.length}`
            : `CONFIRM SPEAKER: ${currentIndex + 1}/${selectedSpeakers.length}`
        }
        isOpen={isOpen}
        onClose={() => {
          setCurrentIndex(0);
          setSelections([]);
          onCollapse();
        }}
        closeBtn={onCollapse}
        className={styles.modal}
        children={
          <>
            <div>
              {selectedSpeakers.length > 0 && (
                <>
                  <div className={styles.grid}>
                    <div className={styles.bold}>Name</div>
                    <div className={styles.item}>
                      {selectedSpeakers[currentIndex]?.displayName}
                    </div>

                    <div className={styles.bold}>Username</div>
                    <div className={styles.item}>
                      {selectedSpeakers[currentIndex]?.userName}
                    </div>

                    <div className={styles.bold}>IBO Number</div>
                    <div className={styles.item2}>
                      {selectedSpeakers[currentIndex]?.iboNumber}
                    </div>

                    <div className={styles.bold}>Email</div>
                    <div className={styles.item2}>
                      {selectedSpeakers[currentIndex]?.email}
                    </div>
                  </div>

                  <div className={styles.hr} />

                  <div className={classNames(styles.bold, styles.selectTitle)}>
                    SELECT:
                  </div>
                  <div className={styles.radioButtonListContainer}>
                    <RadioButtonList
                      onClick={handleSelection}
                      options={[
                        `${selectedSpeakers[currentIndex]?.iboA} (IBO: ${selectedSpeakers[currentIndex]?.iboAContactId})`,
                        `${selectedSpeakers[currentIndex]?.iboB} (IBO: ${selectedSpeakers[currentIndex]?.iboBContactId})`,
                        `Both: ${selectedSpeakers[currentIndex]?.displayName}`,
                      ]}
                    />
                  </div>

                  {/* Pagination */}
                  <div className={styles.pagination}>
                    {currentIndex > 0 && (
                      <div className={styles.submitButton}>
                        <Button
                          children="BACK"
                          onClick={() => setCurrentIndex((prev) => prev - 1)}
                        />
                      </div>
                    )}

                    {currentIndex < selectedSpeakers.length - 1 && (
                      <div
                        className={
                          currentIndex == 0
                            ? styles.submitButtonEnd
                            : styles.submitButton
                        }
                      >
                        <Button
                          children="NEXT"
                          onClick={() => setCurrentIndex((prev) => prev + 1)}
                          // disabled={
                          //   !selections[
                          //     selectedSpeakers[currentIndex]?.iboNumber
                          //   ]
                          // }
                        />
                      </div>
                    )}

                    {currentIndex === selectedSpeakers.length - 1 && (
                      <div
                        className={
                          currentIndex == 0
                            ? styles.submitButtonEnd
                            : styles.submitButton
                        }
                      >
                        <Button
                          children="CONFIRM"
                          onClick={handleConfirmClick}
                          // disabled={
                          //   !selections[
                          //     selectedSpeakers[currentIndex]?.iboNumber
                          //   ]
                          // }
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </>
        }
      />
    </>
  );
}

ConfirmSpeakerModal.propTypes = {
  IBO: PropTypes.object,
  selectedSpeakers: PropTypes.array,
  isOpen: PropTypes.bool,
  onCollapse: PropTypes.func,
  onClick: PropTypes.func,
};

ConfirmSpeakerModal.defaultProps = {
  IBO: {
    names: [],
  },
  selectedSpeakers: [],
};
