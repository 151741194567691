import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import CreateNewCard from "Events/Components/CreateNewCard";
import HomesitesList from "Events/Components/HomesitesList";
import CreateNewHomesiteModal from "Events/Components/CreateNewHomesiteModal";
import Loader from "shared/Loader";
import PagingControls from "shared/PagingControls";
import styles from "./ManageHomesitesPage.module.css";
import { Modal } from "shared/Modal";
import InputItem from "Events/Components/InputItem";
import { EditSelect } from "Events/Components/EditSelect/EditSelect";
import Button from "shared/Button";
import { useManageHomesitesPage } from "./useManageHomesitesPage";
import ComparisonModal from "Events/Components/ComparisonModal";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";

export function ManageHomesitesPage() {
  const { data: states, isLoading: isLoadingStates } = useCurrentRoute();
  const {
    data,
    refetch,
    isLoading: isHomesitesLoading,
    isError,
    resolveAddress,
    updateHomesite,
    createHomesite,
  } = useManageHomesitesPage({
    RegionId: "all",
    stateId: "all",
    city: "all",
    search: "none",
    sort: "name-asc",
    page: 1,
    limit: 15,
  });

  const homesiteRegionsOptionsData = [
    "ALBUQUERQUE",
    "ANCHORAGE",
    "ANN ARBOR",
    "ATLANTA",
    "AUSTIN",
    "BAKERSFIELD",
    "BELLINGHAM",
    "BILLINGS",
    "BOISE",
    "BOZEMAN",
    "BURBANK",
  ];

  const [homesites, setHomesites] = useState([]);

  useEffect(() => {
    if (data) {
      setHomesites(data);
    }
  }, [data]);

  useEffect(() => {
    if (homesiteRegionsOptionsData && regions.length == 0) {
      setRegions(homesiteRegionsOptionsData);
    }
  }, [homesiteRegionsOptionsData]);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [regions, setRegions] = useState([]);
  const [selectedHomesite, setSelectedHomesite] = useState({});
  const [sort, setSort] = useState("");
  const [groupName, setGroupName] = useState("");
  const [region, setRegion] = useState("");
  const [comparisonModalOpen, setComparisonModalOpen] = useState(false);
  const [isCompLoading, setCompLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(true);
  const [suggestedAddress, setSuggestedAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    if (suggestions.length > 0) {
      setComparisonModalOpen(false);
      setShowAddModal(true);
    }
  }, [suggestions]);
  const [formData, setFormData] = useState({
    id: "",
    regionId: "",
    name: "",
    code: "",
    isActive: "",
    location: "",
    city: "",
    street1: "",
    street2: "",
    zip: "",
    state: "",
    city: "",
    country: "",
    latitude: null,
    longitude: null,
    isVerified: false,
  });

  const addHomesite = async (newHomesite) => {
    setCompLoading(true);
    setFormData(newHomesite);
    setShowAddModal(false);
    setComparisonModalOpen(true);

    const requiredFields = [
      { field: newHomesite.name, errorMessage: "Name Missing" },
      { field: newHomesite.code, errorMessage: "Code Missing" },
      { field: newHomesite.location, errorMessage: "Location Missing" },
      { field: newHomesite.city, errorMessage: "City Missing" },
      { field: newHomesite.street1, errorMessage: "Street 1 Missing" },
      { field: newHomesite.zip, errorMessage: "Zip/Postal Code Missing" },
      { field: newHomesite.state, errorMessage: "State Missing" },
      { field: newHomesite.country, errorMessage: "Country Missing" },
      // { field: newHomesite.latitude, errorMessage: "Latitude Missing" },
      // { field: newHomesite.longitude, errorMessage: "Longitude Missing" },
      { field: newHomesite.startsOn, errorMessage: "Start Date Missing" },
    ];

    let newSuggestions = [];

    requiredFields.forEach(({ field, errorMessage }) => {
      if (!field) {
        newSuggestions.push(errorMessage);
      }
    });

    if (newSuggestions.length > 0) {
      setSuggestions(newSuggestions);
      setComparisonModalOpen(false);
      setShowAddModal(true);
      return;
    }

    const resolveAddressData = {
      line1: newHomesite.street1,
      line2: newHomesite.street2,
      city: newHomesite.city,
      state: newHomesite.state,
      country: newHomesite.country,
      postalCode: newHomesite.zip,
    };

    try {
      const returnAddress = await resolveAddress.mutateAsync(
        resolveAddressData
      );

      if (returnAddress?.suggestions?.length > 0) {
        setSuggestions(returnAddress.suggestions);
      } else {
        setSuggestedAddress(returnAddress);
        setSuggestions([]);
      }
      setCompLoading(false);
    } catch (error) {
      console.error("Error Resolving Address:", error);
      setCompLoading(false);
    }
  };

  const handleUseSuggested = () => {
    const newFormData = {
      ...formData,
      street1: suggestedAddress.line1,
      street1: suggestedAddress.line2,
      city: suggestedAddress.city,
      state: suggestedAddress.state,
      zip: suggestedAddress.postalCode,
      country: suggestedAddress.country,
      latitude: suggestedAddress.latitude,
      longitude: suggestedAddress.longitude,
      isVerified: true,
    };
    setFormData(newFormData);
    submitHomesite(newFormData);
  };

  const handleUseOriginal = () => {
    const newFormData = {
      ...formData,
      latitude: suggestedAddress.latitude,
      longitude: suggestedAddress.longitude,
      isVerified: false,
    };
    setFormData(newFormData);
    submitHomesite(newFormData);
  };

  const submitHomesite = async (formDataToSubmit) => {
    setCompLoading(true);
    setIsLoading(true);

    const { rooms, ...otherFormData } = formDataToSubmit;
    let selectedStateObject;
    if (formDataToSubmit.state.length == 2) {
      selectedStateObject = states.find(
        (state) =>
          state.abbreviation.toLowerCase() ==
          formDataToSubmit.state.toLowerCase()
      );
    } else {
      selectedStateObject = states.find(
        (state) =>
          state.state.toLowerCase() == formDataToSubmit.state.toLowerCase()
      );
    }
    const stateId = selectedStateObject.stateId;
    const countryId = selectedStateObject.countryId;

    // const homesiteToEdit = homesites.
    const updatedHomesite = {
      regionalId: formDataToSubmit.regionalId,
      name: formDataToSubmit.name,
      code: formDataToSubmit.code,
      isActive: formDataToSubmit.isActive,
      location: formDataToSubmit.location,
      city: formDataToSubmit.city,
      street1: formDataToSubmit.street1 || "",
      street2: formDataToSubmit.street2 || "",
      zip: formDataToSubmit.zip,
      state: formDataToSubmit.state || selectedStateObject.abbreviation,
      stateId: stateId,
      country: formDataToSubmit.country,
      countryId: countryId,
      latitude: formDataToSubmit.latitude,
      longitude: formDataToSubmit.longitude,
      isVerified: formDataToSubmit.isVerified,
      startsOn: formDataToSubmit.startsOn,
      validUntil: formDataToSubmit.validUntil,
    };

    try {
      await createHomesite.mutateAsync(updatedHomesite).then(() => {
        refetch().then(() => {
          setComparisonModalOpen(false);
          setCompLoading(false);
          setIsLoading(false);
          setSelectedHomesite(null);
        });
      });
    } catch (error) {
      console.error("Error Updating Homesite:", error);
      setComparisonModalOpen(false);
      setCompLoading(false);
      setIsLoading(false);
    }
  };

  const handleCreateHomesite = (newHomesiteData) => {
    //console.log("Creating New Homesite With Data: ", newHomesiteData);
    handleCancelHomesite();
  };

  const handleUpdateHomesite = (newHomesiteData) => {
    //console.log("Updating Homesite With Data: ", newHomesiteData);
    handleCancelHomesite();
  };

  const handleArchiveHomesite = (idToDelete) => {
    //console.log("Archiving Homesite With Id: ", idToDelete);
    handleCancelHomesite();
  };

  const handleCancelHomesite = () => {
    setSelectedHomesite({});
    setGroupName("");
    setRegion("");
    setShowAddModal(false);
    setShowEditModal(false);
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <>
          <div className={styles.loader}>
            <div>
              <Loader />
            </div>
            <div className={styles.loaderText}>Loading Homesites </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.headerContainer}>
            <div>
              <span className={styles.backButton}>
                <Link to="/events/events-details">{"< Back"}</Link>
              </span>
              <h1 className={styles.pageTitle}>MANAGE HOMESITES</h1>
            </div>
            <div class={styles.homesitesControls}>
              <span className={styles.createNewCard}>
                <CreateNewCard
                  title="ADD HOMESITE"
                  onClick={() => {
                    setShowAddModal(true);
                    setIsAdding(true);
                  }}
                />
              </span>
              <span className={styles.createNewCard}>
                <CreateNewCard
                  title="PUBLISH TO GROUPS"
                  onClick={() => {}}
                  text="PUBLISH"
                />
              </span>
            </div>
          </div>

          <div className={styles.homesitesList}>
            <HomesitesList
              homesitesList={homesites}
              onEdit={(homesite) => {
                setSelectedHomesite(homesite);
                setShowEditModal(true);
                setIsAdding(false);
              }}
              sort={sort}
              setSort={(newSort) => {
                setSort(newSort);
              }}
              refetchHomesites={refetch}
            />
          </div>
        </>
      )}

      {showAddModal && (
        <CreateNewHomesiteModal
          isOpen={showAddModal}
          onCollapse={() => {
            setShowAddModal(false);
            setSuggestions([]);
          }}
          onClick={(newHomesite) => {
            addHomesite(newHomesite);
          }}
          initialData={formData}
          errorsList={suggestions}
        />
      )}

      <ComparisonModal
        suggestedAddress={suggestedAddress}
        address={formData}
        handleUseSuggested={() => handleUseSuggested()}
        handleUseOriginal={() => handleUseOriginal()}
        handleEditClick={() => {
          setComparisonModalOpen(false);
          setShowAddModal(true);
        }}
        onClose={() => setComparisonModalOpen(false)}
        isOpen={comparisonModalOpen}
        title={"Confirm Address"}
        loading={isCompLoading}
      />

      {/* <Modal
        title={showAddModal ? "Add Homesite" : "Edit Homesite"}
        isOpen={showAddModal || showEditModal}
        onClose={() => handleCancelHomesite()}
        children={
          <div className={styles.modal}>
            <div className={styles.inputSection}>
              <div className={styles.inputRow}>
                <InputItem
                  label="Group Name*"
                  placeholder=""
                  value={groupName || selectedHomesite?.groupName || ""}
                  onChange={(e) => {
                    setGroupName(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <EditSelect
                  label="Region*"
                  options={regions}
                  placeholder={region || selectedHomesite?.region || "Select"}
                  onChange={(value) => setRegion(value)}
                  className={styles.regionDropdown}
                  isHomesiteRegion={true}
                />
              </div>
            </div>

            <div className={styles.buttonSection}>
              {showEditModal ? (
                <>
                  <Button
                    className={styles.createBtn}
                    children="UPDATE"
                    onClick={() =>
                      handleUpdateHomesite({
                        id: selectedHomesite.id,
                        groupName: groupName,
                        region: region,
                      })
                    }
                  />
                </>
              ) : (
                <Button
                  className={styles.createBtn}
                  children="CREATE"
                  onClick={() =>
                    handleCreateHomesite({
                      groupName: groupName,
                      region: region,
                    })
                  }
                />
              )}
              <Button
                className={styles.cancelBtn}
                children="CANCEL"
                onClick={() => handleCancelHomesite()}
              />
            </div>
          </div>
        }
      /> */}
    </div>
  );
}
