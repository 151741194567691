import React, { useState, useEffect } from "react";
import Card from "shared/Card";
import PropTypes from "prop-types";
import InfoItem from "Events/Components/InfoItem";
import Button from "shared/Button";
import classNames from "classnames";

import cardStyles from "../../V1Components/SharedCardStyles.module.css";
import styles from "./VenueCard.module.css";

/**
 * Interactive Component that displays the Venue Card
 */

export function VenueCard({ venueInfo }) {
  const [venueData, setVenueData] = useState({
    venueName: "",
    street1: "",
    street2: "",
    stateAbbr: "",
    city: "",
    zip: "",
    url: "",
    phone: "",
    capacity: "",
    room: "",
  });

  useEffect(() => {
    if (!venueInfo || !venueInfo.venue) {
      return;
    }

    const { company = {}, room = {} } = venueInfo.venue;

    const newVenueInfo = {
      venueName: company.saved?.venueName || company.value?.venueName || "N/A",
      street1: company.saved?.street1 || company.value?.street1 || "N/A",
      street2: company.saved?.street2 || company.value?.street2 || "",
      city: company.saved?.city || company.value?.city || "",
      stateAbbr: company.saved?.stateAbbr || company.value?.stateAbbr || "",
      zip: company.saved?.zip || company.value?.zip || "",
      url: company.saved?.url || company.value?.url || "N/A",
      phone: company.saved?.phone || company.value?.phone || "N/A",
      capacity: room.saved?.capacity || room.value?.capacity || "N/A",
      room: room.saved?.name || room.value?.name || "N/A",
    };

    setVenueData(newVenueInfo);
  }, [venueInfo]);

  const formatPhoneNumber = (phoneNumber) => {
    const digits = phoneNumber.replace(/\D/g, "");

    if (digits.length === 10) {
      return `(${digits.substr(0, 3)})-${digits.substr(3, 3)}-${digits.substr(
        6
      )}`;
    }
    return phoneNumber;
  };

  const addressLine = [venueData.street1, venueData.street2]
    .filter(Boolean)
    .join(", ");

  const cityStateZip = [
    venueData.city,
    venueData.stateAbbr ? `${venueData.stateAbbr} ${venueData.zip}` : "",
  ]
    .filter(Boolean)
    .join(", ");

  const fullAddress = `${venueData.street1}${
    venueData.street1 && venueData.city ? ", " : ""
  }${venueData.city}${venueData.stateAbbr ? ", " : ""}${venueData.stateAbbr} ${
    venueData.zip
  }`.trim();

  return (
    <Card
      children={
        <div className={styles.container}>
          <div className={styles.cardHeader}>VENUE</div>

          <div className={styles.hr}></div>
          <div className={styles.venueNameDiv}>
            <div>
              <InfoItem label="Venue Name" description={venueData.venueName} />

              <InfoItem
                label="Address"
                description={fullAddress}
                className={styles.venueCardInfo}
              />

              <InfoItem
                label="Capacity"
                description={venueData.capacity}
                className={styles.venueCardInfo}
              />

              <InfoItem
                label="Room"
                description={venueData.room}
                className={styles.venueCardInfo}
              />

              <InfoItem
                label="Venue URL"
                description={venueData.url}
                className={styles.venueCardInfo}
              />

              <InfoItem
                label="Phone"
                description={formatPhoneNumber(venueData.phone)}
              />
            </div>
          </div>
        </div>
      }
    />
  );
  // return <Card children={cardContents} />;
}

VenueCard.propTypes = {
  /**
   * Object with the venues info (name-String, address-String, roomName-String, capasity-String, url-String, contactName-String, phone-String, email-String)
   */
  venueInfo: PropTypes.shape({
    name: PropTypes.string,
    address1: PropTypes.string,
    // roomName: PropTypes.string,
    rooms: PropTypes.arrayOf(PropTypes.string),
    capacity: PropTypes.string,
    venueUrl: PropTypes.string,
    contactName: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
  }),
  /**
   * Function called when the edit btn is clicked
   */
  onClick: PropTypes.func,
};
