import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import styles from "./NotesV2Form.module.css";
import Card from "shared/Card";
import TextArea from "shared/TextArea";

/**
 * Area to fill out notes.
 */

export function NotesV2Form({ notesData, pageMode, onNotesChange }) {
  const [notesInternal, setNotesInternal] = useState(
    notesData?.event?.notes?.saved || notesData?.event?.notes?.value || ""
  );
  const [notesCalendar, setNotesCalendar] = useState(
    notesData?.marketing?.notes?.saved ||
      notesData?.marketing?.notes?.value ||
      ""
  );
  const [alertNotes, setAlertNotes] = useState(
    notesData?.marketing?.alertNotes?.saved ||
      notesData?.marketing?.alertNotes?.value ||
      ""
  );
  const [infoNotes, setInfoNotes] = useState(
    notesData?.marketing?.infoNotes?.saved ||
      notesData?.marketing?.infoNotes?.value ||
      ""
  );

  // When setting the state for displaying the notes:
  useEffect(() => {
    const newNotesInternal =
      notesData?.event?.notes?.saved || notesData?.event?.notes?.value || "";

    const newNotesCalendar =
      notesData?.marketing?.notes?.saved ||
      notesData?.marketing?.notes?.value ||
      "";

    const newAlertNotes =
      notesData?.marketing?.alertNotes?.saved ||
      notesData?.marketing?.alertNotes?.value ||
      "";

    const newInfoNotes =
      notesData?.marketing?.infoNotes?.saved ||
      notesData?.marketing?.infoNotes?.value ||
      "";

    setNotesInternal(decodeURI(newNotesInternal));
    setNotesCalendar(decodeURI(newNotesCalendar));
    setAlertNotes(decodeURI(newAlertNotes));
    setInfoNotes(decodeURI(newInfoNotes));
  }, [notesData]);

  const handleNotesInputChange = (dataType, event) => {
    let newValue = event.target.value;
    // Correctly replace both \n and ↵ with \r\n and assign it back to newValue.
    // newValue = newValue.replace(/\n/g, "\r\n").replace(/↵/g, "\r\n");
    // newValue = newValue.replace(/\r?\n/g, "\r\n");

    // Update the internal state based on the dataType
    if (dataType === "event") {
      setNotesInternal(newValue);
    } else if (dataType === "marketing") {
      setNotesCalendar(newValue);
    } else if (dataType === "marketing.alert") {
      setAlertNotes(newValue);
    } else if (dataType === "marketing.info") {
      setInfoNotes(newValue);
    }
  };

  const handleNotesInternalBlur = () => {
    const encodedNotesInternal = encodeURI(notesInternal);
    onNotesChange("event", notesInternal);
  };

  const handleNotesCalendarBlur = () => {
    const encodedNotesCalendar = encodeURI(notesCalendar);
    onNotesChange("marketing", notesCalendar);
  };

  const handleAlertNotesBlur = () => {
    const encodedAlertNotes = encodeURI(alertNotes);
    onNotesChange("marketing.alert", alertNotes);
  };

  const handleInfoNotesBlur = () => {
    const encodedInfoNotes = encodeURI(infoNotes);
    onNotesChange("marketing.info", infoNotes);
  };

  const renderNotesField = (dataType, label, onChange, onBlur) => {
    let displayValue = "";
    if (dataType === "event") {
      displayValue = notesInternal;
    } else if (dataType === "marketing") {
      displayValue = notesCalendar;
    } else if (dataType === "marketing.alert") {
      displayValue = alertNotes;
    } else if (dataType === "marketing.info") {
      displayValue = infoNotes;
    }

    const permission = (() => {
      if (dataType === "event") {
        return notesData?.event?.notes?.permission;
      }
      if (dataType === "marketing") {
        return notesData?.marketing?.notes?.permission;
      }
      if (dataType === "marketing.alert") {
        return notesData?.marketing?.alertNotes?.permission;
      }
      if (dataType === "marketing.info") {
        return notesData?.marketing?.infoNotes?.permission;
      }
      return "hidden";
    })();

    // If pageMode is "view", display the notes as read-only regardless of permission
    if (pageMode === "view" || permission === "read") {
      const displayValueOnSameLine = displayValue.replace(
        /(\w)(\n)(\d)/g,
        "$1 $3"
      );

      return (
        <div>
          <div className={styles.label}>{label}:</div>
          <div className={styles.text}>{displayValueOnSameLine || "N/A"}</div>
        </div>
      );
    }

    if (permission === "write") {
      return (
        <div className={styles.noteContainer}>
          <div className={styles.label}>{label}:</div>
          <TextArea
            name={dataType}
            value={displayValue}
            placeholder="Click to type"
            onChange={onChange}
            onBlur={onBlur}
          />
        </div>
      );
    }
  };

  return (
    <Card className={styles.container}>
      <div className={styles.header}>
        <div className={styles.cardHeader}>NOTES</div>
      </div>
      <hr className={styles.hr} />

      {renderNotesField(
        "event",
        "Additional Information (Internal to WWG Team)",
        (e) => handleNotesInputChange("event", e),
        handleNotesInternalBlur
      )}

      {renderNotesField(
        "marketing",
        "Additional Information (Notes to be displayed in calendar)",
        (e) => handleNotesInputChange("marketing", e),
        handleNotesCalendarBlur
      )}

      {renderNotesField(
        "marketing.alert",
        "Alert (displayed in Event)",
        (e) => handleNotesInputChange("marketing.alert", e),
        handleAlertNotesBlur
      )}

      {renderNotesField(
        "marketing.info",
        "Info (displayed in Event)",
        (e) => handleNotesInputChange("marketing.info", e),
        handleInfoNotesBlur
      )}
    </Card>
  );
}

NotesV2Form.propTypes = {
  notesData: PropTypes.shape({
    event: PropTypes.shape({
      notes: PropTypes.shape({
        saved: PropTypes.string,
        value: PropTypes.string,
        permission: PropTypes.oneOf(["hidden", "read", "write"]),
        field: PropTypes.string,
        required: PropTypes.bool,
      }),
    }),
    marketing: PropTypes.shape({
      notes: PropTypes.shape({
        saved: PropTypes.string,
        value: PropTypes.string,
        permission: PropTypes.oneOf(["hidden", "read", "write"]),
        field: PropTypes.string,
        required: PropTypes.bool,
      }),
      alertNotes: PropTypes.shape({
        saved: PropTypes.string,
        value: PropTypes.string,
        permission: PropTypes.oneOf(["hidden", "read", "write"]),
        field: PropTypes.string,
        required: PropTypes.bool,
      }),
      infoNotes: PropTypes.shape({
        saved: PropTypes.string,
        value: PropTypes.string,
        permission: PropTypes.oneOf(["hidden", "read", "write"]),
        field: PropTypes.string,
        required: PropTypes.bool,
      }),
    }),
  }).isRequired,
  pageMode: PropTypes.oneOf(["view", "edit"]).isRequired,
  onNotesChange: PropTypes.func,
};
