import React, { useEffect, useState, useCallback, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "shared/Button";
import Loader from "shared/Loader";
import _ from "lodash";
import { QUERY_KEYS } from "shared/@utils/queryKeys";
import { useQueryClient } from "react-query";
import { useSessionStorage } from "shared/@hooks/useSessionStorage";
import moment from "moment-timezone";

import styles from "./EditLocalV2EventPage.module.css";
import DeleteModal from "Events/Components/DeleteModal";
import UndoChangesModal from "Events/Components/UndoChangesModal";

import { EventPublishModal } from "Events/Components/EventPublishModal/EventPublishModal";
import { useEditEventsPage } from "./useEditEventPage";
import { useHomesitesGet } from "./useHomesitesGet";
// import { useEditEventsPage } from "../EditMajorV1EventPage/useEditEventPage";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";
import { extractDateTime } from "shared/@utils/extractCombineDateTime";
import EventTypeV2Card from "Events/V2Components/EventTypeV2Card";
import EventV2InfoForm from "Events/V2Components/EventV2InfoForm";
import PublishingV2InfoForm from "Events/V2Components/PublishingV2InfoForm";
// import DateV1Form from "Events/V2Components/DateV1Form";
import formatFieldName from "shared/@utils/formatFieldName";
import VenueV2Form from "Events/V2Components/VenueV2Form";
import TicketingV2Form from "Events/V2Components/TicketingV2Form";
import { AlertModal } from "Events/Components/AlertModal/AlertModal";
import NotesV2Form from "Events/V2Components/NotesV2Form";
import HostsV2Form from "Events/V2Components/HostsV2Form";
import { removeExtraLineSpaces } from "shared/@utils/removeExtraNewLines";
import VirtualV2Form from "Events/V2Components/VirtualV2Form";
import SessionsV2Form from "Events/V2Components/SessionsV2Form";

export function EditLocalV2EventPage() {
  const history = useHistory();
  const queryClient = useQueryClient();

  const { eventId } = useParams();

  const {
    data: eventData,
    isLoading,
    refetch,
    eventEditPut,
    eventEditPutSilent,
    eventPublish,
  } = useEditEventsPage({ eventId });

  const { data: states } = useCurrentRoute();

  const { data: homesitesData, refetch: refetchHomesites } = useHomesitesGet();

  const [homesitesGroups, setHomesitesGroups] = useState([]);

  useEffect(() => {
    if (homesitesData) {
      setHomesitesGroups(
        homesitesData.flatMap((homesite) =>
          homesite.groups
            .filter((group) => group.groupId !== null)
            .map((group) => ({
              ...group,
              type: "Homesite",
            }))
        )
      );
    }
  }, [homesitesData]);

  // const [eventData, setEventData] = useState(null);
  const [event, setEvent] = useState(null);

  const [showSavedModal, changeShowSavedModal] = useState(false);
  const [showRemoveModal, changeShowRemoveModal] = useState(false);
  const [undoChangesModal, setUndoChangesModal] = useState(false);
  const [removeChangesModal, setRemoveChangesModal] = useState(false);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [showUndoButton, setShowUndoButton] = useState(false);
  const [enablePublishButton, setEnablePublishButton] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [undoPath, setUndoPath] = useState("");
  const [isFormValid, setIsFormValid] = useState(true);
  const [triggerValidation, setTriggerValidation] = useState(false);
  const [loadingUndo, setLoadingUndo] = useState(false);

  const [eventInfo, setEventInfo] = useState({
    event: {
      name: "",
      ruleset: "",
      location: "",
      jobCode: "",
      dates: {},
      country: "",
      city: "",
      state: "",
      notes: {},
    },
    marketing: {
      alertNotes: {},
      displayImage: "",
      title: "",
      description: "",
      infoNotes: {},
      tentative: "",
      primaryCaption: "",
      secondaryCaption: "",
      visibleCalendar: "",
      visibleTo: "",
      notes: {},
    },
    virtual: {
      eventId: {},
      eventLink: {},
      eventType: {},
      eventName: {},
    },
    analytics: {
      sales: {
        soldOut: "",
        capacity: {
          purchase: 0,
          // platinum: 0,
          estimatedAttendance: 0,
        },
      },
      countingTickets: "",
    },
    venue: {
      company: {},
      room: {},
    },
    sessions: [
      {
        type: "",
        sessionName: "",
        description: "",
        speakers: [],
        capacity: "",
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        checkInStartDate: "",
        checkInStartTime: "",
        checkInEndDate: "",
        checkInEndTime: "",
        doorsOpenStartDate: "",
        doorsOpenStartTime: "",
        doorsOpenEndDate: "",
        doorsOpenEndTime: "",
      },
    ],
    hosts: [],
    tickets: [],
    speakers: [],
    visibleTo: [],
  });

  useEffect(() => {
    if (eventData) {
      //  console.log("V2EventPage: eventData", eventData);
      setEvent(eventData);

      setEventInfo((prevState) => ({
        ...prevState,
        event: {
          name: eventData.event?.name,
          ruleset: eventData.event?.ruleset,
          location: eventData.event?.location,
          jobCode: eventData.event?.jobCode,
          city: eventData.event?.city || "",
          state: eventData.event?.state || "",
          country: eventData.event?.country || "",
          dates: eventData.event?.dates || "",
          notes: eventData.event?.notes || {
            saved: "",
            value: "",
            permission: "hidden",
          },
        },
        tickets: eventData.tickets || [],
        marketing: {
          alertNotes: eventData.marketing?.alertNotes || {
            saved: "",
            value: "",
            permission: "hidden",
          },
          displayImage: eventData.marketing?.displayImage || "",
          title: eventData.marketing?.title || "",
          description: eventData.marketing?.description || "",
          infoNotes: eventData.marketing?.infoNotes || {
            saved: "",
            value: "",
            permission: "hidden",
          },
          tentative: eventData.marketing?.tentative || false,
          primaryCaption: eventData.marketing?.primaryCaption || "",
          secondaryCaption: eventData.marketing?.secondaryCaption || "",
          visibleCalendar: eventData.marketing?.visibleCalendar || false,
          visibleTo: eventData.marketing?.visibleTo || "",
          notes: eventData.marketing?.notes || {
            saved: "",
            value: "",
            permission: "hidden",
          },
        },
        virtual: {
          eventId: eventData.virtual?.eventId || "",
          eventLink: eventData.virtual?.ventLink || "",
          eventType: eventData.virtual?.eventType || "",
          eventName: eventData.virtual?.eventName || "",
        },
        analytics: {
          countingTickets: eventData.analytics?.countingTickets || false,
          sales: {
            soldOut: eventData.analytics?.sales?.soldOut || false,
            capacity: {
              purchase: eventData.analytics?.sales?.capacity?.purchase || 0,
              // platinum: eventData.analytics?.sales?.capacity?.platinum || 0,
              estimatedAttendance:
                eventData.analytics?.sales?.capacity?.estimatedAttendance || 0,
            },
          },
        },
        venue: {
          company: eventData.venue.company || {},
          room: {
            saved: eventData.venue?.room?.saved || {},
            value: eventData.venue?.room?.value || {},
          },
        },
        sessions: eventData.sessions || [],
        speakers: eventData.speakers,
        visibleTo: eventData.visibleTo,
        hosts: eventData.hosts,
      }));
    }
    setRemoveChangesModal(false);

    //  console.log("V2EventPage: eventInfo", eventInfo);
  }, [eventData]);

  useEffect(() => {
    //  console.log("V2EventPage: eventInfo", eventInfo);
  }, [eventInfo]);

  // state to hold form data
  const [formData, setFormData] = useSessionStorage("formData", {
    venue: {
      state: "",
      city: "",
      venue: "",
      noVenue: false,
    },
  });

  // Check if saved value exists, excluding event.notes.saved from the check
  const checkSavedValues = (obj, parentKey = "") => {
    for (let key in obj) {
      // Skip checking for event.notes.saved and event.country.saved
      if (parentKey === "event" && key === "notes") continue;

      if (parentKey === "venue" && (key === "company" || key === "room")) {
        const saved = obj[key].saved || {};
        const value = obj[key].value || {};
        for (let prop in saved) {
          if (saved[prop] !== null && saved[prop] !== value[prop]) {
            return true;
          }
        }
        continue;
      }

      if (typeof obj[key] === "object" && obj[key] !== null) {
        const currentPath = parentKey ? `${parentKey}.${key}` : key;

        if (obj[key].hasOwnProperty("saved") && obj[key].saved) {
          if (obj[key].hasOwnProperty("value")) {
            const saved = obj[key].saved;
            const value = obj[key].value;

            // If both saved and value are arrays, compare their lengths and contents
            if (Array.isArray(saved) && Array.isArray(value)) {
              if (
                saved.length === value.length &&
                saved.every((item, index) => item === value[index])
              ) {
                continue;
              }
            } else if (saved === value) {
              continue;
            }

            return true;
          }
        }

        if (checkSavedValues(obj[key], currentPath)) {
          return true;
        }
      }
    }
    return false;
  };

  const validateFields = (data) => {
    let isValid = true;

    const checkField = (field) => {
      if (!field || typeof field !== "object") {
        return;
      }

      const skipFields = ["Event Information > Job Code"];

      if (skipFields.includes(field.field)) {
        return;
      }

      // Check if the field is required but both saved and value are missing or invalid
      if (
        field.required &&
        field.saved == null &&
        (field.value === null ||
          field.value === undefined ||
          field.value === "")
      ) {
        isValid = false;
      }
    };

    const traverseData = (data) => {
      for (const key in data) {
        const field = data[key];

        if (Array.isArray(field)) {
          // Validate non-empty arrays
          if (field.length > 0) {
            field.forEach((item) => traverseData(item));
          }
        } else if (typeof field === "object" && field !== null) {
          if (field.required !== undefined) {
            checkField(field); // Validate the field directly
          } else {
            traverseData(field); // Recursively traverse nested objects
          }
        }
      }
    };

    traverseData(data);
    return isValid;
  };

  useEffect(() => {
    // Always recalculate unsavedChangesExist and isFormValid
    const isFormValid = validateFields(eventInfo);
    const unsavedChangesExist = checkSavedValues(eventInfo);

    // Update button states based on unsaved changes and validation results
    const shouldEnablePublish =
      isFormValid &&
      (unsavedChangesExist ||
        eventInfo?.event?.status?.value === "Unpublished" ||
        eventInfo?.event?.status?.value !== "Publishing");

    setHasUnsavedChanges(unsavedChangesExist);
    setShowUndoButton(unsavedChangesExist);
    setEnablePublishButton(shouldEnablePublish);
  }, [JSON.stringify(eventInfo)]);

  const debouncedHandleTicketingChangeRef = useRef(
    _.debounce((newTicketData) => {
      eventEditPut.mutateAsync({ tickets: newTicketData }).catch((error) => {
        console.error("Backend update failed:", error);
      });
    }, 800)
  );

  const handleTicketingForm = (newTicketData) => {
    const copiedTicketData = JSON.parse(JSON.stringify(newTicketData));

    setEventInfo((prev) => {
      const updatedInfo = {
        ...prev,
        tickets: copiedTicketData,
      };

      const processTicketData = (data) => {
        return data.map((field) => {
          Object.keys(field).forEach((key) => {
            if (field[key] && field[key].hasOwnProperty("saved")) {
              if (field[key].saved === null) {
                field[key] = field[key].value;
              } else {
                field[key] = field[key].saved;
              }
            }
          });

          if (field.tickets) {
            field.tickets = processTicketData(field.tickets);
          }

          return field;
        });
      };

      const updatedTicketData = processTicketData(newTicketData);

      // Call the debounced function
      debouncedHandleTicketingChangeRef.current(updatedTicketData);

      return updatedInfo;
    });
  };

  const handleClearSessionsTicketSet = (ticketSet) => {
    // Transform each session in the sessions array
    const updatedSessions = eventInfo.sessions.map((session) => {
      const transformedSession = {};

      for (const key in session) {
        // Handle 'visibleTo' specifically for filtering and transformation
        if (key === "visibleTo" && session[key]?.saved) {
          // Filter out matching ticketSetId and reformat 'visibleTo'
          const newVisibleTo = session[key].saved.filter(
            (set) => set.ticketSet !== ticketSet.saved
          );
          transformedSession[key] = newVisibleTo;
        } else if (
          session[key] &&
          typeof session[key] === "object" &&
          session[key].hasOwnProperty("value") &&
          session[key].hasOwnProperty("saved")
        ) {
          // For fields with 'value' and 'saved', pick only the 'value'
          transformedSession[key] = session[key].value;
        } else {
          // For other fields, keep them as is
          transformedSession[key] = session[key];
        }
      }

      return transformedSession;
    });

    // Pass the updated sessions array to the handleSessions function
    handleSessions(updatedSessions);
  };

  const handleEventTypeLocation = useCallback((selectedLocation) => {
    const newLocation =
      typeof selectedLocation === "string"
        ? selectedLocation.trim().toLowerCase()
        : "";

    setEventInfo((prevData) => {
      if (prevData.event.location.saved === newLocation) return prevData;

      const updateLocation = { event: { location: newLocation } };
      eventEditPut.mutateAsync(updateLocation);

      return {
        ...prevData,
        event: {
          ...prevData.event,
          location: { ...prevData.event.location, saved: newLocation },
        },
      };
    });
  }, []);

  const [undoIndex, setUndoIndex] = useState(null);
  const [undoField, setUndoField] = useState("");
  const [undoFieldDisplay, setUndoFieldDisplay] = useState("");
  const [original, setOriginal] = useState("");

  //Handles event info form
  const handleMarketingOnBlur = (obj, key, saved) => {
    if (saved === null) return;

    setEventInfo((prevData) => {
      const currentSavedValue = _.get(prevData, `${obj}.${key}.saved`, null);

      // Early exit if there is no change
      if (_.isEqual(currentSavedValue, saved)) {
        return prevData;
      }

      // Update state
      const updatedData = _.set(
        _.cloneDeep(prevData),
        `${obj}.${key}.saved`,
        saved
      );

      // Prepare payload for backend call
      const payload = { [obj]: { [key]: saved || null } };

      // Trigger the debounced backend call
      debouncedHandleMarketingChangeRef.current(obj, key, payload);

      return updatedData;
    });
  };

  const debouncedHandleMarketingChangeRef = useRef(
    _.debounce((obj, key, payload) => {
      eventEditPutSilent.mutateAsync(payload).catch((error) => {
        console.error(`Failed to update ${obj}.${key}`, error);
      });
    }, 300)
  );

  //Deeply nested object
  const constructNestedUpdate = (path, value) => {
    const keys = path.split(".");
    const result = {};
    let current = result;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        current[key] = value;
      } else {
        current[key] = current[key] || {};
        current = current[key];
      }
    });

    return result;
  };

  //Publish info
  const handlePublishChange = useCallback(
    ({ path, value }) => {
      // Create a new promise that will be returned
      return new Promise((resolve, reject) => {
        const debouncedUpdate = _.debounce(() => {
          const newValue = value || null;
          const update = constructNestedUpdate(path, newValue);

          setEventInfo((prevEventInfo) =>
            _.merge(_.cloneDeep(prevEventInfo), update)
          );

          eventEditPut.mutateAsync(update, {
            onSuccess: () => {
              const significantPaths = [
                "analytics.sales.soldOut",
                "analytics.sales.capacity.purchase",
                // "analytics.sales.capacity.platinum",
                "analytics.sales.capacity.estimatedAttendance",
                "analytics.countingTickets",
                "marketing.visibleCalendar",
                "marketing.visibleTo",
                "marketing.tentative",
              ];
              const shouldInvalidate = significantPaths.some(
                (significantPath) => path.startsWith(significantPath)
              );

              if (shouldInvalidate) {
                queryClient.invalidateQueries(["event", eventId]);
              }
              resolve();
            },
            onError: (err) => {
              console.error("Update failed:", err);
              reject(err);
            },
          });
        }, 300);

        debouncedUpdate();
      });
    },
    [queryClient, eventId, eventEditPut]
  );

  const handleSpeakerChange = async (newSpeakers) => {
    await eventEditPut.mutateAsync({
      speakers: newSpeakers,
    });
  };

  const handleVirtualEventChange = async (
    newVirtualEventId,
    newVirtualEventName
  ) => {
    try {
      const payload = {
        virtual: {
          eventId: newVirtualEventId,
          eventName: newVirtualEventName,
        },
      };
      await eventEditPut.mutateAsync(payload);

      setEventInfo((prevEventInfo) => ({
        ...prevEventInfo,
        virtual: {
          ...prevEventInfo.virtual,
          eventId: {
            ...prevEventInfo.virtual.eventId,
            saved: newVirtualEventId,
          },
          eventName: {
            ...prevEventInfo.virtual.eventName,
            saved: newVirtualEventName,
          },
        },
      }));
    } catch (error) {
      console.error("Error updating virtual event:", error);
    }
  };

  const handleGroupChange = async (newGroupsArray) => {
    await eventEditPut.mutateAsync({
      visibleTo: newGroupsArray,
    });
    // await eventEditPut.mutateAsync({
    //   visibleTo: newGroupsArray.map((group) => ({
    //     groupId: group.groupId,
    //     name: group.name,
    //   })),
    // });
  };

  const handleCaptionsChange = (dataType, newValue) => {
    newValue = newValue.replace(/↵/g, "\r\n").trim();
    newValue = newValue === "" ? null : newValue;

    setEventInfo((prev) => {
      // Determine current saved value based on dataType
      let currentSavedValue = "";
      if (dataType === "marketing.primary") {
        currentSavedValue = prev.marketing?.primaryCaption?.saved?.trim() || "";
      } else if (dataType === "marketing.secondary") {
        currentSavedValue =
          prev.marketing?.secondaryCaption?.saved?.trim() || "";
      }

      // Early exit if there's no change
      if (newValue === currentSavedValue) {
        return prev;
      }

      const updatedInfo = {
        ...prev,
        marketing: {
          ...prev.marketing,
          ...(dataType === "marketing.primary" && {
            primaryCaption: {
              ...prev.marketing.primaryCaption,
              saved: newValue,
            },
          }),
          ...(dataType === "marketing.secondary" && {
            secondaryCaption: {
              ...prev.marketing.secondaryCaption,
              saved: newValue,
            },
          }),
        },
      };

      // Create payload for the debounced function
      const captionsPayload =
        dataType === "marketing.primary"
          ? { marketing: { primaryCaption: newValue } }
          : { marketing: { secondaryCaption: newValue } };

      debouncedHandleCaptionsChangeRef.current(dataType, captionsPayload);
      return updatedInfo;
    });
  };

  const debouncedHandleCaptionsChangeRef = useRef(
    _.debounce((dataType, newCaptions) => {
      eventEditPutSilent.mutateAsync(newCaptions);
    }, 300)
  );

  const processArrayData = (array) => {
    return Object.fromEntries(
      Object.entries(array).map(([key, details]) => {
        if (
          details &&
          typeof details === "object" &&
          "saved" in details &&
          "value" in details
        ) {
          const savedIsEmptyArray =
            Array.isArray(details.saved) && details.saved.length === 0;
          const valueIsEmptyArray =
            Array.isArray(details.value) && details.value.length === 0;

          if (!savedIsEmptyArray && details.saved !== null) {
            return [key, details.saved];
          } else if (!valueIsEmptyArray) {
            return [key, details.value];
          } else {
            return [key, null];
          }
        }
        return [key, details];
      })
    );
  };

  const handleUndoSessions = async () => {
    // console.log("undoPath", undoPath);
    const sessionIndex = parseInt(undoPath.split(".")[1], 10);
    const propToUpdate = undoPath.split(".")[2];
    const sessionsArray = eventInfo.sessions || [];

    if (sessionIndex >= 0 && sessionIndex < sessionsArray.length) {
      const sessionToUpdate = sessionsArray[sessionIndex];

      // Process the session to ensure flat structure
      const processedSession = processArrayData(sessionToUpdate);

      let updatedSessions;

      if (propToUpdate === "speakers") {
        // Update speakers array to be empty
        updatedSessions = sessionsArray.map((session, index) =>
          index === sessionIndex
            ? { ...processedSession, speakers: [] }
            : processArrayData(session)
        );
      } else if (propToUpdate === "visibleTo") {
        // Update speakers array to be empty
        updatedSessions = sessionsArray.map((session, index) =>
          index === sessionIndex
            ? { ...processedSession, visibleTo: [] }
            : processArrayData(session)
        );
      } else {
        // Update specific property to null
        updatedSessions = sessionsArray.map((session, index) =>
          index === sessionIndex
            ? { ...processedSession, [propToUpdate]: null }
            : processArrayData(session)
        );
      }

      await eventEditPut.mutateAsync({ sessions: updatedSessions });
    } else {
      console.error("Invalid session index:", sessionIndex);
    }
  };

  const handleUndoTickets = async () => {
    // console.log("undoPath", undoPath);

    const ticketSetId = undoPath.split(".")[1];
    const addRemoveBadgesOrTickets = undoPath.split(".")[2];
    const ticketId = undoPath.split(".")[3];
    const ticketPropToUpdate = undoPath.split(".")[4];
    const ticketingArray = transformData(eventInfo.tickets).savedArray;
    const originalTicketingArray = transformData(eventInfo.tickets).valueArray;

    // console.log("ticketingArray", ticketingArray);
    // console.log("originalTicketingArray", originalTicketingArray);

    if (addRemoveBadgesOrTickets == "add") {
      const updatedTicketArray = {
        tickets: [
          ...ticketingArray,
          originalTicketingArray.find((set) => set.id == ticketSetId),
        ],
      };

      await eventEditPut.mutateAsync(updatedTicketArray);
    } else if (addRemoveBadgesOrTickets == "remove") {
      const updatedTicketArray = {
        tickets: ticketingArray.filter((set) => set.id !== ticketSetId),
      };
      await eventEditPut.mutateAsync(updatedTicketArray);
    } else if (addRemoveBadgesOrTickets == "badges") {
      const updatedTicketArray = {
        tickets: ticketingArray.map((set) =>
          set.id === ticketSetId ? { ...set, badges: [] } : set
        ),
      };

      await eventEditPut.mutateAsync(updatedTicketArray);
    } else if (
      addRemoveBadgesOrTickets == "title" ||
      addRemoveBadgesOrTickets == "subTitle"
    ) {
      const updatedTicketArray = {
        tickets: ticketingArray.map((set) =>
          set.id === ticketSetId
            ? { ...set, [addRemoveBadgesOrTickets]: null }
            : set
        ),
      };

      await eventEditPut.mutateAsync(updatedTicketArray);
    } else if (addRemoveBadgesOrTickets == "tickets") {
      if (ticketPropToUpdate == "originalTicket") {
        const updatedTicketArray = {
          tickets: ticketingArray.map((set) =>
            set.id === ticketSetId
              ? {
                  ...set,
                  tickets: [
                    ...set.tickets,
                    originalTicketingArray
                      .find((set) => set.id == ticketSetId)
                      .tickets.find((tkt) => tkt.id == ticketId),
                  ],
                }
              : set
          ),
        };
        await eventEditPut.mutateAsync(updatedTicketArray);
      } else if (ticketPropToUpdate == "ticket") {
        const updatedTicketArray = {
          tickets: ticketingArray.map((set) =>
            set.id === ticketSetId
              ? {
                  ...set,
                  tickets: set.tickets.filter((tkt) => tkt.id !== ticketId),
                }
              : set
          ),
        };
        await eventEditPut.mutateAsync(updatedTicketArray);
      } else {
        const updatedTicketArray = {
          tickets: ticketingArray.map((set) => {
            if (set.id === ticketSetId) {
              return {
                ...set,
                tickets: set.tickets.map((tkt) => {
                  if (tkt.id === ticketId) {
                    const updatedTicket = {
                      ...tkt,
                      [ticketPropToUpdate]: null,
                    };
                    return updatedTicket;
                  }

                  return tkt;
                }),
              };
            }

            return set;
          }),
        };

        // console.log("Updated Ticket Array:", updatedTicketArray);

        await eventEditPut.mutateAsync(updatedTicketArray);
      }
    }
  };

  const handleUndoSpeakers = async () => {
    await eventEditPut.mutateAsync({ speakers: [] });
  };
  const handleUndoHosts = async () => {
    await eventEditPut.mutateAsync({ hosts: [] });
  };
  const handleUndoVisibleTo = async () => {
    await eventEditPut.mutateAsync({ visibleTo: [] });
  };

  const handleUndoVenue = async () => {
    await eventEditPut.mutateAsync({
      venue: {
        company: {
          city: null,
          country: null,
          email: null,
          fax: null,
          isVerified: null,
          latitude: null,
          longitude: null,
          netSuiteId: null,
          phone: null,
          state: null,
          stateId: null,
          street1: null,
          street2: null,
          url: null,
          venueId: null,
          venueName: null,
          zip: null,
          noVenue: null,
        },
        room: {
          roomId: null,
          name: null,
          capacity: null,
        },
      },
    });
  };

  //Dates
  const handleDateChange = async (key, newValue, isTime) => {
    // Retrieve the previous value for comparison
    const prevValue = eventInfo?.dates?.[key] || "";

    // Check if the newValue is different from the previous value
    if (newValue !== prevValue) {
      const newDate = {
        event: {
          dates: {
            [key]: newValue,
          },
        },
      };

      await eventEditPut.mutateAsync(newDate);

      setEventInfo((prev) => ({
        ...prev,
        dates: {
          ...prev.dates,
          [key]: newValue,
        },
      }));

      // If there's a time, refetch for timezone data to display
      const newTime = moment(newValue).utc().format("HH:mm");
      if (isTime || newTime !== "00:00") {
        queryClient.invalidateQueries(["event", eventId]);
      }
    }
  };

  const debouncedHandleDateChangeRef = useRef(
    _.debounce(handleDateChange, 300)
  );

  const handleDateTimeChange = (key, newValue, isTime) => {
    debouncedHandleDateChangeRef.current(key, newValue, isTime);
  };

  //Notes
  const handleNotesChange = (dataType, newValue) => {
    newValue = newValue.replace(/↵/g, "\r\n").trim();

    newValue = newValue === "" ? null : newValue;

    setEventInfo((prev) => {
      // Early exit if there's no change
      let currentSavedValue;
      if (dataType === "marketing.alert") {
        currentSavedValue = prev.marketing?.alertNotes?.saved?.trim() || "";
      } else if (dataType === "marketing.info") {
        currentSavedValue = prev.marketing?.infoNotes?.saved?.trim() || "";
      } else {
        currentSavedValue = prev[dataType]?.notes?.saved?.trim() || "";
      }
      if (newValue === currentSavedValue) {
        return prev;
      }

      let updatedInfo;
      if (dataType === "marketing.alert") {
        updatedInfo = {
          ...prev,
          marketing: {
            ...prev.marketing,
            alertNotes: {
              ...prev.marketing.alertNotes,
              saved: newValue,
            },
          },
        };
      } else if (dataType === "marketing.info") {
        updatedInfo = {
          ...prev,
          marketing: {
            ...prev.marketing,
            infoNotes: {
              ...prev.marketing.infoNotes,
              saved: newValue,
            },
          },
        };
      } else {
        updatedInfo = {
          ...prev,
          [dataType]: {
            ...prev[dataType],
            notes: {
              ...prev[dataType].notes,
              saved: newValue,
            },
          },
        };
      }

      const notesPayload =
        dataType === "marketing.alert"
          ? { marketing: { alertNotes: newValue } }
          : dataType === "marketing.info"
          ? { marketing: { infoNotes: newValue } }
          : { [dataType]: { notes: newValue } };

      debouncedHandleNotesChangeRef.current(dataType, notesPayload);
      return updatedInfo;
    });
  };

  const debouncedHandleNotesChangeRef = useRef(
    _.debounce((dataType, newNotes) => {
      eventEditPutSilent.mutateAsync(newNotes);
    }, 300)
  );

  //Venue
  const handleVenueChange = (newVenueData) => {
    debouncedEventEditPut(newVenueData);

    setEventInfo((prevEventInfo) => ({
      ...prevEventInfo,
      venue: newVenueData.venue,
    }));
  };

  const debouncedEventEditPut = useRef(
    _.debounce((newVenueData) => {
      eventEditPut.mutateAsync(newVenueData);
    }, 100)
  ).current;

  //VirtualV2Form
  const handleVirtualForm = (newValue, field, isUndoing) => {
    newValue = newValue?.trim();
    newValue = newValue === "" || newValue === undefined ? null : newValue;

    setEventInfo((prev) => ({
      ...prev,
      virtual: {
        ...prev.virtual,
        [field]: {
          ...prev.virtual[field],
          saved: newValue,
        },
      },
    }));

    const payload = {
      virtual: {
        [field]: newValue,
      },
    };

    debouncedHandleVirtualChangeRef.current(payload, isUndoing);
  };

  const debouncedHandleVirtualChangeRef = useRef(
    _.debounce((updatedEvent, isUndoing) => {
      if (isUndoing) {
        eventEditPut.mutateAsync(updatedEvent).catch((error) => {
          console.error("Backend update failed:", error);
        });
      } else {
        eventEditPutSilent.mutateAsync(updatedEvent).catch((error) => {
          console.error("Backend update failed:", error);
        });
      }
    }, 800)
  );

  // Sessions
  const handleSessions = async (newSessions) => {
    await eventEditPut.mutateAsync({
      sessions: newSessions,
    });
  };

  //Hosts
  const handleAddHosts = async (newHosts) => {
    await eventEditPut.mutateAsync({
      hosts: newHosts,
    });
  };

  //Function handles the undo changes
  const handleUndoChangeAndUpdate = async () => {
    setLoadingUndo(true);
    let updatedChanges = {};
    const eventObjects = ["event", "marketing", "analytics"];
    let shouldInvalidate = false;

    if (undoPath?.includes("sessions")) {
      handleUndoSessions();
    }

    if (undoField == "virtualEventName") {
      await handleVirtualEventChange(null, null);
    }

    if (undoField == "virtualEventType") {
      handleVirtualForm(null, "eventType", true);
    }

    if (undoField == "virtualEventLink") {
      handleVirtualForm(null, "eventLink", true);
    }

    if (undoField == "speakers") {
      handleUndoSpeakers();
    }
    if (undoField == "hosts") {
      handleUndoHosts();
    }
    if (undoField == "limitToGroups" || undoField == "visibleTo") {
      await handleUndoVisibleTo();
    }

    if (undoField == "ticketing") {
      handleUndoTickets();
    }

    if (undoField == "venue") {
      handleUndoVenue();
    }

    eventObjects.forEach((eventObject) => {
      let tempContext = {};
      if (eventInfo[eventObject]) {
        const isEventNotes = eventObject === "event" && undoField === "notes";
        const isMarketingNotes =
          eventObject === "marketing" && undoField === "notes";
        const isAlertNotes =
          eventObject === "marketing" && undoField === "marketing.alertNotes";
        const isInfoNotes =
          eventObject === "marketing" && undoField === "marketing.infoNotes";

        const proceed =
          undoField !== "notes" ||
          isEventNotes ||
          isMarketingNotes ||
          isAlertNotes ||
          isInfoNotes;

        if (proceed) {
          if (eventObject === "venue" && undoField === "venue") {
            // Set saved properties to current value properties for company and room
            tempContext.company = {
              ...eventInfo.venue.company,
              saved: { ...eventInfo.venue.company.value },
            };
            tempContext.room = {
              ...eventInfo.venue.room,
              saved: { ...eventInfo.venue.room.value },
            };
            updatedChanges[eventObject] = {
              // ...eventInfo[eventObject],
              ...tempContext,
            };
            shouldInvalidate = true;
          } else if (
            findAndUpdateField(eventInfo[eventObject], undoField, tempContext)
          ) {
            updatedChanges[eventObject] = {
              // ...eventInfo[eventObject],
              ...tempContext,
            };
            shouldInvalidate = true;
          }
        }
      }
    });

    if (Object.keys(updatedChanges).length > 0) {
      await eventEditPut.mutateAsync(updatedChanges, {
        onSuccess: () => {
          if (shouldInvalidate) {
            queryClient.invalidateQueries(["event", eventId]);
          }
        },
      });
    }
    // setRemoveChangesModal(false);
  };

  const findAndUpdateField = (obj, field, context) => {
    let fieldUpdated = false;

    if (field === "venue") {
      // Special handling for venue: set saved properties to the current value
      if (obj.company && obj.company.value) {
        context.company = {
          ...obj.company,
          saved: { ...obj.company.value }, // Set saved to the current value
        };
        fieldUpdated = true;
      }
      if (obj.room && obj.room.value) {
        context.room = {
          ...obj.room,
          saved: { ...obj.room.value }, // Set saved to the current value
        };
        fieldUpdated = true;
      }
    } else if (field === "notes") {
      // Existing handling for notes
      if (
        undoPath === "event.notes" &&
        obj === eventInfo.event &&
        field in obj
      ) {
        context[field] = null;
        fieldUpdated = true;
      } else if (
        undoPath === "marketing.notes" &&
        obj === eventInfo.marketing &&
        field in obj
      ) {
        context[field] = null;
        fieldUpdated = true;
      } else if (
        undoPath === "marketing.alertNotes" &&
        obj === eventInfo.marketing.infoNotes &&
        "alertNotes" in obj
      ) {
        context.alertNotes = { ...obj.alertNotes, saved: null };
        fieldUpdated = true;
      } else if (
        undoPath === "marketing.infoNotes" &&
        obj === eventInfo.marketing &&
        "infoNotes" in obj
      ) {
        context.infoNotes = { ...obj.infoNotes, saved: null };
        fieldUpdated = true;
      }
    } else if (field in obj) {
      // Clear the specified field if it's a direct match
      context[field] = null;
      fieldUpdated = true;
    } else {
      // Recursive search in nested objects
      Object.keys(obj).forEach((key) => {
        if (obj[key] !== null && typeof obj[key] === "object") {
          let nestedContext = {};
          if (findAndUpdateField(obj[key], field, nestedContext)) {
            context[key] = { ...context[key], ...nestedContext };
            fieldUpdated = true;
          }
        }
      });
    }

    return fieldUpdated;
  };

  const handleUndoButtonClick = (
    index,
    path,
    field,
    originalValue,
    fieldDisplay
  ) => {
    setLoadingUndo(false);
    setRemoveChangesModal(true);
    setUndoIndex(index);
    setUndoField(field);
    setUndoPath(path);
    setOriginal(originalValue);
    setUndoFieldDisplay(fieldDisplay);
  };

  //Display state in words, don't display if value is "0"
  const getStateNameById = (id) => {
    if (id === "0") return "";

    const state = states?.find(
      (state) => state?.stateId?.toString() === id?.toString()
    );

    if (state) {
      return `${state.abbreviation} - ${state.state}`;
    } else {
      return null;
    }
  };

  const handleViewCreatedEvent = () => {
    changeShowSavedModal(false);
    history.push(`/events/view/event/localv2/${eventId}`);
  };

  const ruleset = eventInfo?.event?.ruleset.name;

  const onPublish = () => {
    eventPublish.mutateAsync();
  };

  const handlePublish = () => {
    changeShowSavedModal(true);

    onPublish();
  };

  if (isLoading && eventInfo) {
    return (
      <div className={styles.loader}>
        <div>
          <Loader />
        </div>
        <div className={styles.loaderText}>Loading Edit Local V2.0</div>
      </div>
    );
  }

  const countryIdName = {
    220: "U.S.",
    38: "CDN",
    40: "JAMAICA",
    221: "JAMAICA",
    300: "ANZ",
    151: "ANZ",
    13: "ANZ",
  };

  function transformData(array) {
    const valueArray = [];
    const savedArray = [];

    array.forEach((item) => {
      const valueObject = {};
      const savedObject = {};

      for (const key in item) {
        if (key === "tickets" && Array.isArray(item[key])) {
          const ticketValueArray = [];
          const ticketSavedArray = [];

          item[key].forEach((ticket) => {
            const ticketValueObj = {};
            const ticketSavedObj = {};

            if (ticket.id) {
              ticketValueObj.id = ticket.id;
              ticketSavedObj.id = ticket.id;
            } else if (ticket._id) {
              ticketValueObj._id = ticket._id;
              ticketSavedObj._id = ticket._id;
            }

            for (const ticketKey in ticket) {
              if (
                ticket[ticketKey] &&
                typeof ticket[ticketKey] === "object" &&
                ticket[ticketKey].hasOwnProperty("value") &&
                ticket[ticketKey].hasOwnProperty("saved")
              ) {
                ticketValueObj[ticketKey] = ticket[ticketKey].value;
                ticketSavedObj[ticketKey] = ticket[ticketKey].saved;
              }
            }

            ticketValueArray.push(ticketValueObj);
            ticketSavedArray.push(ticketSavedObj);
          });

          valueObject[key] = ticketValueArray;
          savedObject[key] = ticketSavedArray;
        } else if (
          item[key] &&
          typeof item[key] === "object" &&
          item[key].hasOwnProperty("value") &&
          item[key].hasOwnProperty("saved")
        ) {
          valueObject[key] = item[key].value;
          savedObject[key] = item[key].saved;
        } else if (key === "id") {
          valueObject.id = item[key];
          savedObject.id = item[key];
        } else if (key === "_id") {
          valueObject._id = item[key];
          savedObject._id = item[key];
        }
      }

      valueArray.push(valueObject);
      savedArray.push(savedObject);
    });

    return { valueArray: valueArray, savedArray: savedArray };
  }

  const fieldChanges = [
    {
      field: "Display Name",
      field_name: "title",
      path: "marketing.title",
      original_value: eventInfo?.marketing?.title?.value,
      saved_value: eventInfo?.marketing.title?.saved,
    },
    {
      field: "Event Description",
      field_name: "description",
      path: "marketing.description",
      original_value: eventInfo?.marketing.description?.value,
      saved_value: eventInfo?.marketing.description?.saved,
    },
    {
      field: "Speakers",
      field_name: "speakers",
      path: "speakers",
      original_value: eventInfo?.speakers?.value,
      saved_value: eventInfo?.speakers?.saved,
    },
    {
      field: "City",
      field_name: "city",
      path: "event.city",
      original_value: eventInfo.event.city?.value,
      saved_value: eventInfo.event.city?.saved,
    },
    {
      field: "Country",
      field_name: "country",
      path: "event.country",
      original_value: countryIdName[eventInfo.event.country?.value],
      saved_value: countryIdName[eventInfo.event.country?.saved],
    },
    {
      field: "State",
      field_name: "state",
      path: "event.state",
      original_value: eventInfo.event.state?.value
        ? getStateNameById(eventInfo.event.state?.value)
        : "",
      saved_value: eventInfo.event.state?.saved
        ? getStateNameById(eventInfo.event.state?.saved)
        : "",
    },
    {
      field: "Display Image",
      field_name: "displayImage",
      path: "marketing.displayImage",
      original_value: eventInfo.marketing?.displayImage?.value,
      saved_value: eventInfo.marketing?.displayImage?.saved,
    },
    {
      field: "Primary Caption",
      field_name: "primaryCaption",
      path: "marketing.primaryCaption",
      original_value: eventInfo.marketing?.primaryCaption?.value,
      saved_value: eventInfo.marketing?.primaryCaption?.saved,
    },
    {
      field: "Secondary Caption",
      field_name: "secondaryCaption",
      path: "marketing.secondaryCaption",
      original_value: eventInfo.marketing?.secondaryCaption?.value,
      saved_value: eventInfo.marketing?.secondaryCaption?.saved,
    },
    {
      field: "Visible on Calendar",
      field_name: "visibleCalendar",
      path: "marketing.visibleCalendar",
      original_value: eventInfo.marketing?.visibleCalendar?.value ? "Yes" : "",
      saved_value:
        eventInfo.marketing?.visibleCalendar?.saved === false
          ? "No"
          : eventInfo.marketing?.visibleCalendar?.saved
          ? "Yes"
          : "",
    },
    {
      field: "Visible To",
      field_name: "visibleTo",
      path: "marketing.visibleTo",
      original_value: eventInfo.marketing?.visibleTo?.value,
      saved_value: eventInfo.marketing?.visibleTo?.saved,
    },
    {
      field: "Tentative",
      field_name: "tentative",
      path: "marketing.tentative",
      original_value: eventInfo.marketing?.tentative?.value ? "Yes" : "",
      saved_value:
        eventInfo.marketing?.tentative?.saved === false
          ? "No"
          : eventInfo.marketing?.tentative?.saved
          ? "Yes"
          : "",
    },
    {
      field: "Sold Out",
      field_name: "soldOut",
      path: "analytics.sales.soldOut",
      original_value: eventInfo.analytics?.sales?.soldOut?.value ? "Yes" : "",
      saved_value:
        eventInfo.analytics?.sales?.soldOut?.saved === false
          ? "No"
          : eventInfo.analytics?.sales?.soldOut?.saved
          ? "Yes"
          : "",
    },
    {
      field: "Limit To Groups",
      field_name: "limitToGroups",
      path: "visibleTo",
      original_value: eventInfo?.visibleTo?.value,
      saved_value: eventInfo?.visibleTo?.saved,
    },
    {
      field: "Counting Tickets",
      field_name: "countingTickets",
      path: "analytics.countingTickets",
      original_value: eventInfo?.analytics?.countingTickets?.value ? "Yes" : "",
      saved_value:
        eventInfo?.analytics?.countingTickets?.saved === false
          ? "No"
          : eventInfo?.analytics?.countingTickets?.saved
          ? "Yes"
          : "",
    },
    {
      field: "Purchase Capacity",
      field_name: "purchase",
      path: "analytics.sales.capacity.purchase",
      original_value: eventInfo.analytics?.sales.capacity?.purchase?.value,
      saved_value: eventInfo.analytics?.sales.capacity?.purchase?.saved,
    },
    {
      field: "Estimated Attendance",
      field_name: "estimatedAttendance",
      path: "analytics.sales.capacity.estimatedAttendance",
      original_value:
        eventInfo.analytics?.sales.capacity?.estimatedAttendance?.value,
      saved_value:
        eventInfo.analytics?.sales.capacity?.estimatedAttendance?.saved,
    },
    {
      field: "Virtual Event Type",
      field_name: "virtualEventType",
      path: "virtual.eventType",
      original_value: eventData.virtual?.eventType?.value,
      saved_value: eventData.virtual?.eventType?.saved,
    },
    {
      field: "Virtual Event Link",
      field_name: "virtualEventLink",
      path: "virtual.eventLink",
      original_value: eventData.virtual?.eventLink?.value,
      saved_value: eventData.virtual?.eventLink?.saved,
    },
    {
      field: "Virtual Event Name",
      field_name: "virtualEventName",
      path: "virtual.eventName",
      original_value: eventData.virtual?.eventName?.value,
      saved_value: eventData.virtual?.eventName?.saved,
    },
    {
      field: "Event Venue",
      field_name: "venue",
      path: "venue",
      original_value: `${
        eventInfo?.venue?.company?.value?.venueName
          ? eventInfo?.venue?.company?.value?.venueName
          : ""
      }${
        eventInfo?.venue?.room?.value?.name
          ? ` (${eventInfo?.venue?.room?.value?.name})`
          : ""
      }`,
      saved_value: `${
        eventInfo?.venue?.company?.value?.netSuiteId &&
        eventInfo?.venue?.company?.saved?.noVenue &&
        !eventInfo?.venue?.company?.saved?.netSuiteId
          ? "No Event Venue"
          : `${
              eventInfo?.venue?.company?.saved?.venueName
                ? eventInfo?.venue?.company?.saved?.venueName
                : ""
            }${
              eventInfo?.venue?.room?.saved?.name
                ? ` (${eventInfo?.venue?.room?.saved?.name})`
                : ""
            }`
      }`,
    },
    {
      field: "Sessions",
      field_name: "sessions",
      path: "sessions",
      original_value: transformData(eventInfo.sessions).valueArray,
      saved_value: transformData(eventInfo.sessions).savedArray,
    },
    {
      field: "Hosts",
      field_name: "hosts",
      path: "hosts",
      original_value: eventInfo?.hosts?.value,
      saved_value: eventInfo?.hosts?.saved,
    },
    {
      field: "Internal Notes",
      field_name: "notes",
      path: "event.notes",
      original_value: eventInfo.event?.notes?.value
        ? removeExtraLineSpaces(decodeURI(eventInfo.event?.notes?.value))
        : "",
      saved_value: eventInfo.event?.notes?.saved
        ? removeExtraLineSpaces(decodeURI(eventInfo.event?.notes?.saved))
        : "",
    },
    {
      field: "Calendar Notes",
      field_name: "notes",
      path: "marketing.notes",
      original_value: eventInfo.marketing?.notes?.value
        ? removeExtraLineSpaces(decodeURI(eventInfo.marketing?.notes?.value))
        : "",
      saved_value: eventInfo.marketing?.notes?.saved
        ? removeExtraLineSpaces(decodeURI(eventInfo.marketing?.notes?.saved))
        : "",
    },
    {
      field: "Alert Notes",
      field_name: "alertNotes",
      path: "marketing.alertNotes",
      original_value: eventInfo.marketing?.alertNotes?.value
        ? removeExtraLineSpaces(
            decodeURI(eventInfo.marketing?.alertNotes?.value)
          )
        : "",
      saved_value: eventInfo.marketing?.alertNotes?.saved
        ? removeExtraLineSpaces(
            decodeURI(eventInfo.marketing?.alertNotes?.saved)
          )
        : "",
    },
    {
      field: "Info Notes",
      field_name: "infoNotes",
      path: "marketing.infoNotes",
      original_value: eventInfo.marketing?.infoNotes?.value
        ? removeExtraLineSpaces(
            decodeURI(eventInfo.marketing?.infoNotes?.value)
          )
        : "",
      saved_value: eventInfo.marketing?.infoNotes?.saved
        ? removeExtraLineSpaces(
            decodeURI(eventInfo.marketing?.infoNotes?.saved)
          )
        : "",
    },
    {
      field: "Ticketing",
      field_name: "ticketing",
      path: "ticketing",
      original_value: transformData(eventInfo.tickets).valueArray,
      saved_value: transformData(eventInfo.tickets).savedArray,
    },
  ].filter((change) => {
    if (
      ["countingTickets", "soldOut", "tentative", "visibleCalendar"].includes(
        change.field_name
      )
    ) {
      const isOriginalUnselected =
        !change.original_value || change.original_value === "No";
      const isSavedNo = change.saved_value === "No";
      const isSavedYes = change.saved_value === "Yes";

      return (
        !(isOriginalUnselected && isSavedNo) &&
        !(!isOriginalUnselected && isSavedYes)
      );
    }

    if (change.field_name === "sessions") {
      let hasChanges = false;
      change.saved_value.map((savedSession, index) => {
        Object.keys(savedSession).map((key) => {
          if (!Array.isArray(savedSession[key])) {
            if (savedSession[key] !== change.original_value[index][key]) {
              hasChanges = true;
            }
          }
        });
      });
      return hasChanges;
    } else if (change.field_name === "ticketing") {
      let hasChanges = false;
      change.saved_value.map((savedTickets, index) => {
        Object.keys(savedTickets).map((key) => {
          if (!Array.isArray(savedTickets[key])) {
            if (savedTickets[key] !== change.original_value[index][key]) {
              hasChanges = true;
            }
          }
        });
      });
      return hasChanges;
    } else {
      if (
        Array.isArray(change.original_value) &&
        change.original_value.length === 0 &&
        Array.isArray(change.saved_value) &&
        change.saved_value.length === 0
      ) {
        return false;
      }
    }

    return true;
  });

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const datesUndoChanges = (eventInfo) => {
    const dateFields = eventInfo?.event?.dates || {};
    const acronyms = ["SLM"];

    const undoChanges = Object.entries(dateFields).reduce((acc, [key, obj]) => {
      if ((key.endsWith("Date") || key.endsWith("TimeZone")) && obj?.saved) {
        const isDateField = key.endsWith("Date");
        const fieldName = key
          .replace("StartDate", " Begin")
          .replace("EndDate", " End")
          .replace("Date", isDateField ? "" : " Timezone")
          .replace(/([A-Z])/g, " $1")
          .trim()
          .split(" ")
          .map((word) =>
            acronyms.includes(word.toUpperCase())
              ? word.toUpperCase()
              : word.charAt(0).toUpperCase() + word.slice(1)
          )
          .join(" ");

        acc.push({
          field: capitalizeFirstLetter(fieldName),
          field_name: key,
          original_value: obj.value,
          saved_value: obj.saved,
        });
      }
      return acc;
    }, []);

    return undoChanges;
  };

  const dateChanges = datesUndoChanges(eventInfo);

  setTimeout(() => {
    // const unsavedChangesExist = checkSavedValues(eventInfo);
    const unsavedChangesExist = [...fieldChanges, ...dateChanges].length > 0;
    setShowUndoButton(unsavedChangesExist);
  }, 100);

  //Handles back button
  const handleBackClick = () => {
    const displayName =
      eventInfo.marketing.title.saved === null ||
      eventInfo.marketing.title.saved === "";

    // Trigger validation
    setTriggerValidation((prev) => !prev);

    setTimeout(() => {
      if (displayName) {
        setIsAlertModalOpen(true);
        return;
      }

      if ((isFormValid && hasUnsavedChanges) || !displayName) {
        queryClient.invalidateQueries(QUERY_KEYS.events("25"));
        queryClient.invalidateQueries(QUERY_KEYS.events("50"));
        queryClient.invalidateQueries(QUERY_KEYS.events("all"));
        queryClient.invalidateQueries(QUERY_KEYS.archivedEvents("25"));
        queryClient.invalidateQueries(QUERY_KEYS.archivedEvents("50"));
        queryClient.invalidateQueries(QUERY_KEYS.archivedEvents("all"));
        history.push("/events/events-details", { refetch: true });
        refetch();
      }
    }, 0);
  };

  const handleCloseAlert = () => {
    setIsAlertModalOpen(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div>
          <span className={styles.backButton} onClick={handleBackClick}>
            {"< Back"}
          </span>
          <h1 className={styles.title}>EDIT EVENT - LOCAL V2.0</h1>
        </div>

        <div className={styles.buttonContainer}>
          {showUndoButton && (
            <Button
              className={styles.undoButton}
              children={"UNDO CHANGES"}
              onClick={() => setUndoChangesModal(true)}
            />
          )}

          <Button
            className={
              eventInfo?.event?.status?.value === "Publishing"
                ? styles.greenButton
                : styles.saveButton
            }
            children={
              eventInfo?.event?.status?.value === "Publishing"
                ? "PUBLISHING"
                : "PUBLISH"
            }
            onClick={() => handlePublish()}
            disabled={!enablePublishButton}
          />
        </div>
      </div>

      <div className={styles.bodyContainer}>
        <EventTypeV2Card
          eventInfo={eventInfo?.event}
          onChange={handleEventTypeLocation}
        />

        <div className={styles.cardContainer}>
          {eventInfo && (
            <>
              <EventV2InfoForm
                marketingInfo={eventInfo}
                onBlur={handleMarketingOnBlur}
                pageMode="edit"
                triggerValidation={triggerValidation}
                onDateTimeChange={handleDateTimeChange}
                section="Event Information"
                onSpeakerChange={(newSpeakers) =>
                  handleSpeakerChange(newSpeakers)
                }
                onVirtualEventChange={(virtualEventId, virtualEventName) =>
                  handleVirtualEventChange(virtualEventId, virtualEventName)
                }
              />
            </>
          )}
        </div>
        <div className={styles.cardContainer}>
          {eventInfo && (
            <PublishingV2InfoForm
              publishingInfo={eventInfo}
              onPublishChange={handlePublishChange}
              pageMode="edit"
              onDateTimeChange={handleDateTimeChange}
              section="Publishing Information"
              onGroupChange={(newGroupsArray) =>
                handleGroupChange(newGroupsArray)
              }
              homesitesGroups={homesitesGroups}
              onCaptionChange={handleCaptionsChange}
              onDisplayImageChange={handlePublishChange}
            />
          )}
        </div>

        {/* <div className={styles.cardContainer}>
          {eventInfo && (
            <DateV2Form
              location={eventInfo?.event?.location?.value}
              ruleset={eventInfo?.event?.ruleset?.value}
              eventName={eventInfo?.event?.name?.value}
              dateInfo={eventInfo?.event}
              onDateTimeChange={handleDateTimeChange}
              pageMode="edit"
            />
          )}
        </div> */}

        {eventInfo?.event?.location?.value === "onsite" ? (
          <div className={styles.cardContainer}>
            <VenueV2Form
              venueInfo={eventInfo}
              onVenueChange={handleVenueChange}
              pageMode="edit"
            />
          </div>
        ) : (
          <div className={styles.cardContainer}>
            <VirtualV2Form
              type={
                eventInfo.virtual?.eventType?.saved ||
                eventInfo.virtual?.eventType?.saved
              }
              link={
                eventInfo.virtual?.eventLink?.saved ||
                eventInfo.virtual?.eventLink?.value
              }
              onVirtualChange={handleVirtualForm}
            />
          </div>
        )}

        {eventInfo?.event?.location?.value === "onsite" ? (
          <div className={styles.cardContainer}>
            {eventInfo && (
              <SessionsV2Form
                sessions={eventInfo.sessions}
                setSessions={handleSessions}
                eventData={eventData}
                pageMode="edit"
              />
            )}
          </div>
        ) : (
          ""
        )}

        <div className={styles.cardContainer}>
          <TicketingV2Form
            ticketSets={eventInfo.tickets}
            setTicketSets={(newTicketData) =>
              handleTicketingForm(newTicketData)
            }
            homesitesGroups={homesitesGroups}
            clearSessionsTicketSet={(ticketSetId) =>
              handleClearSessionsTicketSet(ticketSetId)
            }
          />
        </div>

        {/* {eventInfo?.event?.name?.value === "Regional" && ( */}
        <div className={styles.cardContainer}>
          {eventInfo && (
            <HostsV2Form
              hosts={eventInfo.hosts?.saved || eventInfo.hosts?.value}
              setHosts={(newHosts) => handleAddHosts(newHosts)}
            />
          )}
        </div>
        {/* )} */}

        <div className={styles.cardContainer}>
          {eventInfo && (
            <NotesV2Form
              notesData={eventInfo}
              onNotesChange={handleNotesChange}
              pageMode="edit"
            />
          )}
        </div>
      </div>

      <div className={styles.footerContainer}>
        <Button
          className={
            eventInfo?.event?.status?.value === "Publishing"
              ? styles.greenButton
              : styles.saveButton
          }
          children={
            eventInfo?.event?.status?.value === "Publishing"
              ? "PUBLISHING"
              : "PUBLISH"
          }
          onClick={() => handlePublish()}
          disabled={!enablePublishButton}
        />
      </div>

      <UndoChangesModal
        isOpen={undoChangesModal}
        onCollapse={() => setUndoChangesModal(false)}
        editedEvent={[...fieldChanges, ...dateChanges].filter(
          (change) => change.saved_value
        )}
        onClick={handleUndoButtonClick}
      />

      <EventPublishModal
        isOpen={showSavedModal}
        onCollapse={() => changeShowSavedModal(false)}
        onClick={() => history.push("/events/events-details")}
        onView={() => handleViewCreatedEvent()}
      />
      <DeleteModal
        header={"REMOVE HOST"}
        description={
          "Confirm that you would like to delete [Venue Name]. This action cannot be undone."
        }
        isOpen={showRemoveModal}
        onCollapse={() => changeShowRemoveModal(false)}
        onClick={() => console.log("Removed")}
        btnName={"REMOVE"}
      />

      <DeleteModal
        header={"REMOVE CHANGES"}
        description={`Are you sure you want to undo the changes made to ${formatFieldName(
          undoFieldDisplay
        )}?`}
        isOpen={removeChangesModal}
        onCollapse={() => setRemoveChangesModal(false)}
        onClick={handleUndoChangeAndUpdate}
        btnName={"REMOVE"}
        isLoading={loadingUndo}
      />

      <AlertModal isOpen={isAlertModalOpen} onClose={handleCloseAlert} />
    </div>
  );
}
