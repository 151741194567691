import styles from "./EditVenueModal.module.css";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { AddButton } from "Events/Components/AddButton/AddButton";
import { InputItem } from "../InputItem/InputItem";
import { Button } from "../../../shared/Button/Button";
import ModalActionBar from "../../../shared/ModalActionBar";
import { EditSelect } from "../EditSelect/EditSelect";
import Select from "../Select";
import { Modal } from "../../../shared/Modal";
import { RadioButtonList } from "../RadioButtonList/RadioButtonList";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";

/**
 *  TODO:
 *   - Replace Inputs with InputItem when merged with develop
 *   - Fix inevitable styling errors when doing so
 *   - add placeholder text when InputItem merged
 *   - change modal import to shared modal when Events/Modal is switched to shared folder
 *   - when InputItem is merged, change value to venue prop data
 */

export function EditVenueModal({
  isOpen,
  onCollapse,
  onClick,
  onDelete,
  venue,
  errorsList,
}) {
  useEffect(() => {
    if (isOpen && errorsList.length > 0) {
      const modalContainer = document.querySelector(`.${styles.modal}`);
      if (modalContainer) {
        modalContainer.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }, [isOpen, errorsList]);

  const venueToUse = venue ?? {};

  const { data: statesData, isLoading: isLoadingStates } = useCurrentRoute();

  const countryIdName = {
    220: "U.S.",
    38: "CDN",
    40: "JAMAICA",
    221: "JAMAICA",
    300: "ANZ",
    151: "ANZ",
  };

  const getCountryNameFromState = (state) => {
    let foundState = statesData.find((item) => item.state == state);
    if (!foundState) {
      foundState = statesData.find((item) => item.stateId == state);
    }

    const id = foundState ? foundState.countryId : 220;

    return countryIdName[id];
  };

  const [newName, setNewName] = useState(venueToUse.venueName ?? "");
  const [newAddress1, setNewAddress1] = useState(venueToUse.street1 ?? "");
  const [newAddress2, setNewAddress2] = useState(venueToUse.street2 ?? "");
  const [newCity, setNewCity] = useState(venueToUse.city ?? "");
  const [newState, setNewState] = useState(venueToUse.state ?? "");
  const [newCountry, setNewCountry] = useState(
    getCountryNameFromState(venueToUse.state)
  );
  const [newPhone, setNewPhone] = useState(venueToUse.phone ?? "");
  const [newZip, setNewZip] = useState(venueToUse.zip ?? "");
  const [newFax, setNewFax] = useState(venueToUse.fax ?? "");
  const [newEmail, setNewEmail] = useState(venueToUse.email ?? "");
  const [newURL, setNewURL] = useState(venueToUse.url ?? "");
  const [newRooms, setNewRooms] = useState(venueToUse.rooms ?? []);
  const [states, setStates] = useState([]);

  const handleFormSubmittal = () => {
    const formData = {
      venueId: venue.venueId,
      ruleset: venue.ruleset,
      netSuiteId: venue.netSuiteId,
      venueName: newName,
      street1: newAddress1,
      street2: newAddress2,
      city: newCity,
      state: newState,
      country: newCountry,
      zip: newZip,
      phone: newPhone,
      fax: newFax,
      email: newEmail,
      url: newURL,
      rooms: newRooms,
    };
    onClick(formData);
  };

  useEffect(() => {
    if (statesData) {
      const stateNames = statesData.map((state) => state.state);
      setStates(stateNames.filter((state) => state !== "N/A"));
    }
  }, [statesData]);

  useEffect(() => {
    // Update state variables when the venue prop changes
    setNewName(venueToUse.venueName ?? "");
    setNewAddress1(venueToUse.street1 ?? "");
    setNewAddress2(venueToUse.street2 ?? "");
    setNewCity(venueToUse.city ?? "");
    setNewCountry(getCountryNameFromState(venueToUse.state));
    setNewState(venueToUse.state ?? "");
    setNewZip(venueToUse.zip ?? "");
    setNewPhone(venueToUse.phone ?? "");
    setNewFax(venueToUse.fax ?? "");
    setNewEmail(venueToUse.email ?? "");
    setNewURL(venueToUse.url ?? "");
    setNewRooms(venueToUse.rooms ?? [{ name: "", capacity: "" }]);
  }, [venue]);

  useEffect(() => {
    if (venueToUse.state && statesData) {
      const selectedState = statesData.find(
        (item) => item.stateId.toString() === venueToUse.state.toString()
      );
      setNewState(selectedState ? selectedState.state : "");
    }
  }, [venue, statesData]);

  const onVenueRemove = (index) => {
    newRooms.length > 1 &&
      setNewRooms((arr) => arr.filter((e) => arr.indexOf(e) !== index));
    newRooms.length === 1 && setNewRooms(() => [{ name: "", capacity: "" }]);
  };

  const onVenueAdd = () => {
    setNewRooms((arr) => [...arr, { name: "", capacity: "" }]);
  };

  const handleStateChange = (value) => {
    setNewState(value.split(" - ")[1]); //Update to have all cases match when api hookup is done
    const stateWithId = statesData.find(
      (item) => item.state.toString() === value.split(" - ")[1]
    );
    setNewState(stateWithId.state);
  };

  const handleCountrySelect = (country) => {
    setNewCountry(country);
  };

  const getLabelForCountry = (country) => {
    switch (country) {
      case "CDN":
        return "Province*";
      case "JAMAICA":
        return "Parish*";
      default:
        return "State*";
    }
  };
  const getPostalLabelForCountry = (country, removeStar = false) => {
    switch (country) {
      case "CDN":
        return removeStar ? "Postal Code" : "Postal Code*";
      case "JAMAICA":
        return removeStar ? "Postal Code" : "Postal Code*";
      default:
        return removeStar ? "ZIP Code" : "ZIP Code*";
    }
  };

  const handleStateSelection = (value) => {
    setNewState(value);
  };

  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    if (statesData) {
      const matchingCountryIds = Object.keys(countryIdName).filter(
        (key) => countryIdName[key] === newCountry
      );

      if (matchingCountryIds.length > 0) {
        const filteredStates = statesData
          .filter((state) =>
            matchingCountryIds.includes(state.countryId.toString())
          )
          .filter((state) => state.state !== "N/A")
          .map((state) => `${state.abbreviation} - ${state.state}`);

        //setStateOptions if they have changed,
        setStateOptions((prevStateOptions) => {
          const newOptions = filteredStates;
          return prevStateOptions.join() !== newOptions.join()
            ? newOptions
            : prevStateOptions;
        });
      } else {
        setStateOptions([]);
      }
    }
  }, [statesData, newCountry]);

  const renderCountryField = () => {
    // Sequence of countries
    if (statesData) {
      const countrySequence = ["U.S.", "CDN", "ANZ", "JAMAICA"];

      const existingCountry = new Set(
        statesData.map((state) => state.countryId)
      );

      // Filter valid country options
      const countryOptions = countrySequence.filter((countryName) =>
        Object.entries(countryIdName).some(
          ([id, name]) =>
            name === countryName && existingCountry.has(parseInt(id))
        )
      );

      return (
        <span className={styles.radioButtonList}>
          <RadioButtonList
            onClick={handleCountrySelect}
            options={countryOptions}
            defaultValue={newCountry}
            groupName="venueCountry"
          />
        </span>
      );
    }
  };
  const renderStateSelection = () => {
    // const stateValue =
    //   eventData?.state?.value === 0 || eventData?.state === 0
    //     ? "N/A"
    //     : eventData?.state?.value || eventData?.state?.saved;

    // const hasStateError = validationErrors["state"];

    // Display state in words, don't display if value is "0"
    const getStateNameById = (id) => {
      if (id === "0") return "N/A";

      const state = statesData?.find(
        (state) => state?.stateId?.toString() === id?.toString()
      );

      if (state) {
        return `${state.abbreviation} - ${state.state}`;
      } else {
        return "N/A";
      }
    };

    return (
      <div className={styles.stateContainer}>
        <EditSelect
          label={getLabelForCountry(newCountry) || "State*"}
          options={stateOptions}
          placeholder={newState ? newState : "Select"}
          onChange={(value) => handleStateChange(value)}
          className={styles.selectDropdown}
        />
      </div>
    );
  };
  return (
    <Modal
      title="EDIT VENUE"
      isOpen={isOpen}
      onClose={onCollapse}
      className={styles.modal}
      children={
        <>
          {errorsList.length > 0 && (
            <div className={styles.errorsContainer}>
              {errorsList.map((error) => (
                <div className={styles.error}>{error}</div>
              ))}
            </div>
          )}
          <div className={styles.container}>
            <div className={styles.section}>
              <div className={styles.inputRow}>
                <div className={styles.rulesetContainer}>
                  <div className={styles.rulesetLabel}>Ruleset</div>
                  <div className={styles.rulesetData}>
                    {venueToUse?.ruleset}
                  </div>
                </div>
              </div>
              {venueToUse.netSuiteId && (
                <div className={styles.inputRow}>
                  <div className={styles.netSuiteContainer}>
                    <div className={styles.netSuiteLabel}>NetSuite ID</div>
                    <div className={styles.netSuiteData}>
                      {venueToUse.netSuiteId}
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.inputRow}>
                {venueToUse?.ruleset === "v2.0" ? (
                  <div className={styles.venueNameContainer}>
                    <div className={styles.venueNameLabel}>Venue Name</div>
                    <div className={styles.venueNameData}>{newName}</div>
                  </div>
                ) : (
                  <InputItem
                    label="Venue Name*"
                    placeholder=""
                    value={newName}
                    onChange={(e) => {
                      setNewName(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>

            <div className={styles.section}>
              {venueToUse?.ruleset === "v2.0" ? (
                <>
                  <div className={styles.inputRow}>
                    <div className={styles.address1Container}>
                      <div className={styles.address1Label}>Address 1</div>
                      <div className={styles.address1Data}>{newAddress1}</div>
                    </div>
                  </div>
                  <div className={styles.inputRow}>
                    <div className={styles.address2Container}>
                      <div className={styles.address2Label}>Address 2</div>
                      <div className={styles.address2Data}>{newAddress2}</div>
                    </div>
                  </div>
                  <div className={styles.inputRow}>
                    <div className={styles.cityContainer}>
                      <div className={styles.cityLabel}>City</div>
                      <div className={styles.cityData}>{newCity}</div>
                    </div>
                  </div>
                  <div className={styles.inputRow}>
                    <div className={styles.stateContainer}>
                      <div className={styles.stateLabel}>State</div>
                      <div className={styles.stateData}>
                        {venueToUse?.state}
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputRow}>
                    <div className={styles.zipContainer}>
                      <div className={styles.zipLabel}>
                        {getPostalLabelForCountry(newCountry, true) || "Zip"}
                      </div>
                      <div className={styles.zipData}>{newZip}</div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.inputRow}>
                    <InputItem
                      label="Address 1*"
                      placeholder=""
                      value={newAddress1}
                      onChange={(e) => {
                        setNewAddress1(e.target.value);
                      }}
                    />
                  </div>
                  <div className={styles.inputRow}>
                    <InputItem
                      label="Address 2*"
                      placeholder=""
                      value={newAddress2}
                      onChange={(e) => {
                        setNewAddress2(e.target.value);
                      }}
                    />
                  </div>
                  <div className={styles.radioCol}>
                    <span className={styles.label}>Country* </span>
                    {renderCountryField()}
                  </div>
                  <div className={styles.inputRow}>
                    <InputItem
                      label="City*"
                      placeholder=""
                      value={newCity}
                      onChange={(e) => {
                        setNewCity(e.target.value);
                      }}
                    />
                  </div>
                  <div className={styles.inputRow}>
                    {renderStateSelection()}
                  </div>
                  <div className={styles.inputRow}>
                    <InputItem
                      label={getPostalLabelForCountry(newCountry) || "Zip*"}
                      placeholder=""
                      value={newZip}
                      onChange={(e) => {
                        setNewZip(e.target.value);
                      }}
                    />
                  </div>
                </>
              )}
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Phone"
                  placeholder=""
                  value={newPhone}
                  onChange={(e) => {
                    setNewPhone(e.target.value);
                  }}
                />
              </div>
              {venueToUse.ruleset !== "v2.0" && (
                <div className={styles.inputRow}>
                  <InputItem
                    label="Venue Fax"
                    placeholder=""
                    value={newFax}
                    onChange={(e) => {
                      setNewFax(e.target.value);
                    }}
                  />
                </div>
              )}
              {venue &&
                venue.ruleset &&
                !venue.ruleset.toLowerCase().includes("v1.0") && (
                  <div className={styles.inputRow}>
                    <InputItem
                      label="Venue Email"
                      placeholder=""
                      value={newEmail}
                      onChange={(e) => {
                        setNewEmail(e.target.value);
                      }}
                    />
                  </div>
                )}
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue URL"
                  placeholder=""
                  value={newURL}
                  onChange={(e) => {
                    setNewURL(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={styles.section}>
              {newRooms?.map((room, index) => (
                <div className={styles.inputRow} key={index}>
                  <InputItem
                    label="Room"
                    value={room.name}
                    placeholder=""
                    onChange={(e) => {
                      room.name = e.target.value;
                      setNewRooms([...newRooms]);
                    }}
                  />
                  <InputItem
                    label="Capacity"
                    value={room.capacity}
                    placeholder=""
                    onChange={(e) => {
                      room.capacity = e.target.value;
                      setNewRooms([...newRooms]);
                    }}
                  />
                  <div className={styles.removeBtn}>
                    <Button
                      className={styles.remove}
                      children="Remove"
                      onClick={() => onVenueRemove(index)}
                    />
                  </div>
                </div>
              ))}
              <div className={styles.button}>
                <AddButton
                  color="#9AB5BB"
                  description={
                    newRooms.length == 0 ? "Add Room" : "Add Another Room"
                  }
                  onClick={onVenueAdd}
                />
              </div>
            </div>

            <div className={styles.saveBtn}>
              <ModalActionBar
                children={
                  <>
                    {!venue?.ruleset?.includes("2") && (
                      <Button
                        className={styles.delete}
                        children="ARCHIVE"
                        onClick={onDelete}
                      />
                    )}
                    <Button
                      className={styles.update}
                      children="UPDATE"
                      onClick={() => handleFormSubmittal()}
                    />
                  </>
                }
              />
            </div>
          </div>
        </>
      }
    />
  );
}

EditVenueModal.propTypes = {
  // Getter to determine if modal is displayed
  isOpen: PropTypes.bool,

  // Callback function for when the modal is closed
  onCollapse: PropTypes.func,

  // Function called when the save venue btn is clicked
  onClick: PropTypes.func,

  // Function called when the delete btn is clicked
  onDelete: PropTypes.func,

  // Object with the venues info
  venue: PropTypes.shape({
    venueName: PropTypes.string,
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    rooms: PropTypes.array,
    // rooms: PropTypes.arrayOf(PropTypes.string),
    venueURL: PropTypes.string,
    contacts: PropTypes.array,
  }),
};
