import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";
import classNames from "classnames";

import Card from "shared/Card";
import { useRef } from "react";
//import Button from "shared/Button";
import InputItem from "Events/Components/InputItem";
import AddButton from "Events/Components/AddButton";
import { Select } from "shared/Select/Select";
import DatePicker from "Events/Components/DatePicker";
import TimePicker from "Events/Components/TimePicker";
import TextArea from "shared/TextArea";
import Button from "shared/Button";
import styles from "./SessionsV2Form.module.css";
import DeleteModal from "Events/Components/DeleteModal";
import AddSpeakerModal from "Events/Components/AddSpeakerModal";
import moment from "moment-timezone";
import Link from "shared/Link";
import AddGroupModal from "Events/Components/AddGroupModal";
import { GroupsGet } from "Events/Components/TicketGroup/GroupsGet";
import { Modal } from "shared/Modal";
import Checkbox from "shared/@forms/Checkbox";
import Loader from "shared/Loader";

/**Area to fill out session information */
export function SessionsV2Form({
  sessions,
  setSessions,
  onAddSpeaker,
  eventData,
  pageMode,
}) {
  const history = useHistory();
  const [selectedSessionId, setSelectedSessionId] = useState(false);

  const [splitRooms, setSplitRooms] = useState({});

  const [btnOpened, setBtnOpened] = useState(false);
  const [showSpeakerModal, setShowSpeakerModal] = useState(false);
  const [indexToRemove, setIndexToRemove] = useState(0);
  const [sessionSpeakerIndex, setSessionSpeakerIndex] = useState(0);
  const [showTicketSetModal, setShowTicketSetModal] = useState(false);
  const [sessionTicketSetIndex, setSessionTicketSetIndex] = useState(0);
  const [selectedTicketSets, setSelectedTicketSets] = useState([]);
  useEffect(() => {
    setSelectedTicketSets(
      sessionsArray[sessionTicketSetIndex] &&
        sessionsArray[sessionTicketSetIndex]?.visibleTo?.saved
        ? sessionsArray[sessionTicketSetIndex]?.visibleTo?.saved
        : sessionsArray[sessionTicketSetIndex]?.visibleTo?.value || []
    );
  }, [sessionTicketSetIndex]);
  const [isLoading, setIsLoading] = useState(false);

  const addBtnOnClick = () => {
    setBtnOpened(true);
  };

  const initialSession = {
    type: "",
    sessionName: "",
    description: "",
    speakers: [],
    location: "",
    capacity: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    checkInStartDate: "",
    checkInStartTime: "",
    checkInEndDate: "",
    checkInEndTime: "",
    doorsOpenStartDate: "",
    doorsOpenStartTime: "",
    doorsOpenEndDate: "",
    doorsOpenEndTime: "",
  };

  const [sessionsArray, setSessionsArray] = useState([]);

  const [showRemoveModal, changeShowRemoveModal] = useState(false);

  // const [localStartTimeZone, setLocalStartTimeZone] = useState("CST");
  // const [localEndTimeZone, setLocalEndTimeZone] = useState("CST");

  const timeZones = [
    "Pacific Time (PT)",
    "Mountain Time (MT)",
    "Central Time (CT)",
    "Eastern Time (ET)",
    "Alaska Time (AKT)",
    "Hawaii-Aleutian Time (HAT)",
    "Hawaii-Honolulu (HST)",
    "Australian Eastern Standard Time (AEST)",
    "Australian Central Standard Time (ACST)",
    "Australian Western Standard Time (AWST)",
    "New Zealand Standard Time (NZST)",
    "Australian Eastern Daylight Time (AEDT)",
    "New Zealand Daylight Time (NZDT)",
  ];

  useEffect(() => {
    const processSessionData = (session) => {
      return Object.fromEntries(
        Object.entries(session).map(([key, details]) => {
          if (
            details &&
            typeof details === "object" &&
            "saved" in details &&
            "value" in details
          ) {
            const savedIsEmptyArray =
              Array.isArray(details.saved) && details.saved.length === 0;
            const valueIsEmptyArray =
              Array.isArray(details.value) && details.value.length === 0;

            if (!savedIsEmptyArray && details.saved !== null) {
              return [key, details.saved];
            } else if (!valueIsEmptyArray) {
              return [key, details.value];
            } else {
              return [key, null];
            }
          }
          return [key, details];
        })
      );
    };

    const sessionData = sessions.map(processSessionData);
    setSessionsArray(sessionData);
  }, [sessions]);

  const handleSessionAdd = () => {
    setIsLoading(true);
    const newSessions = [
      ...sessionsArray,
      {
        type: "",
        sessionName: "",
        description: "",
        speakers: [],
        location: "",
        capacity: "",
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        checkInStartDate: "",
        checkInStartTime: "",
        checkInEndDate: "",
        checkInEndTime: "",
        doorsOpenStartDate: "",
        doorsOpenStartTime: "",
        doorsOpenEndDate: "",
        doorsOpenEndTime: "",
      },
    ];
    // setSessionsArray(newSessions);

    setSessions([...sessionsArray, {}]).then(() => setIsLoading(false));
  };

  // useUpdateSession logic embedded directly here
  const debounceTimer = useRef(null); // Ref to track the debounce timer
  const pendingUpdates = useRef({}); // Ref to track pending updates to avoid conflicts

  const updateSession = (index, key, value) => {
    // Update the frontend state immediately
    setSessionsArray((prevSessions) => {
      const updatedSessions = [...prevSessions];
      updatedSessions[index] = { ...updatedSessions[index], [key]: value };

      return updatedSessions;
    });

    // Store the latest pending update
    pendingUpdates.current = { index, key, value };

    // Clear the previous timer
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Schedule a new backend update
    debounceTimer.current = setTimeout(() => {
      const {
        index: pendingIndex,
        key: pendingKey,
        value: pendingValue,
      } = pendingUpdates.current;

      // Ensure we only make the update for the latest changes
      if (
        pendingIndex === index &&
        pendingKey === key &&
        pendingValue === value
      ) {
        const sessionToUpdate = sessionsArray[index];
        let newDateString;

        // Handle date fields
        if (
          key === "startDate" ||
          key === "endDate" ||
          key === "checkInStartDate" ||
          key === "checkInEndDate" ||
          key === "doorsOpenStartDate" ||
          key === "doorsOpenEndDate"
        ) {
          const newDateMoment = moment(value, "MM/DD/YYYY");

          if (key === "startDate") {
            newDateString = moment(sessionToUpdate.startDate || moment())
              .year(newDateMoment.year())
              .month(newDateMoment.month())
              .date(newDateMoment.date())
              .hour(0)
              .minute(0)
              .second(0)
              .millisecond(0)
              .toISOString();
          } else if (key === "endDate") {
            newDateString = moment(sessionToUpdate.endDate || moment())
              .year(newDateMoment.year())
              .month(newDateMoment.month())
              .date(newDateMoment.date())
              .hour(0)
              .minute(0)
              .second(0)
              .millisecond(0)
              .toISOString();
          } else if (key === "checkInStartDate") {
            newDateString = moment(sessionToUpdate.checkInStartDate || moment())
              .year(newDateMoment.year())
              .month(newDateMoment.month())
              .date(newDateMoment.date())
              .hour(0)
              .minute(0)
              .second(0)
              .millisecond(0)
              .toISOString();
          } else if (key === "checkInEndDate") {
            newDateString = moment(sessionToUpdate.checkInEndDate || moment())
              .year(newDateMoment.year())
              .month(newDateMoment.month())
              .date(newDateMoment.date())
              .hour(0)
              .minute(0)
              .second(0)
              .millisecond(0)
              .toISOString();
          } else if (key === "doorsOpenStartDate") {
            newDateString = moment(
              sessionToUpdate.doorsOpenStartDate || moment()
            )
              .year(newDateMoment.year())
              .month(newDateMoment.month())
              .date(newDateMoment.date())
              .hour(0)
              .minute(0)
              .second(0)
              .millisecond(0)
              .toISOString();
          } else if (key === "doorsOpenEndDate") {
            newDateString = moment(sessionToUpdate.doorsOpenEndDate || moment())
              .year(newDateMoment.year())
              .month(newDateMoment.month())
              .date(newDateMoment.date())
              .hour(0)
              .minute(0)
              .second(0)
              .millisecond(0)
              .toISOString();
          }
        }

        // Handle time fields
        else if (
          key === "startTime" ||
          key === "endTime" ||
          key === "checkInStartTime" ||
          key === "checkInEndTime" ||
          key === "doorsOpenStartTime" ||
          key === "doorsOpenEndTime"
        ) {
          const newTimeMoment = moment(value, "HH:mm");

          if (key === "startTime") {
            newDateString = moment(sessionToUpdate.startDate || moment())
              .hour(newTimeMoment.hour())
              .minute(newTimeMoment.minute())
              .second(0)
              .millisecond(0)
              .toISOString();
          } else if (key === "endTime") {
            newDateString = moment(sessionToUpdate.endDate || moment())
              .hour(newTimeMoment.hour())
              .minute(newTimeMoment.minute())
              .second(0)
              .millisecond(0)
              .toISOString();
          } else if (key === "checkInStartTime") {
            newDateString = moment(sessionToUpdate.checkInStartDate || moment())
              .hour(newTimeMoment.hour())
              .minute(newTimeMoment.minute())
              .second(0)
              .millisecond(0)
              .toISOString();
          } else if (key === "checkInEndTime") {
            newDateString = moment(sessionToUpdate.checkInEndDate || moment())
              .hour(newTimeMoment.hour())
              .minute(newTimeMoment.minute())
              .second(0)
              .millisecond(0)
              .toISOString();
          } else if (key === "doorsOpenStartTime") {
            newDateString = moment(
              sessionToUpdate.doorsOpenStartDate || moment()
            )
              .hour(newTimeMoment.hour())
              .minute(newTimeMoment.minute())
              .second(0)
              .millisecond(0)
              .toISOString();
          } else if (key === "doorsOpenEndTime") {
            newDateString = moment(sessionToUpdate.doorsOpenEndDate || moment())
              .hour(newTimeMoment.hour())
              .minute(newTimeMoment.minute())
              .second(0)
              .millisecond(0)
              .toISOString();
          }
        }

        let keyToUse;
        if (key.includes("TimeZone")) {
          keyToUse = key;
        } else if (key.includes("Time")) {
          keyToUse = key.replace("Time", "Date");
        } else {
          keyToUse = key;
        }

        // Create a properly updated session object
        const formattedSession = {
          ...sessionToUpdate,
          [keyToUse]: newDateString || value,
        };

        // Update the sessions array with the new session
        const updatedSessions = [...sessionsArray];
        updatedSessions[index] = formattedSession;
        setSessions(updatedSessions);
      }
    }, 800); // Adjust delay as needed
  };

  const onRemove = () => {
    const sessionToRemove = sessionsArray[indexToRemove];
    const formattedSessions = sessionsArray.filter(
      (session, index) => index !== indexToRemove
    );

    setSessions(formattedSessions);
    changeShowRemoveModal(false);
  };

  const handleAddSpeaker = (speakers) => {
    const currentSpeakers = sessionsArray[sessionSpeakerIndex]?.speakers || [];

    // Filter out duplicates
    const newSpeakers = speakers.filter(
      (speaker) =>
        !currentSpeakers.find(
          (existingSpeaker) => existingSpeaker.wwgId === speaker.wwgId
        )
    );

    // Combine both arrays into one flat array
    const updatedSpeakers = [...currentSpeakers, ...newSpeakers];

    // Send the updated array
    updateSession(sessionSpeakerIndex, "speakers", updatedSpeakers);
  };

  const handleRemoveSpeaker = (speaker, sessionIndex) => {
    const speakersArray = sessionsArray[sessionIndex]?.speakers;
    const speakersToSend = speakersArray.filter(
      (speak) => speak.wwgId !== speaker.wwgId
    );

    updateSession(sessionIndex, "speakers", speakersToSend);
  };

  const renderAddSpeaker = (index) => {
    const speakerPermission = eventData?.speaker?.permission;

    const speakerSaved = sessionsArray[index]?.speakers;
    // const speakersToDisplay = speakerSaved?.length > 0 ? speakerSaved : speakerValue;
    if (speakerPermission === "write" || speakerPermission === undefined) {
      if (speakerSaved) {
        return (
          <>
            <div className={speakerSaved.length > 0 ? styles.speakerList : ""}>
              {speakerSaved.map((speaker) => (
                <div className={styles.speakerItem}>
                  <Link to={"/events/events-details"}>
                    {speaker?.name ||
                      speaker?.iboNumber ||
                      "No Name For Speaker"}
                  </Link>
                  <div
                    className={styles.removeSpeaker}
                    onClick={() => handleRemoveSpeaker(speaker, index)}
                  >
                    &times;
                  </div>
                </div>
              ))}
            </div>
            <div
              className={classNames(styles["button-container"])}
              onClick={() => {
                setSessionSpeakerIndex(index);
                setShowSpeakerModal(true);
              }}
            >
              <AddButton description={"Add Speaker"} color="#2A3847" />
            </div>
          </>
        );
      }
      return (
        <div
          className={classNames(styles["button-container"])}
          onClick={() => {
            setSessionSpeakerIndex(index);
            setShowSpeakerModal(true);
          }}
        >
          <AddButton description={"Add Speaker"} color="#2A3847" />
        </div>
      );
    }
  };

  const handleAddTicketSets = () => {
    const ticketSetsOnly = selectedTicketSets.map((item) => {
      return {
        ticketSet: item.ticketSet?.saved || item.ticketSet?.value,
        id: item.id,
      };
    });
    updateSession(sessionTicketSetIndex, "visibleTo", ticketSetsOnly);
  };

  const handleRemoveTicketSet = (ticketSet, sessionIndex) => {
    const ticketSetsArray = sessionsArray[sessionIndex]?.visibleTo;
    const ticketSetsToSend = ticketSetsArray.filter(
      (set) => set.id !== ticketSet.id
    );
    updateSession(sessionIndex, "visibleTo", ticketSetsToSend);
  };
  const renderAddTicketSets = (index) => {
    const ticketSetsSaved = sessionsArray[index]?.visibleTo;

    if (ticketSetsSaved) {
      return (
        <>
          <div className={ticketSetsSaved.length > 0 ? styles.speakerList : ""}>
            {ticketSetsSaved.map((ticketSet) => (
              <div className={styles.speakerItem}>
                {ticketSet?.ticketSet
                  ? `${ticketSet.ticketSet} Ticket Set`
                  : "No Name For Ticket Set"}
                <div
                  className={styles.removeSpeaker}
                  onClick={() => handleRemoveTicketSet(ticketSet, index)}
                >
                  &times;
                </div>
              </div>
            ))}
          </div>
          <div
            className={classNames(styles["button-container"])}
            onClick={() => {
              setSessionTicketSetIndex(index);
              setShowTicketSetModal(true);
            }}
          >
            <AddButton description={"Add Ticket Set"} color="#2A3847" />
          </div>
        </>
      );
    }
    return (
      <div
        className={classNames(styles["button-container"])}
        onClick={() => {
          setSessionTicketSetIndex(index);
          setShowTicketSetModal(true);
        }}
      >
        <AddButton description={"Add Ticket Set"} color="#2A3847" />
      </div>
    );
  };

  const redirectInvitees = () => {
    history.push("/events/manage-invitees");
  };

  return (
    <Card
      children={
        <section>
          <div className={styles.header}>
            <div className={styles.title}>SESSIONS</div>
            <div className={styles.button}>
              {isLoading ? (
                <Loader size={45} />
              ) : (
                <Button
                  children="Add"
                  size="small"
                  onClick={handleSessionAdd}
                />
              )}
            </div>
          </div>
          <div className={styles.container}>
            {sessionsArray.map((session, index) => (
              <div key={index} className={styles.section}>
                <hr className={styles.hr} />
                <div className={styles.row}>
                  <span className={styles.label}>
                    Visibility{" "}
                    {sessions[index]?.visibility?.required &&
                    pageMode === "edit"
                      ? " *"
                      : ""}
                  </span>
                  <span className={styles.visibleTo}>
                    <Select
                      placeholder={session.visibility || "Select"}
                      value={session.visibility}
                      selectedOption={session.visibility || "Select"}
                      options={["All", "Limit To Groups"]}
                      onChange={(type) =>
                        updateSession(index, "visibility", type)
                      }
                      removeSelectedOption={false}
                    />
                  </span>
                  <div className={styles.selectRemove}>
                    {session.type === "Invite Only" && (
                      <div className={styles.button}>
                        <Button
                          children="Manage Invitees"
                          size="small"
                          onClick={redirectInvitees}
                        />
                      </div>
                    )}

                    <div className={styles.remove}>
                      <Button
                        className={styles.removeButton}
                        children="Remove"
                        size="small"
                        onClick={
                          () => {
                            setIndexToRemove(index);
                            changeShowRemoveModal(true);
                          }
                          // sessionsArray.length >= 2
                          //   ? () => changeShowRemoveModal(true)
                          //   : null
                        }
                      />
                    </div>
                  </div>
                </div>

                {session.visibility == "Limit To Groups" && (
                  <div className={styles.row}>
                    <div className={styles.leftCol}>
                      <span className={styles.label}>Ticket Sets</span>
                      {renderAddTicketSets(index)}
                    </div>
                  </div>
                )}

                <div className={styles.session}>
                  <InputItem
                    label={`Session Name${
                      sessions[index]?.name?.required && pageMode === "edit"
                        ? " *"
                        : ""
                    }`}
                    placeholder="Click to type"
                    value={session.name || ""}
                    onChange={(e) =>
                      updateSession(index, "name", e.target.value)
                    }
                  />
                </div>
                <div className={classNames(styles.description)}>
                  <TextArea
                    label={"Description"}
                    placeholder="Click to type"
                    value={session.description || ""}
                    onChange={(e) =>
                      updateSession(index, "description", e.target.value)
                    }
                  />
                </div>
                <div className={styles.row}>
                  <div className={styles.leftCol}>
                    <span className={styles.label}>Speaker Name</span>
                    {renderAddSpeaker(index)}
                  </div>
                </div>

                <div className={styles.session}>
                  <InputItem
                    label="Location"
                    placeholder="Click to type"
                    value={session.location || ""}
                    onChange={(e) =>
                      updateSession(index, "location", e.target.value)
                    }
                  />
                </div>

                <div className={classNames(styles.session)}>
                  <InputItem
                    label="Capacity"
                    placeholder="Click to type"
                    value={session.capacity || ""}
                    onChange={(e) =>
                      updateSession(index, "capacity", e.target.value)
                    }
                  />
                </div>

                <div className={styles.datesItemFirst}>
                  <span className={styles.datesLabel}>
                    <span className={styles.datesMainLabel}>
                      Session Dates{" "}
                      {sessions[index]?.startDate?.required &&
                      pageMode === "edit"
                        ? " *"
                        : ""}
                    </span>
                  </span>

                  <div className={styles.datesSubItemContainer}>
                    <div className={styles.datesSubItem}>
                      <span className={styles.datesSubLabel}>Starts On:</span>
                      <span className={styles.datePicker}>
                        <DatePicker
                          dateString={moment(session.startDate).format(
                            "MM/DD/YYYY"
                          )}
                          setDate={(sDate) =>
                            updateSession(index, "startDate", sDate)
                          }
                        />
                      </span>
                      <span className={styles.timePicker}>
                        <TimePicker
                          timeString={
                            session.startDate
                              ? moment(session.startDate).format("HH:mm")
                              : ""
                          }
                          onChange={(sTime) =>
                            updateSession(index, "startTime", sTime)
                          }
                        />
                      </span>
                      <span className={styles.timezone}>
                        <Select
                          placeholder={
                            session.startTimeZone || "Select Time Zone"
                          }
                          options={timeZones}
                          onChange={(zone) =>
                            updateSession(index, "startTimeZone", zone)
                          }
                        />
                      </span>
                    </div>
                    <div className={styles.datesSubItem}>
                      <span className={styles.datesSubLabel}>Ends On:</span>
                      <span className={styles.datePicker}>
                        <DatePicker
                          dateString={moment(session.endDate).format(
                            "MM/DD/YYYY"
                          )}
                          setDate={(eDate) =>
                            updateSession(index, "endDate", eDate)
                          }
                        />
                      </span>
                      <span className={styles.timePicker}>
                        <TimePicker
                          timeString={
                            session.endDate
                              ? moment(session.endDate).format("HH:mm")
                              : ""
                          }
                          // onChange={setEndTime}
                          onChange={(eTime) =>
                            updateSession(index, "endTime", eTime)
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <div className={styles.datesItem}>
                  <span className={styles.datesLabel}>
                    <span className={styles.datesMainLabel}>
                      Check In{" "}
                      {sessions[index]?.checkInStartDate?.required &&
                      pageMode === "edit"
                        ? " *"
                        : ""}
                    </span>
                  </span>

                  <div className={styles.datesSubItemContainer}>
                    <div className={styles.datesSubItem}>
                      <span className={styles.datesSubLabel}>Starts On:</span>
                      <span className={styles.datePicker}>
                        <DatePicker
                          dateString={moment(session.checkInStartDate).format(
                            "MM/DD/YYYY"
                          )}
                          setDate={(cStartDate) =>
                            updateSession(index, "checkInStartDate", cStartDate)
                          }
                        />
                      </span>
                      <span className={styles.timePicker}>
                        <TimePicker
                          timeString={
                            session.checkInStartDate
                              ? moment(session.checkInStartDate).format("HH:mm")
                              : ""
                          }
                          onChange={(cStartTime) =>
                            updateSession(index, "checkInStartTime", cStartTime)
                          }
                        />
                      </span>
                      <span className={styles.timezone}>
                        <Select
                          placeholder={
                            session.checkInStartTimeZone || "Select Time Zone"
                          }
                          options={timeZones}
                          onChange={(zone) =>
                            updateSession(index, "checkInStartTimeZone", zone)
                          }
                        />
                      </span>
                    </div>
                    <div className={styles.datesSubItem}>
                      <span className={styles.datesSubLabel}>Ends On:</span>
                      <span className={styles.datePicker}>
                        <DatePicker
                          dateString={moment(session.checkInEndDate).format(
                            "MM/DD/YYYY"
                          )}
                          setDate={(cEndDate) =>
                            updateSession(index, "checkInEndDate", cEndDate)
                          }
                        />
                      </span>
                      <span className={styles.timePicker}>
                        <TimePicker
                          timeString={
                            session.checkInEndDate
                              ? moment(session.checkInEndDate).format("HH:mm")
                              : ""
                          }
                          // onChange={setEndTime}
                          onChange={(cEndTime) =>
                            updateSession(index, "checkInEndTime", cEndTime)
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <div className={styles.datesItem}>
                  <span className={styles.datesLabel}>
                    <span className={styles.datesMainLabel}>
                      Doors Open{" "}
                      {sessions[index]?.doorsOpenStartDate?.required &&
                      pageMode === "edit"
                        ? " *"
                        : ""}
                    </span>
                  </span>

                  <div className={styles.datesSubItemContainer}>
                    <div className={styles.datesSubItem}>
                      <span className={styles.datesSubLabel}>Starts On:</span>
                      <span className={styles.datePicker}>
                        <DatePicker
                          dateString={moment(session.doorsOpenStartDate).format(
                            "MM/DD/YYYY"
                          )}
                          setDate={(dStartDate) =>
                            updateSession(
                              index,
                              "doorsOpenStartDate",
                              dStartDate
                            )
                          }
                        />
                      </span>
                      <span className={styles.timePicker}>
                        <TimePicker
                          timeString={
                            session.doorsOpenStartDate
                              ? moment(session.doorsOpenStartDate).format(
                                  "HH:mm"
                                )
                              : ""
                          }
                          onChange={(dStartTime) =>
                            updateSession(
                              index,
                              "doorsOpenStartTime",
                              dStartTime
                            )
                          }
                        />
                      </span>
                      <span className={styles.timezone}>
                        <Select
                          placeholder={
                            session.doorsOpenStartTimeZone || "Select Time Zone"
                          }
                          options={timeZones}
                          onChange={(zone) =>
                            updateSession(index, "doorsOpenStartTimeZone", zone)
                          }
                        />
                      </span>
                    </div>
                    <div className={styles.datesSubItem}>
                      <span className={styles.datesSubLabel}>Ends On:</span>
                      <span className={styles.datePicker}>
                        <DatePicker
                          dateString={moment(session.doorsOpenEndDate).format(
                            "MM/DD/YYYY"
                          )}
                          setDate={(dEndDate) =>
                            updateSession(index, "doorsOpenEndDate", dEndDate)
                          }
                        />
                      </span>
                      <span className={styles.timePicker}>
                        <TimePicker
                          timeString={
                            session.doorsOpenEndDate
                              ? moment(session.doorsOpenEndDate).format("HH:mm")
                              : ""
                          }
                          // onChange={setEndTime}
                          onChange={(dEndTime) =>
                            updateSession(index, "doorsOpenEndTime", dEndTime)
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <DeleteModal
            header={"REMOVE SESSION"}
            description={"Confirm that you would like to remove session?"}
            isOpen={showRemoveModal}
            onCollapse={() => changeShowRemoveModal(false)}
            onClick={() => onRemove()}
            btnName={"REMOVE"}
          />
          <AddSpeakerModal
            isOpen={showSpeakerModal}
            onCollapse={() => {
              setSessionSpeakerIndex(0);
              setShowSpeakerModal(false);
            }}
            onClick={(speakers) => handleAddSpeaker(speakers)}
          />
          <Modal
            title="Ticket Sets"
            isOpen={showTicketSetModal}
            onClose={() => setShowTicketSetModal(false)}
            children={
              <div className={styles.modal}>
                <div className={styles.optionsListContainer}>
                  <div className={styles.optionsListBody}>
                    {(eventData.tickets || []).map((ticketSet) => {
                      return (
                        <div
                          className={styles.optionItemContainer}
                          key={ticketSet.id}
                        >
                          <div className={styles.optionItem}>
                            <Checkbox
                              className={styles.optionCheckbox}
                              onChange={() => {
                                const isAdded = selectedTicketSets.find(
                                  (tktset) => tktset.id == ticketSet.id
                                );
                                if (isAdded) {
                                  setSelectedTicketSets([
                                    ...selectedTicketSets.filter(
                                      (tktset) => tktset.id !== ticketSet.id
                                    ),
                                  ]);
                                } else {
                                  setSelectedTicketSets([
                                    ...selectedTicketSets,
                                    ticketSet,
                                  ]);
                                }
                              }}
                              checked={selectedTicketSets.find(
                                (tktSet) => tktSet.id == ticketSet.id
                              )}
                            />
                          </div>
                          <div className={styles.optionItem}>
                            {ticketSet.ticketSet?.saved
                              ? `${ticketSet?.ticketSet.saved} Ticket Set`
                              : `${ticketSet?.ticketSet.value} Ticket Set` ||
                                "No Name For Ticket Set"}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <Button
                  className={styles.updateButton}
                  children={"Update"}
                  onClick={() => {
                    handleAddTicketSets();
                    setSelectedTicketSets([]);
                    setShowTicketSetModal(false);
                  }}
                />
              </div>
            }
          />
        </section>
      }
    />
  );
}

SessionsV2Form.propTypes = {
  /**Session type displays the name of the session that is assigned by IT during the population of the template. This field is not editable.  */
  type: PropTypes.string,
  /**Ticket group displays the ticket groups that are allowed to attend the session and are assign by IT during the population of the template. This field is not editable.  */
  ticketGroups: PropTypes.arrayOf(PropTypes.string),
  /**Value of session name  */
  sessionName: PropTypes.string,
  /**Sets the value of the session name when changed  */
  setSessionName: PropTypes.func,
  /**Description of the session  */
  description: PropTypes.string,
  /**Sets the value of the description when changed  */
  setDescription: PropTypes.func,
  /**Array of speaker objects  */
  speakers: PropTypes.arrayOf(PropTypes.shape({})),
  /**Function used when adding more speakers  */
  setSpeakers: PropTypes.func,
  /**Array containing the rooms associated with the venue selected in the venue form earlier in the page */
  rooms: PropTypes.arrayOf(PropTypes.string),
  /**Value of the room selected  */
  room: PropTypes.string,
  /**Sets the value of the room when changed  */
  setRoom: PropTypes.func,
  /**Value of the start date  */
  startDate: PropTypes.string,
  /**Sets the value of the start date when changed  */
  setStartDate: PropTypes.func,
  /**Value of the end date  */
  endDate: PropTypes.string,
  /**Sets the value of the end date when changed  */
  setEndDate: PropTypes.func,
  /**Value of the start time  */
  startTime: PropTypes.string,
  /**Sets the value of the start time when changed  */
  setStartTime: PropTypes.func,
  /**Value of the end time  */
  endTime: PropTypes.string,
  /**Sets the value of the end time when changed  */
  setEndTime: PropTypes.func,
};
