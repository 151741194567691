import React, { useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./VenueInfoCard.module.css";
import { Modal } from "shared/Modal";
import Button from "shared/Button";
import Select from "../Select";
import AddButton from "../AddButton";
import InputItem from "Events/Components/InputItem";

/**
 *
 * Displays the Venues Information
 *
 */

export function VenueInfoCard({
  venueInfo,
  pageMode,
  isV2,
  fullVenue = null,
  onEdit,
  redBorder = false,
}) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [venueName, setVenueName] = useState("");
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [url, setUrl] = useState("");
  const [phone, setPhone] = useState("");
  const [fax, setFax] = useState("");
  const [email, setEmail] = useState("");
  const [roomName, setRoomName] = useState("");
  const [roomCapacity, setRoomCapacity] = useState("");

  useEffect(() => {
    const useSavedValues = venueInfo.company?.saved?.netSuiteId ? true : false;

    const getField = (savedField, valueField) => {
      const field = useSavedValues ? savedField : valueField;
      return field !== null && field !== undefined ? field : "";
    };

    const fullVenue = {
      venueName: getField(
        venueInfo.company?.saved?.venueName,
        venueInfo.company?.value?.venueName
      ),
      street1: getField(
        venueInfo.company?.saved?.street1,
        venueInfo.company?.value?.street1
      ),
      street2: getField(
        venueInfo.company?.saved?.street2,
        venueInfo.company?.value?.street2
      ),
      city: getField(
        venueInfo.company?.saved?.city,
        venueInfo.company?.value?.city
      ),
      country: getField(
        venueInfo.company?.saved?.country,
        venueInfo.company?.value?.country
      ),
      state: getField(
        venueInfo.company?.saved?.state,
        venueInfo.company?.value?.state
      ),
      zip: getField(
        venueInfo.company?.saved?.zip,
        venueInfo.company?.value?.zip
      ),
      url: getField(
        venueInfo.company?.saved?.url,
        venueInfo.company?.value?.url
      ),
      phone: getField(
        venueInfo.company?.saved?.phone,
        venueInfo.company?.value?.phone
      ),
      fax: getField(
        venueInfo.company?.saved?.fax,
        venueInfo.company?.value?.fax
      ),
      email: getField(
        venueInfo.company?.saved?.email,
        venueInfo.company?.value?.email
      ),
    };

    const fullRoom = {
      name: getField(venueInfo.room?.saved?.name, venueInfo.room?.value?.name),
      capacity: getField(
        venueInfo.room?.saved?.capacity,
        venueInfo.room?.value?.capacity
      ),
    };

    if (fullVenue) {
      setVenueName(fullVenue.venueName);
      setStreet1(fullVenue.street1);
      setStreet2(fullVenue.street2);
      setCity(fullVenue.city);
      setCountry(fullVenue.country);
      setState(fullVenue.state);
      setZip(fullVenue.zip);
      setUrl(fullVenue.url);
      setPhone(fullVenue.phone);
      setFax(fullVenue.fax);
      setEmail(fullVenue.email);
      setRoomName(fullRoom.name);
      setRoomCapacity(fullRoom.capacity);
    }
  }, [venueInfo]);

  const handleFormSubmittal = async () => {
    const fullVenue = venueInfo.company.saved || venueInfo.company.value;
    const fullRoom = venueInfo.room.saved || venueInfo.room.value;

    const payload = {
      ...fullVenue,
      venueName: venueName || null,
      street1: street1 || null,
      street2: street2 || null,
      city: city || null,
      zip: zip || null,
      url: url || null,
      phone: phone || null,
      fax: fax || null,
      email: email || null,
    };

    const roomPayload = {
      ...fullRoom,
      name: roomName || null,
      capacity: roomCapacity || null,
    };

    onEdit(payload, roomPayload);

    setIsEditModalOpen(false);
  };

  const [venueData, setVenueData] = useState({
    venueName: "",
    street1: "",
    street2: "",
    stateAbbr: "",
    city: "",
    zip: "",
    url: "",
    phone: "",
    fax: "",
    email: "",
    capacity: "",
    room: "",
  });

  useEffect(() => {
    const { company, room } = venueInfo;
    const useSavedValues = company.saved?.netSuiteId ? true : false;

    const getField = (savedField, valueField, defaultValue) => {
      const field = useSavedValues ? savedField : valueField;
      return field !== null && field !== undefined ? field : defaultValue;
    };

    const newVenueInfo = {
      venueName: getField(
        company.saved?.venueName,
        company.value?.venueName,
        ""
      ),
      street1: getField(company.saved?.street1, company.value?.street1, ""),
      street2: getField(company.saved?.street2, company.value?.street2, ""),
      city: getField(company.saved?.city, company.value?.city, ""),
      stateAbbr: getField(
        company.saved?.stateAbbr || company.saved?.state, 
        company.value?.stateAbbr || company.value?.state,
        ""
      ),
      zip: getField(company.saved?.zip, company.value?.zip, ""),
      url: getField(company.saved?.url, company.value?.url, "N/A"),
      phone: getField(company.saved?.phone, company.value?.phone, "N/A"),
      fax: getField(company.saved?.fax, company.value?.fax, "N/A"),
      email: getField(company.saved?.email, company.value?.email, "N/A"),
      capacity: getField(room.saved?.capacity, room.value?.capacity, "N/A"),
      room: getField(room.saved?.name, room.value?.name, "N/A"),
    };

    setVenueData(newVenueInfo);
  }, [venueInfo]);

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      return phoneNumber;
    }
    const digits = phoneNumber.replace(/\D/g, "");

    if (digits.length === 10) {
      return `(${digits.substr(0, 3)})-${digits.substr(3, 3)}-${digits.substr(
        6
      )}`;
    }
    return phoneNumber;
  };

  return (
    <div className={redBorder ? styles.redContainer : styles.container}>
      <div className={isV2 ? styles.grid2 : styles.grid}>
        {isV2 && (
          <div className={styles.link} onClick={() => setIsEditModalOpen(true)}>
            Edit Venue Info
          </div>
        )}
        <div className={classNames(styles.item, styles.left)}>Venue Name</div>
        <div className={styles.item}>{venueData.venueName}</div>

        <div className={classNames(styles.item, styles.left)}>Address</div>
        <div className={classNames(styles.item, styles.address)}>
          <span>
            {[venueData.street1, venueData.street2].filter(Boolean).join(", ")}
          </span>
          <span>
            {[
              venueData.city,
              venueData.stateAbbr && venueData.stateAbbr + " " + venueData.zip,
            ]
              .filter(Boolean)
              .join(", ")}
          </span>
        </div>

        <div className={classNames(styles.item, styles.left)}>Capacity</div>
        <div className={styles.item}>{venueData.capacity}</div>

        <div className={classNames(styles.item, styles.left)}>Room</div>
        <div className={styles.item}>{venueData.room}</div>

        <div className={classNames(styles.item, styles.left)}>Venue URL</div>
        <div className={styles.item}>
          <a href={venueData.url} target="_blank" rel="noopener noreferrer">
            {venueData.url}
          </a>
        </div>

        <div className={classNames(styles.item, styles.left)}>Venue Phone</div>
        <div className={styles.item}>{formatPhoneNumber(venueData.phone)}</div>

        <div className={classNames(styles.item, styles.left)}>Venue Fax</div>
        <div className={styles.item}>{formatPhoneNumber(venueData.fax)}</div>

        <div className={classNames(styles.item, styles.left)}>Venue Email</div>
        <div className={styles.item}>{venueData.email}</div>
      </div>

      <Modal
        title="Edit Venue"
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
        }}
        children={
          <>
            <div className={styles.modal}>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Name"
                  placeholder=""
                  value={venueName}
                  onChange={(e) => {
                    setVenueName(e.target.value);
                  }}
                />
              </div>

              <div className={styles.inputRow}>
                <InputItem
                  label="Street 1"
                  placeholder=""
                  value={street1}
                  onChange={(e) => {
                    setStreet1(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Street 2"
                  placeholder=""
                  value={street2}
                  onChange={(e) => {
                    setStreet2(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Country"
                  placeholder=""
                  value={country}
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="City"
                  placeholder=""
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="State"
                  placeholder=""
                  value={state}
                  onChange={(e) => {
                    setState(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label={"Zip"}
                  placeholder=""
                  value={zip}
                  onChange={(e) => {
                    setZip(e.target.value);
                  }}
                />
              </div>

              <div className={styles.inputRow}>
                <InputItem
                  label="URL"
                  placeholder=""
                  value={url}
                  onChange={(e) => {
                    setUrl(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Phone"
                  placeholder=""
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Fax"
                  placeholder=""
                  value={fax}
                  onChange={(e) => {
                    setFax(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Email"
                  placeholder=""
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>

              <div className={styles.inputRow}>
                <InputItem
                  label="Room"
                  value={roomName}
                  placeholder=""
                  onChange={(e) => {
                    setRoomName(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Capacity"
                  value={roomCapacity}
                  placeholder=""
                  onChange={(e) => {
                    setRoomCapacity(e.target.value);
                  }}
                />
              </div>

              <div className={styles.saveBtn}>
                <Button
                  className={styles.update}
                  children="UPDATE"
                  onClick={() => handleFormSubmittal()}
                />
              </div>
            </div>
          </>
        }
      />
    </div>
  );
}

VenueInfoCard.propTypes = {
  venueInfo: PropTypes.object,

  onEdit: PropTypes.func,
};

VenueInfoCard.defaultProps = {
  venueInfo: {
    name: "",
    address1: "",
    address2: "",
    rooms: [],
    capacity: "",
    venueUrl: "",
    contactName: "",
    phone: "",
    fax: "",
    email: "",
  },
};
