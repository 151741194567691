import PropTypes from "prop-types";
import styles from "./CreateNewCard.module.css";

/**
 * Component that opens the new event modal
 */

export function CreateNewCard({ title, onClick, text }) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <button className={styles.button} onClick={onClick}>
          {text || "CREATE NEW"}
        </button>
      </div>
    </div>
  );
}

CreateNewCard.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};
