import styles from "./index.module.css";
import React, { useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import Link from "shared/Link";
import Card from "shared/Card";

import { RECENT_EVENTS_KEY } from "../../OrdersByIBO/index";
import { descendingBy } from "shared/@utils/sort";
import { useMount } from "react-use";

dayjs.extend(relativeTime);

export default function NoSelection() {
  const [recents, setRecents] = useState([]);

  useMount(() => {
    setRecents(
      JSON.parse(sessionStorage.getItem(RECENT_EVENTS_KEY) || "[]").sort(
        descendingBy("timestamp")
      )
    );
  });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>No Event selected</h1>
        <p>But you can use the search box above to find one.</p>
      </div>

      <Card className={styles.tips}>
        <header>
          <h3>Search Tips</h3>
        </header>

        <section>
          <h4 className={styles.tipLabel}>Search by event type</h4>
          <p className={styles.tipText}>
            Enter <code>{"type:sl"}</code> to search for all Spring Leaderships.
            Valid types: type:SL, type:FED, type:FR
          </p>
        </section>
        <section>
          <h4 className={styles.tipLabel}>Search by event location</h4>
          <p className={styles.tipText}>
            Enter <code>{"location:spokane"}</code> to search for all events in
            Spokane.
          </p>
        </section>
        <section>
          <h4 className={styles.tipLabel}>Search by event state</h4>
          <p className={styles.tipText}>
            Enter <code>{"state:wa"}</code> to search for all events in
            Washington.
          </p>
        </section>
        {/* <section>
          <h4 className={styles.tipLabel}>Search by event month</h4>
          <p className={styles.tipText}>
            Enter <code>{"month:5"}</code> to search for all events in May.
            *This is not yet implemented*
          </p>
        </section> */}
        <section>
          <h4 className={styles.tipLabel}>Search by event year</h4>
          <p className={styles.tipText}>
            Enter <code>{"year:2022"}</code> to search for all events in the
            year of 2022.
          </p>
        </section>
        <section>
          <h4 className={styles.tipLabel}>Combine search criteria</h4>
          <p className={styles.tipText}>
            Enter <code>{"type:sl state:wa year:2022"}</code> to search for all
            Spring Leaderships in Washington for 2022.
          </p>
        </section>
      </Card>

      {recents.length > 0 && (
        <section className={styles.recents}>
          <header>
            <h2>Recently Viewed</h2>
          </header>

          <ol className={styles.recentsList}>
            {recents.map((item, index) => (
              <li key={item.EventID || index}>
                <Link to={`/events/manage/${item.EventID}`}>
                  {item.EventID} {item.name}
                </Link>{" "}
                ({dayjs(item.timestamp).fromNow()})
              </li>
            ))}
          </ol>
        </section>
      )}
    </div>
  );
}
