import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import EditTicketModal from "Events/Components/EditTicketModal";
import TicketGroupItem from "Events/Components/TicketGroupItem";
import Input from "shared/@forms/Input";
import Button from "shared/Button";
import { Select } from "shared/Select/Select";
import { Modal } from "shared/Modal";
import Checkbox from "shared/@forms/Checkbox";
import AddGroupModal from "../AddGroupModal";
import { GroupsGet } from "./GroupsGet";
import InputItem from "Events/Components/InputItem";
import styles from "./TicketGroup.module.css";
import Loader from "shared/Loader";
import DatePicker from "../DatePicker";
import TimePicker from "../TimePicker";

import AddButton from "../AddButton";
import classNames from "classnames";
import moment from "moment-timezone";

import { ImagePicker } from "Events/Components/ImagePicker/ImagePicker";

/**Displays the information for a ticket group and all the ticket options. The user can update the number of tickets available. If edit is clicked in the sub header, then the limit and tickets per will be editable with a select and input box.  */
export function TicketGroup({
  name,
  available,
  setAvailable,
  limit,
  setLimit,
  ticketsPer,
  setTicketsPer,
  tickets,
  updateTickets,
  eventStartDate,
  isMajorV1 = false,
  isV2,
  ticketSet = [],
  setTicketSet,
  removeTicketSet,
  onAddTicket,
  homesitesGroups,
}) {
  const {
    data: groupData,
    isLoading: isGroupsLoading,
    refetch: refetchGroupsGet,
  } = GroupsGet();

  const timeZones = [
    "Pacific Time (PT)",
    "Mountain Time (MT)",
    "Central Time (CT)",
    "Eastern Time (ET)",
    "Alaska Time (AKT)",
    "Hawaii-Aleutian Time (HAT)",
    "Hawaii-Honolulu (HST)",
  ];

  const [ticketName, setTicketName] = useState("");
  const [price, setPrice] = useState("");
  const [id, setID] = useState("");
  const [showAddTicketModal, setShowAddTicketModal] = useState(false);
  const [showEditPurchaseLimits, setShowEditPurchaseLimits] = useState(false);
  const [ticketsPerField, setTicketsPerField] = useState("Tickets Per User");
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingTicket, setEditingTicket] = useState({});
  const [groups, setGroups] = useState([]);
  const [selectedTicketId, setSelectedTicketId] = useState("");
  const [ticketsWithGroups, setTicketsWithGroups] = useState(
    ticketSet.tickets
      ? ticketSet.tickets.map((ticket) => ({
          ticketId: ticket.id,
          groups:
            ticket.limitedToGroups.saved ?? ticket.limitedToGroups.value ?? [],
        }))
      : []
  );
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const acceptedCreditOptions = [
    {
      creditTypeId: "LF",
      name: "Local Function Credit",
    },
    {
      creditTypeId: "MF",
      name: "Major Function Credit",
    },
    {
      creditTypeId: "FE25_FPL",
      name: "FE 2025 - Founders Platinum Credit",
    },
    {
      creditTypeId: "LS26_FPL",
      name: "LS 2026 - Founders Platinum Credit",
    },
    {
      creditTypeId: "FR26_FPL",
      name: "FR 2026 - Founders Platinum Credit",
    },
  ];
  const [isAcceptedCreditModalOpen, setIsAcceptedCreditModalOpen] =
    useState(false);
  const [acceptedCreditArray, setAcceptedCreditArray] = useState([]);

  const [isPurchaseDatesModalOpen, setIsPurchaseDatesModalOpen] =
    useState(false);

  useEffect(() => {
    if (groupData) {
      setGroups(groupData);
    }
  }, [groupData]);

  useEffect(() => {
    if (ticketSet.tickets && ticketsWithGroups.length === 0) {
      setTicketsWithGroups(
        ticketSet.tickets.map((ticket) => {
          return {
            ticketId: ticket.id,
            groups: [],
          };
        })
      );
    }
  }, [ticketSet, ticketsWithGroups]);

  const handleGroupSelection = (groupData) => {
    setTicketsWithGroups((prevTickets) => {
      return prevTickets.map((ticket) => {
        if (ticket.ticketId === selectedTicketId) {
          // Check if the group already exists in the array
          const groupExists = ticket.groups.some(
            (group) => group.id === groupData.id
          ); // Compare based on ID

          // Return a new object with updated groups
          return {
            ...ticket,
            groups: groupExists
              ? ticket.groups.filter((group) => group.id !== groupData.id) // Remove the group
              : [...ticket.groups, groupData], // Add the group
          };
        }

        // Return unchanged tickets
        return ticket;
      });
    });
  };

  const handleTicketSetInput = (field, value) => {
    setTicketSet({
      ...ticketSet,
      [field]: { saved: value },
    });
  };

  const handleIconChange = (icon, ticketSet) => {
    if (!icon || typeof icon === "string") {
      // Handle existing Base64 string or null values directly
      setTicketSet({
        ...ticketSet,
        icon: {
          ...ticketSet.icon,
          saved: icon || ticketSet.icon?.saved || null,
        },
      });
      return;
    }

    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        if (!(file instanceof Blob)) {
          reject(new TypeError("The provided file is not a Blob or File."));
          return;
        }
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };

    convertToBase64(icon)
      .then((base64Image) => {
        setTicketSet({
          ...ticketSet,
          icon: { saved: base64Image },
        });
      })
      .catch((error) => {
        console.error("Error converting icon to Base64:", error);
      });
  };

  const handleAddGroups = (newGroups) => {
    if (selectedTicketId === "ticketSet") {
      // Update the ticketSet's badges directly
      const updatedBadges = [
        ...(ticketSet.badges.saved ?? ticketSet.badges.value),
        ...newGroups.filter(
          (newGroup) =>
            !(ticketSet.badges.saved ?? ticketSet.badges.value).some(
              (existingBadge) => existingBadge.name === newGroup.name
            )
        ),
      ];

      // Update the ticketSet with the new badges
      setTicketSet({
        ...ticketSet,
        badges: { saved: updatedBadges },
      });
    } else {
      // Find the ticket to update
      const ticketToUpdate = ticketSet.tickets.find(
        (ticket) => ticket.id === selectedTicketId
      );

      if (ticketToUpdate) {
        // Combine existing groups with new groups, avoiding duplicates
        const updatedGroups = [
          ...(ticketToUpdate.limitedToGroups.saved ??
            ticketToUpdate.limitedToGroups.value),
          ...newGroups.filter(
            (newGroup) =>
              !(
                ticketToUpdate.limitedToGroups.saved ??
                ticketToUpdate.limitedToGroups.value
              ).some((existingGroup) => existingGroup.id === newGroup.id)
          ),
        ];

        // Update the ticket with the new groups
        const updatedTicket = {
          ...ticketToUpdate,
          limitedToGroups: { saved: updatedGroups },
        };

        // Replace the updated ticket in the ticket set
        const updatedTickets = ticketSet.tickets.map((ticket) =>
          ticket.id === selectedTicketId ? updatedTicket : ticket
        );

        // Update the ticket set state
        setTicketSet({
          ...ticketSet,
          tickets: updatedTickets,
        });
      }
    }
  };

  const handleRemoveGroup = (groupToRemove, targetId) => {
    if (targetId === "ticketSet") {
      // Remove the badge from the ticketSet badges array
      const updatedBadges = (
        ticketSet.badges.saved ?? ticketSet.badges.value
      ).filter((badge) => badge.id !== groupToRemove.id);

      // Update the ticketSet with the new badges
      setTicketSet({
        ...ticketSet,
        badges: { saved: updatedBadges },
      });
    } else {
      // Find the ticket to update
      const ticketToUpdate = ticketSet.tickets.find(
        (ticket) => ticket.id === targetId
      );

      if (ticketToUpdate) {
        // Remove the group from the ticket's limitedToGroups array
        const updatedGroups = (
          ticketToUpdate.limitedToGroups.saved ??
          ticketToUpdate.limitedToGroups.value
        ).filter((group) => group.id !== groupToRemove.id);

        // Update the ticket with the new groups
        const updatedTicket = {
          ...ticketToUpdate,
          limitedToGroups: { saved: updatedGroups },
        };

        // Replace the updated ticket in the ticket set
        const updatedTickets = ticketSet.tickets.map((ticket) =>
          ticket.id === targetId ? updatedTicket : ticket
        );

        // Update the ticket set state
        setTicketSet({
          ...ticketSet,
          tickets: updatedTickets,
        });
      }
    }
  };
  const handleRemoveCreditType = (typeToRemove, targetId) => {
    // Find the ticket to update
    const ticketToUpdate = ticketSet.tickets.find(
      (ticket) => ticket.id === targetId
    );

    if (ticketToUpdate) {
      // Remove the type from the ticket's acceptedCreditTypes array
      const updatedCreditTypes = (
        ticketToUpdate.acceptedCreditTypes.saved ??
        ticketToUpdate.acceptedCreditTypes.value
      ).filter((type) => type.creditTypeId !== typeToRemove.creditTypeId);

      // Update the ticket with the new groups
      const updatedTicket = {
        ...ticketToUpdate,
        acceptedCreditTypes: { saved: updatedCreditTypes },
      };

      // Replace the updated ticket in the ticket set
      const updatedTickets = ticketSet.tickets.map((ticket) =>
        ticket.id === targetId ? updatedTicket : ticket
      );

      // Update the ticket set state
      setTicketSet({
        ...ticketSet,
        tickets: updatedTickets,
      });
    }
  };
  const handleCheckBoxClick = (ticketId) => {
    const ticketToUpdate = ticketSet.tickets.find(
      (ticket) => ticket.id === ticketId
    );

    if (ticketToUpdate) {
      const updatedTicket = {
        ...ticketToUpdate,
        creditsRequired: {
          saved: !(
            ticketToUpdate.creditsRequired.saved ??
            ticketToUpdate.creditsRequired.value
          ),
        },
      };

      const updatedTickets = ticketSet.tickets.map((ticket) =>
        ticket.id === ticketId ? updatedTicket : ticket
      );

      setTicketSet({
        ...ticketSet,
        tickets: updatedTickets,
      });
    }
  };

  const handleSelectChange = (newValue, ticketId) => {
    const ticketToUpdate = ticketSet.tickets.find(
      (ticket) => ticket.id === ticketId
    );

    if (ticketToUpdate) {
      const updatedTicket = {
        ...ticketToUpdate,
        commonItemId: { saved: newValue },
      };

      const updatedTickets = ticketSet.tickets.map((ticket) =>
        ticket.id === ticketId ? updatedTicket : ticket
      );

      setTicketSet({
        ...ticketSet,
        tickets: updatedTickets,
      });
    }
  };
  const handleCreditChange = () => {
    const ticketToUpdate = ticketSet.tickets.find(
      (ticket) => ticket.id === selectedTicketId
    );

    if (ticketToUpdate) {
      const updatedTicket = {
        ...ticketToUpdate,
        acceptedCreditTypes: { saved: acceptedCreditArray },
      };

      const updatedTickets = ticketSet.tickets.map((ticket) =>
        ticket.id === selectedTicketId ? updatedTicket : ticket
      );

      setTicketSet({
        ...ticketSet,
        tickets: updatedTickets,
      });
    }
  };
  const handleInputChange = (value, field, ticketId) => {
    const ticketToUpdate = ticketSet.tickets.find(
      (ticket) => ticket.id === ticketId
    );

    if (ticketToUpdate) {
      const updatedTicket = {
        ...ticketToUpdate,
        [field]: { saved: value },
      };

      const updatedTickets = ticketSet.tickets.map((ticket) =>
        ticket.id === ticketId ? updatedTicket : ticket
      );

      setTicketSet({
        ...ticketSet,
        tickets: updatedTickets,
      });
    }
  };
  const handleDateTimeChange = (newValue, field) => {
    const [fieldToUpdate, sectionToUpdate] = field.split("-");

    const ticketToUpdate = ticketSet.tickets.find(
      (ticket) => ticket.id === selectedTicketId
    );

    if (!ticketToUpdate) return;

    // Handle time zone updates
    if (sectionToUpdate === "zone") {
      const updatedTicket = {
        ...ticketToUpdate,
        [fieldToUpdate]: { saved: newValue }, // Directly update with the selected time zone
      };

      const updatedTickets = ticketSet.tickets.map((ticket) =>
        ticket.id === selectedTicketId ? updatedTicket : ticket
      );

      setTicketSet({
        ...ticketSet,
        tickets: updatedTickets,
      });

      return;
    }

    const existingDateTime =
      ticketToUpdate[fieldToUpdate].saved ??
      ticketToUpdate[fieldToUpdate].value ??
      null;

    // Initialize updatedMoment based on the existing value or default to today at 12:00 AM
    let updatedMoment = existingDateTime
      ? moment(existingDateTime)
      : moment().startOf("day");

    // Determine if the input is a date or time and update the moment object
    if (sectionToUpdate === "date") {
      const [month, day, year] = newValue.split("/").map(Number);
      updatedMoment = updatedMoment
        .set("month", month - 1)
        .set("date", day)
        .set("year", year);

      // If the existing value doesn't have a time portion, default it to 12:00 AM
      if (
        !existingDateTime ||
        moment(existingDateTime).format("HH:mm") === "00:00"
      ) {
        updatedMoment = updatedMoment.startOf("day");
      }
    } else if (sectionToUpdate === "time") {
      const [hour, minute] = newValue.split(":").map(Number);
      updatedMoment = updatedMoment.set("hour", hour).set("minute", minute);
    }

    // Format the updated value as an ISO string
    const formattedValue = updatedMoment.toISOString();

    // Update the ticket with the new value
    const updatedTicket = {
      ...ticketToUpdate,
      [fieldToUpdate]: { saved: formattedValue },
    };

    // Update the tickets array
    const updatedTickets = ticketSet.tickets.map((ticket) =>
      ticket.id === selectedTicketId ? updatedTicket : ticket
    );

    // Update the ticketSet state
    setTicketSet({
      ...ticketSet,
      tickets: updatedTickets,
    });
  };

  const handleRemoveTicket = (ticketId) => {
    const newTicketSet = {
      ...ticketSet,
      tickets: ticketSet.tickets.filter((tkt) => tkt.id !== ticketId),
    };
    if (newTicketSet.tickets.length < 1) {
      removeTicketSet();
    } else {
      setTicketSet({
        ...ticketSet,
        tickets: ticketSet.tickets.filter((tkt) => tkt.id !== ticketId),
      });
    }
  };

  const dateArray = eventStartDate;
  const eventDate = dayjs(dateArray);

  const handleEditOpen = (ticket) => {
    setEditingTicket({
      id: ticket.id,
      name: ticket.name,
      price: ticket.price.toString(),
      startDate: ticket.startDate,
      endDate: ticket.endDate,
      startTime: ticket.startTime,
      endTime: ticket.endTime,
      timeZone: ticket.timeZone,
    });

    setTimeout(() => {
      setShowEditModal(true);
    }, 0);
  };

  const handleEditClose = () => {
    setShowEditModal(false);
    setEditingTicket({});
  };

  const handleSaveEdit = (newTicket) => {
    // let startDate = parseStartDate(newTicket);
    // let endDate = parseEndDate(newTicket);

    const updatedTickets = tickets.map((ticket) => {
      if (ticket.id === newTicket.id) {
        return {
          ...ticket,
          name: newTicket.name,
          price: parseInt(newTicket.price),
          startDate: newTicket.startDate,
          startTime: newTicket.startTime,
          endDate: newTicket.endDate,
          endTime: newTicket.endTime,
          timeZone: newTicket.timeZone,
          // startDate: `${startDate.format("MM/DD/YYYY")}`,
          // endDate: `${endDate.format("MM/DD/YYYY")}`,
          //  startTime: `${newTicket.purchaseBegin.time}`,
          //  endTime: `${newTicket.purchaseEnd.time}`,
        };
      }
      return ticket;
    });

    updateTickets(updatedTickets);
    handleEditClose();
  };

  const parseStartDate = (newTicket) => {
    //   // eslint-disable-next-line default-case
    // switch (newTicket.purchaseBegin.length) {
    //   case "Days":
    //     return eventDate.subtract(
    //       parseInt(newTicket.purchaseBegin.period),
    //       "day"
    //     );
    //   case "Weeks":
    //     return eventDate.subtract(
    //       parseInt(newTicket.purchaseBegin.period),
    //       "week"
    //     );
    //   case "Months":
    //     return eventDate.subtract(
    //       parseInt(newTicket.purchaseBegin.period),
    //       "month"
    //     );
    // }
  };

  const parseEndDate = (newTicket) => {
    //   // eslint-disable-next-line default-case
    //   switch (newTicket.purchaseEnd.length) {
    //     case "Days":
    //       return eventDate.subtract(
    //         parseInt(newTicket.purchaseEnd.period),
    //         "day"
    //       );
    //     case "Weeks":
    //       return eventDate.subtract(
    //         parseInt(newTicket.purchaseEnd.period),
    //         "week"
    //       );
    //     case "Months":
    //       return eventDate.subtract(
    //         parseInt(newTicket.purchaseEnd.period),
    //         "month"
    //       );
    //   }
  };

  useEffect(() => {
    switch (limit) {
      case "Limit tickets per customer (IBO)":
        setTicketsPerField("Max Tickets Per Customer");
        break;
      default:
        setTicketsPerField("Tickets Per User");
        break;
    }
  }, [limit]);

  return (
    <>
      {isMajorV1 ? (
        // Layout for when isMajorV1 is true
        <div className={styles.ticketGroupContainer}>
          <div className={styles.header}>
            <span className={styles.label}>Ticket Set</span>
          </div>
          <div className={styles.body}>
            <div className={styles.ticketTableHeaderV1}>
              <span className={styles.ticketItem}>Ticket ID</span>
              <span>Ticket Name</span>
              <span className={styles.ticketPrice}>Price</span>
            </div>

            <div className={styles.ticketTableBody}>
              {tickets &&
                tickets?.map((ticket, index) => (
                  <div className={styles.ticketTableRow} key={index}>
                    <TicketGroupItem
                      ticket={ticket}
                      onEdit={handleEditOpen}
                      isMajorV1={isMajorV1}
                    />
                  </div>
                ))}
              <div className={styles.ticketTableRow}></div>
            </div>
          </div>
        </div>
      ) : isV2 ? (
        <div className={styles.ticketGroupContainer}>
          <div className={styles.v2header}>
            <span className={styles.label}>
              {ticketSet.ticketSet?.saved ?? ticketSet.ticketSet?.value} Ticket
              Set
            </span>
            {(ticketSet.ticketSetType?.saved ??
              ticketSet.ticketSetType?.value) === "sub" && (
              <div className={styles.subRemoveBtnContainer}>
                <Button
                  className={styles.subRemoveBtn}
                  children={"Remove"}
                  onClick={() => removeTicketSet()}
                />
              </div>
            )}
          </div>

          <div className={styles.inputContainer}>
            <div className={styles.inputLabelContainer}>
              <div className={styles.inputLabel}>
                Title {ticketSet?.title?.required ? " *" : ""}
              </div>
            </div>
            <div className={styles.titleInput}>
              <Input
                label={"Title"}
                value={ticketSet?.title?.value || ticketSet?.title?.saved}
                placeholder={"Click to type"}
                onChange={(e) => handleTicketSetInput("title", e.target.value)}
              />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.inputLabelContainer}>
              <div className={styles.inputLabel}>
                Sub-Title {ticketSet?.subTitle?.required ? " *" : ""}
              </div>
            </div>
            <div className={styles.titleInput}>
              <Input
                label={"Sub-Title"}
                value={ticketSet?.subTitle?.value || ticketSet?.subTitle?.saved}
                placeholder={"Click to type"}
                onChange={(e) =>
                  handleTicketSetInput("subTitle", e.target.value)
                }
              />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.inputLabelContainer}>
              <div className={styles.inputLabel}>
                Icon {ticketSet?.icon?.required ? " *" : ""}
              </div>
            </div>
            <div>
              <ImagePicker
                changeImageItem={(icon) => handleIconChange(icon, ticketSet)}
                isTicket={true}
                imgSrc={ticketSet.icon?.value ? ticketSet.icon?.saved : ""}
              />
            </div>
          </div>
          {(ticketSet.ticketSetType?.saved ??
            ticketSet.ticketSetType?.value) === "main" && (
            <div>
              <div className={styles.bottomInputContainer}>
                <div className={styles.inputLabelContainer}>
                  <div className={styles.inputLabel}>Badges</div>
                </div>
                <div
                  className={styles.addBadgesButton}
                  onClick={() => {
                    setSelectedTicketId("ticketSet");
                    setIsGroupModalOpen(true);
                  }}
                >
                  <AddButton description={"Add Badges"} color="#2A3847" />
                </div>
                <div className={styles.badgesList}>
                  {(ticketSet.badges?.saved ?? ticketSet.badges?.value).map(
                    (group) => (
                      <div className={styles.speakerItem}>
                        {group?.name || "No Name For Group"}
                        <div
                          className={styles.removeSpeaker}
                          onClick={() => handleRemoveGroup(group, "ticketSet")}
                        >
                          &times;
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
          <div className={styles.body}>
            <div className={styles.ticketTableHeaderV2}>
              <span>
                Name {ticketSet?.tickets[0].name?.required ? " *" : ""}
              </span>
              <span className={styles.ticketPrice}>
                Price {ticketSet?.tickets[0].price?.required ? " *" : ""}
              </span>
              <span className={styles.ticketPrice}>Cancel Fee</span>
              <span>
                ID {ticketSet?.tickets[0].commonItemId?.required ? " *" : ""}
              </span>
              <span className={styles.creditTypesHeader}>
                Accepted Credit Types
              </span>
              <span className={styles.creditsRequiredHeader}>
                Credits Required
              </span>
              <span>Groups</span>
              <span className={styles.creditsRequiredHeader}>Dates</span>
              <span />
            </div>
            <div className={styles.ticketTableBody}>
              {ticketSet.tickets && ticketsWithGroups.length > 0 ? (
                ticketSet?.tickets.map((ticket) => {
                  const ticketGroupData = ticketsWithGroups.find(
                    (twg) => twg.ticketId === ticket.id
                  );
                  return (
                    <>
                      <TicketGroupItem
                        key={`${ticketSet.id}-${ticket.id}`}
                        ticket={ticket}
                        onEdit={handleEditOpen}
                        isMajorV1={false}
                        isV2={true}
                        onAddGroup={(ticketId) => {
                          setSelectedTicketId(ticketId);
                          setIsGroupModalOpen(true);
                        }}
                        selectedGroups={
                          ticketGroupData ? ticketGroupData.groups : []
                        }
                        onRemoveGroup={(group, ticketId) =>
                          handleRemoveGroup(group, ticketId)
                        }
                        onRemoveCreditType={(type, ticketId) =>
                          handleRemoveCreditType(type, ticketId)
                        }
                        onCheckbox={(ticketId) => handleCheckBoxClick(ticketId)}
                        onSelect={(newValue, ticketId) =>
                          handleSelectChange(newValue, ticketId)
                        }
                        onCreditChange={(ticketId) => {
                          setSelectedTicketId(ticketId);
                          setAcceptedCreditArray(
                            ticketSet.tickets.find((tkt) => tkt.id === ticketId)
                              ?.acceptedCreditTypes?.saved ??
                              ticketSet.tickets.find(
                                (tkt) => tkt.id === ticketId
                              )?.acceptedCreditTypes?.value
                          );
                          setIsAcceptedCreditModalOpen(true);
                        }}
                        onEditDates={(ticketId) => {
                          setSelectedTicketId(ticketId);
                          setIsPurchaseDatesModalOpen(true);
                        }}
                        isSub={
                          (ticketSet.ticketSetType?.saved ??
                            ticketSet.ticketSetType?.value) === "sub"
                        }
                        onInputChange={(value, field) =>
                          handleInputChange(value, field, ticket.id)
                        }
                        onRemoveTicket={(ticketId) =>
                          handleRemoveTicket(ticketId)
                        }
                      />
                    </>
                  );
                })
              ) : (
                <div>
                  <Loader />
                </div>
              )}
              {(ticketSet.ticketSetType?.saved ??
                ticketSet.ticketSetType?.value) === "sub" && (
                <div className={styles.addBtn} onClick={() => onAddTicket()}>
                  <AddButton description={"Add New Ticket"} color="#9AB5BB" />
                </div>
              )}
              <div className={styles.ticketTableRow}></div>
            </div>
          </div>
        </div>
      ) : (
        // Layout for when isMajorV1 is false
        <div className={styles.ticketGroupContainer}>
          <div className={styles.header}>
            <span className={styles.label}>Ticket Set</span>
            <span className={styles.name}>{name}</span>
            <span className={styles.ticketsAvailable}>Tickets Available</span>
            <span className={styles.ticketsAvailInput}>
              <span className={styles.numberSign}>#</span>
              <Input
                value={available}
                onChange={(e) => setAvailable(parseInt(e.target.value))}
                onFocus={(e) => e.target.select()}
              />
            </span>
          </div>
          <div className={styles.body}>
            {showEditPurchaseLimits ? (
              <div className={styles.purchaseEditContainer}>
                <span className={styles.label}>Purchase Limits</span>
                <span className={styles.purchaseLimitsSelect}>
                  <Select
                    options={[
                      "Limit tickets per each user",
                      "Limit tickets per customer (IBO)",
                    ]}
                    placeholder={limit}
                    onChange={(value) => setLimit(value)}
                  />
                </span>
                <span className={styles.ticketsPerUser}>{ticketsPerField}</span>
                <span className={styles.ticketsPerInput}>
                  <Input
                    value={ticketsPer}
                    onChange={(e) => setTicketsPer(e.target.value)}
                    placeholder="#"
                  />
                </span>

                <Button
                  size="small"
                  onClick={() => setShowEditPurchaseLimits(false)}
                  className={styles.editButton}
                >
                  Save
                </Button>
              </div>
            ) : (
              <div className={styles.purchaseEditContainer}>
                <span className={styles.label}>Purchase Limits</span>
                <span>{limit}</span>
                <span className={styles.ticketsPerUser}>{ticketsPerField}</span>
                <span className={styles.ticketsPerInput}>{ticketsPer}</span>

                <Button
                  size="small"
                  onClick={() => setShowEditPurchaseLimits(true)}
                  className={styles.editButton}
                >
                  Edit
                </Button>
              </div>
            )}
            <div className={styles.ticketTableHeader}>
              <span className={styles.ticketItem}>Ticket ID</span>
              <span>Ticket Name</span>
              <span className={styles.ticketPrice}>Price</span>
              <span>Purchase Begin</span>
              <span>Purchase End</span>
              <span>Time Zone</span>
              <span></span>
            </div>
            {editingTicket && (
              <div>
                <EditTicketModal
                  isOpen={showEditModal}
                  onCollapse={handleEditClose}
                  ticketInfo={editingTicket}
                  onClick={handleSaveEdit}
                />
              </div>
            )}

            <div className={styles.ticketTableBody}>
              {tickets?.map((ticket, index) => (
                <div className={styles.ticketTableRow} key={index}>
                  <TicketGroupItem
                    ticket={ticket}
                    onEdit={handleEditOpen}
                    isMajorV1={isMajorV1}
                    isV2={isV2}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* {isV2 && (
        <div className={styles.ticketGroupContainer}>
          <div className={styles.header}>
            <span className={styles.labelContainer}>
              <div className={styles.labelName}>
                Homesite Ticket Set: {homesiteSelected}
              </div>
              <div className={styles.addButton}>
                <Button
                  children={homesiteSelected ? "EDIT" : "ADD"}
                  onClick={() => setIsHomesiteModalOpen(true)}
                />
              </div>
            </span>
          </div>
          <div className={styles.body}>
            <div className={styles.ticketTableHeaderV1}>
              <span className={styles.ticketItem}>Ticket ID</span>
              <span>Ticket Name</span>
              <span className={styles.ticketPrice}>Price</span>
            </div>

            <div className={styles.ticketTableBody}>
              {tickets &&
                tickets?.map((ticket, index) => (
                  <div className={styles.ticketTableRow} key={index}>
                    <TicketGroupItem
                      ticket={ticket}
                      onEdit={handleEditOpen}
                      isMajorV1={isMajorV1}
                    />
                  </div>
                ))}
              <div className={styles.ticketTableRow}></div>
            </div>
          </div>
        </div>
      )} */}

      <Modal
        title="Add Ticket"
        isOpen={showAddTicketModal}
        onClose={() => {
          setTicketName("");
          setPrice("");
          setID("");
          setShowAddTicketModal(false);
        }}
        children={
          <div className={styles.ticketModal}>
            <div className={styles.ticketDescription}>
              Please write a name, price, and ID, for the new Ticket Set
            </div>
            <div className={styles.ticketInputItem}>
              <Input
                value={ticketName}
                onChange={(e) => setTicketName(e.target.value)}
                placeholder="Ticket Name"
              />
            </div>
            <div className={styles.ticketInputItem}>
              <Input
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder="Ticket Price"
              />
            </div>
            <div className={styles.ticketInputItem}>
              <Select
                options={[
                  "FE_ADM",
                  "FE_COMP",
                  "FE_FPL",
                  "FE_STD",
                  "FE_STDPL",
                  "FE_VR_LS",
                  "FE_VR_PR",
                  "FR_ADM",
                  "FR_COMP",
                  "FR_FPL",
                  "FR_STD",
                  "FR_STDPL",
                  "FR_VR_LS",
                  "FR_VR_PR",
                  "LF_ADM",
                  "LF_USD",
                  "LF_VR_LS",
                  "LF_VR_PR",
                  "LS_ADM",
                  "LS_COMP",
                  "LS_FPL",
                  "LS_STD",
                  "LS_STDPL",
                  "LS_VR_LS",
                  "LS_VR_PR",
                ]}
                placeholder={id}
                onChange={(newValue) => setID(newValue)}
                removeSelectedOption={false}
              />
            </div>
            <div className={styles.addButtonContainer}>
              <Button
                className={styles.addButton}
                children={"Create"}
                onClick={() => {
                  onAddTicket(ticketName, price, id);
                  setTicketName("");
                  setPrice("");
                  setID("");
                  setShowAddTicketModal(false);
                }}
              />
            </div>
          </div>
        }
      />

      <AddGroupModal
        isOpen={isGroupModalOpen}
        onCollapse={() => setIsGroupModalOpen(false)}
        onClick={(newGroups) => handleAddGroups(newGroups)}
        // groups={[...groups, ...homesitesGroups]}
        // groups={[...groups]}
        groups={homesitesGroups ? [...groups, ...homesitesGroups] : [...groups]}
        groupsLoading={isGroupsLoading}
      />

      <Modal
        title="Accepted Credit Types"
        isOpen={isAcceptedCreditModalOpen}
        onClose={() => setIsAcceptedCreditModalOpen(false)}
        children={
          <div className={styles.modal}>
            <div className={styles.optionsListContainer}>
              <div className={styles.optionsListHeader}>
                <div className={styles.optionsHeader} />
                <div className={styles.optionsHeader}>ID</div>
                <div className={styles.optionsHeader}>Credit Type</div>
              </div>
              <div className={styles.optionsListBody}>
                {acceptedCreditOptions.map((option, index) => (
                  <div
                    className={styles.optionItemContainer}
                    key={option.creditTypeId || index}
                  >
                    <div className={styles.optionItem}>
                      <Checkbox
                        className={styles.optionCheckbox}
                        onChange={() => {
                          const isOptionSelected = acceptedCreditArray.some(
                            (credit) =>
                              credit.creditTypeId === option.creditTypeId
                          );
                          if (isOptionSelected) {
                            setAcceptedCreditArray(
                              acceptedCreditArray.filter(
                                (credit) =>
                                  credit.creditTypeId !== option.creditTypeId
                              )
                            );
                          } else {
                            setAcceptedCreditArray([
                              ...acceptedCreditArray,
                              option,
                            ]);
                          }
                        }}
                        checked={acceptedCreditArray.some(
                          (credit) =>
                            credit.creditTypeId === option.creditTypeId
                        )}
                      />
                    </div>
                    <div className={styles.optionItem}>
                      {option.creditTypeId}
                    </div>
                    <div className={styles.optionItem}>{option.name}</div>
                  </div>
                ))}
              </div>
            </div>
            <Button
              className={styles.updateButton}
              children={"Update"}
              onClick={() => {
                handleCreditChange();
                setIsAcceptedCreditModalOpen(false);
              }}
            />
          </div>
        }
      />

      <Modal
        title="Dates"
        isOpen={isPurchaseDatesModalOpen}
        onClose={() => setIsPurchaseDatesModalOpen(false)}
        children={
          <div className={styles.datesModal}>
            <div className={styles.dateMessage}>
              Ticket dates will be set to the dates in 'Publishing Information'
              section unless changed here
            </div>
            <div className={styles.dateSelectors}>
              <div className={styles.purchaseDateSelectors}>
                <div className={styles.datesLabel}>
                  <label>Purchase</label>
                </div>
                <div className={styles.purchaseDates}>
                  <div className={styles.labelAndPickerContainer}>
                    <div className={styles.subLabelContainer}>
                      <label>Starts On:</label>
                    </div>
                    <div className={styles.datePickerContainer}>
                      <DatePicker
                        className={styles.date}
                        dateString={(() => {
                          const selectedTicket = ticketSet.tickets.find(
                            (ticket) => ticket.id === selectedTicketId
                          );
                          const date =
                            selectedTicket?.purchaseStartDate?.saved ??
                            selectedTicket?.purchaseStartDate?.value;

                          return date
                            ? moment(date).format("MM/DD/YYYY")
                            : null;
                        })()}
                        setDate={(newDate) =>
                          handleDateTimeChange(
                            newDate,
                            "purchaseStartDate-date"
                          )
                        }
                      />
                      <div className={styles.timePickerContainer}>
                        <TimePicker
                          className={styles.date}
                          onChange={(newTime) =>
                            handleDateTimeChange(
                              newTime,
                              "purchaseStartDate-time"
                            )
                          }
                          timeString={(() => {
                            const selectedTicket = ticketSet.tickets.find(
                              (ticket) => ticket.id === selectedTicketId
                            );
                            const date =
                              selectedTicket?.purchaseStartDate?.saved ??
                              selectedTicket?.purchaseStartDate?.value;

                            return date ? moment(date).format("HH:mm") : null;
                          })()}
                        />
                      </div>
                    </div>
                    <div className={styles.timezone}>
                      <Select
                        options={timeZones}
                        value={(() => {
                          const selectedTicket = ticketSet.tickets.find(
                            (ticket) => ticket.id === selectedTicketId
                          );
                          return (
                            selectedTicket?.purchaseStartDateTimeZone?.saved ??
                            selectedTicket?.purchaseStartDateTimeZone?.value
                          );
                        })()}
                        placeholder={(() => {
                          const selectedTicket = ticketSet.tickets.find(
                            (ticket) => ticket.id === selectedTicketId
                          );
                          return (
                            selectedTicket?.purchaseStartDateTimeZone?.saved ??
                            selectedTicket?.purchaseStartDateTimeZone?.value ??
                            "Select Time Zone"
                          );
                        })()}
                        onChange={(newTimeZone) =>
                          handleDateTimeChange(
                            newTimeZone,
                            "purchaseStartDateTimeZone-zone"
                          )
                        }
                        disable={false}
                        className={styles.timezone}
                      />
                    </div>
                  </div>
                  <div className={styles.labelAndPickerContainer}>
                    <div className={styles.subLabelContainer}>
                      <label>Ends On:</label>
                    </div>
                    <div className={styles.datePickerContainer}>
                      <DatePicker
                        dateString={(() => {
                          const selectedTicket = ticketSet.tickets.find(
                            (ticket) => ticket.id === selectedTicketId
                          );
                          const date =
                            selectedTicket?.purchaseEndDate?.saved ??
                            selectedTicket?.purchaseEndDate?.value;

                          return date
                            ? moment(date).format("MM/DD/YYYY")
                            : null;
                        })()}
                        setDate={(newDate) =>
                          handleDateTimeChange(newDate, "purchaseEndDate-date")
                        }
                      />
                      <div className={styles.timePickerContainer}>
                        <TimePicker
                          className={styles.date}
                          onChange={(newTime) =>
                            handleDateTimeChange(
                              newTime,
                              "purchaseEndDate-time"
                            )
                          }
                          timeString={(() => {
                            const selectedTicket = ticketSet.tickets.find(
                              (ticket) => ticket.id === selectedTicketId
                            );
                            const date =
                              selectedTicket?.purchaseEndDate?.saved ??
                              selectedTicket?.purchaseEndDate?.value;

                            return date ? moment(date).format("HH:mm") : null;
                          })()}
                        />
                      </div>
                    </div>
                    <div className={styles.timezoneSpacer}></div>
                  </div>
                </div>
              </div>

              <div className={styles.otherDateSelectors}>
                <div className={styles.otherDatesLabel}>
                  <label>Start Fee</label>
                </div>
                <div className={styles.labelAndPickerContainer2}>
                  <div className={styles.datePickerContainer}>
                    <DatePicker
                      className={styles.date}
                      dateString={(() => {
                        const selectedTicket = ticketSet.tickets.find(
                          (ticket) => ticket.id === selectedTicketId
                        );
                        const date =
                          selectedTicket?.startFeeDate?.saved ??
                          selectedTicket?.startFeeDate?.value;

                        return date ? moment(date).format("MM/DD/YYYY") : null;
                      })()}
                      setDate={(newDate) =>
                        handleDateTimeChange(newDate, "startFeeDate-date")
                      }
                    />
                    <div className={styles.timePickerContainer}>
                      <TimePicker
                        className={styles.date}
                        onChange={(newTime) =>
                          handleDateTimeChange(newTime, "startFeeDate-time")
                        }
                        timeString={(() => {
                          const selectedTicket = ticketSet.tickets.find(
                            (ticket) => ticket.id === selectedTicketId
                          );
                          const date =
                            selectedTicket?.startFeeDate?.saved ??
                            selectedTicket?.startFeeDate?.value;

                          return date ? moment(date).format("HH:mm") : null;
                        })()}
                      />
                    </div>
                  </div>
                  <div className={styles.timezone}>
                    <Select
                      options={timeZones}
                      value={(() => {
                        const selectedTicket = ticketSet.tickets.find(
                          (ticket) => ticket.id === selectedTicketId
                        );
                        return (
                          selectedTicket?.startFeeTimeZone?.saved ??
                          selectedTicket?.startFeeTimeZone?.value
                        );
                      })()}
                      placeholder={(() => {
                        const selectedTicket = ticketSet.tickets.find(
                          (ticket) => ticket.id === selectedTicketId
                        );
                        return (
                          selectedTicket?.startFeeTimeZone?.saved ??
                          selectedTicket?.startFeeTimeZone?.value ??
                          "Select Time Zone"
                        );
                      })()}
                      onChange={(newTimeZone) =>
                        handleDateTimeChange(
                          newTimeZone,
                          "startFeeTimeZone-zone"
                        )
                      }
                      disable={false}
                      className={styles.timezone}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.otherDateSelectors}>
                <div className={styles.otherDatesLabel}>
                  <label>Changeable Until</label>
                </div>
                <div className={styles.labelAndPickerContainer2}>
                  <div className={styles.datePickerContainer}>
                    <DatePicker
                      className={styles.date}
                      dateString={(() => {
                        const selectedTicket = ticketSet.tickets.find(
                          (ticket) => ticket.id === selectedTicketId
                        );
                        const date =
                          selectedTicket?.changeableUntilDate?.saved ??
                          selectedTicket?.changeableUntilDate?.value;

                        return date ? moment(date).format("MM/DD/YYYY") : null;
                      })()}
                      setDate={(newDate) =>
                        handleDateTimeChange(
                          newDate,
                          "changeableUntilDate-date"
                        )
                      }
                    />
                    <div className={styles.timePickerContainer}>
                      <TimePicker
                        className={styles.date}
                        onChange={(newTime) =>
                          handleDateTimeChange(
                            newTime,
                            "changeableUntilDate-time"
                          )
                        }
                        timeString={(() => {
                          const selectedTicket = ticketSet.tickets.find(
                            (ticket) => ticket.id === selectedTicketId
                          );
                          const date =
                            selectedTicket?.changeableUntilDate?.saved ??
                            selectedTicket?.changeableUntilDate?.value;

                          return date ? moment(date).format("HH:mm") : null;
                        })()}
                      />
                    </div>
                  </div>
                  <div className={styles.timezone}>
                    <Select
                      options={timeZones}
                      value={(() => {
                        const selectedTicket = ticketSet.tickets.find(
                          (ticket) => ticket.id === selectedTicketId
                        );
                        return (
                          selectedTicket?.changeableUntilTimeZone?.saved ??
                          selectedTicket?.changeableUntilTimeZone?.value
                        );
                      })()}
                      placeholder={(() => {
                        const selectedTicket = ticketSet.tickets.find(
                          (ticket) => ticket.id === selectedTicketId
                        );
                        return (
                          selectedTicket?.changeableUntilTimeZone?.saved ??
                          selectedTicket?.changeableUntilTimeZone?.value ??
                          "Select Time Zone"
                        );
                      })()}
                      onChange={(newTimeZone) =>
                        handleDateTimeChange(
                          newTimeZone,
                          "changeableUntilTimeZone-zone"
                        )
                      }
                      disable={false}
                      className={styles.timezone}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
}

TicketGroup.defaultProps = {
  ticketSet: { tickets: [] },
};

TicketGroup.propTypes = {
  /**Displays the name in the header  */
  name: PropTypes.string,
  /**Number of tickets available. A cumulative number for all the tickets within the group */
  available: PropTypes.number,
  /**The user can choose between limiting tickets per user or per customer.  */
  limit: PropTypes.string,
  /**Number of tickets allowed for purchase. This will change between Tickets per user (for limit tickets per each user) and Max tickets per customer (for limit tickets per customer (IBO)).  */
  ticketsPer: PropTypes.string,
  /**Array of ticket objects*/
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.number,
      timeZone: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
    })
  ),
  /**Function to set the number of tickets that are available */
  setAvailable: PropTypes.func,
  /**Function to set the limit option */
  setLimit: PropTypes.func,
  /**Function to set the number of tickets allowed per purchase */
  setTicketsPer: PropTypes.func,
  /**Function that updates the ticket information */
  updateTickets: PropTypes.func,
  /**The date the event starts on */
  eventStartDate: PropTypes.string,
  isMajorV1: PropTypes.bool,
};
