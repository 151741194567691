import PropTypes from "prop-types";
import styles from "./DeleteModal.module.css";
import classNames from "classnames";
import ReactModal from "react-modal";
import { Modal } from "shared/Modal";
import Loader from "shared/Loader";

/**
 * Modal that opens when the user clicks the delete option in the event cards select box. Also used when deleting a venue.
 */
export function DeleteModal({
  header,
  description,
  isOpen,
  onCollapse,
  onClick,
  btnName,
  children,
  className,
  isLoading,
}) {
  return (
    <div className={styles.container}>
      <Modal
        // contentLabel={title}
        title={header}
        isOpen={isOpen}
        onClose={onCollapse}
        className={`${styles.modal} ${className}`}
        children={
          <>
            {isLoading ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                <div>{description}</div>

                <div>
                  <button
                    className={classNames(styles.button, styles.deleteButton)}
                    onClick={onClick}
                  >
                    {btnName}
                  </button>
                  <button className={styles.button} onClick={onCollapse}>
                    CANCEL
                  </button>
                </div>
              </>
            )}
          </>
        }
      />
    </div>
  );
}

DeleteModal.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  isOpen: PropTypes.bool,
  onCollapse: PropTypes.func,
  onClick: PropTypes.func,
};
