import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from "shared/Card";
import TicketGroup from "Events/Components/TicketGroup";
import AddButton from "Events/Components/AddButton";
import { Modal } from "shared/Modal";
import Input from "shared/@forms/Input";
import Button from "shared/Button";
import styles from "./TicketingV2Form.module.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

/**Area to fill out ticketing information  */
export function TicketingV2Form({
  ticketSets,
  setTicketSets,
  homesitesGroups,
  clearSessionsTicketSet,
}) {
  const [showAddTicketSetModal, setShowAddTicketSetModal] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [name, setName] = useState("");
  const handleAddTicketSet = () => {
    setTicketSets([
      ...ticketSets,
      {
        ticketSet: { saved: name },
        ticketSetType: { saved: "sub" },
        badges: { saved: [] },
        sort: { saved: ticketSets.length },
        tickets: [
          {
            commonItemId: { saved: null },
            name: { saved: name },
            price: { saved: 0 },
            creditsRequired: { saved: false },
            limitedToGroups: { saved: [] },
            acceptedCreditTypes: { saved: [] },
            purchaseStartDate: { saved: null },
            purchaseEndDate: { saved: null },
            virtualCommonItemId: { saved: null },
          },
        ],
      },
    ]);
    setShowAddTicketSetModal(false);
  };
  const handleSetTicketSet = (updatedTicketSet) => {
    const updatedTicketSets = ticketSets.map((ticketSet) =>
      ticketSet.id === updatedTicketSet.id ? updatedTicketSet : ticketSet
    );
    setIsRemoving(false);
    if (!isRemoving) {
      setTicketSets(updatedTicketSets);
    } else {
      setIsRemoving(false);
    }
  };
  const handleRemoveTicketSet = (setToRemove) => {
    setIsRemoving(true);
    const updatedTicketSets = ticketSets.filter(
      (ticketSet) => ticketSet.id !== setToRemove.id
    );
    setTicketSets(updatedTicketSets);
    clearSessionsTicketSet(setToRemove.ticketSet);
  };
  const handleAddTicket = (ticketSetId, name, price, id) => {
    const updatedTicketSets = ticketSets.map((tktSet) =>
      tktSet.id === ticketSetId
        ? {
            ...tktSet,
            tickets: [
              ...tktSet.tickets,
              {
                commonItemId: null,
                name: null,
                price: null,
                creditsRequired: false,
                limitedToGroups: [],
                acceptedCreditTypes: [],
                purchaseStartDate: null,
                purchaseEndDate: null,
                virtualCommonItemId: null,
              },
            ],
          }
        : tktSet
    );
    setTicketSets(updatedTicketSets);
  };

  const handleOrderChange = (sourceIndex, destinationIndex) => {
    if (sourceIndex === destinationIndex || destinationIndex == null) return;

    const ticketSetsClone = [...ticketSets];

    const filteredSets = ticketSetsClone.filter(
      (ticketSet) => ticketSet.ticketSetType.saved === "sub"
    );

    const draggedItem = filteredSets[sourceIndex];
    filteredSets.splice(sourceIndex, 1);
    filteredSets.splice(destinationIndex, 0, draggedItem);

    const updatedTicketSets = ticketSetsClone.map((ticketSet) => {
      const indexInFiltered = filteredSets.findIndex(
        (fSet) => fSet.id === ticketSet.id
      );

      return indexInFiltered !== -1
        ? {
            ...ticketSet,
            sort: {
              ...ticketSet.sort,
              saved: indexInFiltered + 1,
            },
          }
        : ticketSet;
    });

    setTicketSets(updatedTicketSets);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    handleOrderChange(source.index, destination.index);
  };

  return (
    <Card
      children={
        <section>
          <div className={styles.bold}>TICKETING</div>
          <hr className={styles.hr} />
          <div className={styles.ticketGroup}>
            {ticketSets
              .filter(
                (ticketSet) =>
                  (ticketSet.ticketSetType.saved ??
                    ticketSet.ticketSetType.value) === "main"
              )
              .map((ticketSet, index) => (
                <TicketGroup
                  key={ticketSet.id}
                  ticketSet={ticketSet}
                  setTicketSet={(newData) => handleSetTicketSet(newData)}
                  removeTicketSet={() => handleRemoveTicketSet(ticketSet)}
                  isMajorV1={false}
                  isV2={true}
                  onAddTicket={() => handleAddTicket(ticketSet.id)}
                  homesitesGroups={homesitesGroups}
                />
              ))}
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="ticketSets">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={styles.ticketGroup}
                >
                  {ticketSets
                    .filter(
                      (ticketSet) =>
                        (ticketSet.ticketSetType.saved ??
                          ticketSet.ticketSetType.value) === "sub"
                    )
                    .sort((a, b) => {
                      const sortA = a.sort.saved ?? a.sort.value;
                      const sortB = b.sort.saved ?? b.sort.value;

                      return sortA - sortB;
                    })
                    .map((ticketSet, index) => (
                      <Draggable
                        key={ticketSet.id}
                        draggableId={ticketSet.id?.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <TicketGroup
                              key={index}
                              ticketSet={ticketSet}
                              setTicketSet={(newData) =>
                                handleSetTicketSet(newData)
                              }
                              removeTicketSet={() =>
                                handleRemoveTicketSet(ticketSet)
                              }
                              isMajorV1={false}
                              isV2={true}
                              onAddTicket={() => handleAddTicket(ticketSet.id)}
                              homesitesGroups={homesitesGroups}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div
            className={styles.addBtn}
            onClick={() => setShowAddTicketSetModal(true)}
          >
            <AddButton description={"Add New Ticket Set"} color="#9AB5BB" />
          </div>

          <Modal
            title="Add Ticket Set"
            isOpen={showAddTicketSetModal}
            onClose={() => {
              setName("");
              setShowAddTicketSetModal(false);
            }}
            children={
              <div className={styles.modal}>
                <div className={styles.description}>
                  Please write a name for the new Ticket Set
                </div>
                <div className={styles.inputItem}>
                  <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Ticket Set Name"
                  />
                </div>
                <div className={styles.addButtonContainer}>
                  <Button
                    className={styles.addButton}
                    children={"Create"}
                    onClick={() => handleAddTicketSet()}
                  />
                </div>
              </div>
            }
          />
        </section>
      }
    />
  );
}

TicketingV2Form.propTypes = {
  /**Array of ticket group objects (name-String, available-Number, limit-String, ticketsPer-string, tickets-Array)  */
  ticketSets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      available: PropTypes.number,
      setAvailable: PropTypes.func,
      limit: PropTypes.string,
      setLimit: PropTypes.shape({}),
      ticketsPer: PropTypes.string,
      setTicketsPer: PropTypes.shape({}),
      tickets: PropTypes.array,
      updateTickets: PropTypes.shape({}),
      eventStartDate: PropTypes.string,
    })
  ),
  /**Function that changes the ticket groups */
  setTicketGroups: PropTypes.func,
};
