import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Card from "shared/Card";
import { Select } from "shared/Select/Select";
import VenueInfoCard from "Events/Components/VenueInfoCard";
import Checkbox from "shared/@forms/Checkbox";
import Loader from "shared/Loader";
import styles from "./VenueV2Form.module.css";
import {
  useManageVenuesPage,
  useVenueOptionsGet,
} from "Events/Pages/ManageVenuesPage/useManageVenuesPage";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";
import { capitalizeCityName } from "shared/@utils/capitalizeCityName";
import VenueCard from "Events/Components/VenueCard";
import Button from "shared/Button";

//Area to fill out information about the venue with a map displaying the venue location
export function VenueV2Form({ venueInfo, onVenueChange, pageMode }) {
  const { data: statesData } = useCurrentRoute();

  const { v2Data, isV2Loading } = useManageVenuesPage();

  const [venueNames, setVenueNames] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState("");
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [noVenue, setNoVenue] = useState(false);
  const [userHasSelected, setUserHasSelected] = useState(false);
  const [isLoadingVenue, setIsLoadingVenue] = useState(false);
  const [stateNameToIdMap, setStateNameToIdMap] = useState({});

  const prevSelectedStateRef = useRef();

  useEffect(() => {
    const hasSavedVenueData = venueInfo.venue?.company?.saved?.netSuiteId;
    const isSavedNoVenue = venueInfo.venue?.company?.saved?.noVenue;
    const hasValueVenueData = venueInfo.venue?.company?.value?.netSuiteId;
    const isValueNoVenue = venueInfo.venue?.company?.value?.noVenue;

    const noVenue =
      !hasSavedVenueData &&
      (isSavedNoVenue || (!hasValueVenueData && isValueNoVenue));

    setNoVenue(noVenue && !isLoadingVenue);
    // setNoVenue(
    //   !hasSavedVenueData && (isSavedNoVenue ? isSavedNoVenue : (!hasValueVenueData && (isValueNoVenue ? isValueNoVenue : isValueNoVenue)))
    // );
  }, [venueInfo]);

  useEffect(() => {
    if (v2Data && statesData) {
      // Create mappings for state names and IDs
      const newStateIdToNameMap = statesData.reduce((acc, state) => {
        acc[state.stateId.toString()] = state.state;
        return acc;
      }, {});

      const newStateNameToIdMap = statesData.reduce((acc, state) => {
        acc[state.state] = state.stateId.toString();
        return acc;
      }, {});
      setStateNameToIdMap(newStateNameToIdMap);

      const newStateNameToAbbMap = statesData.reduce((acc, state) => {
        acc[state.abbreviation] = state.state.toString();
        return acc;
      }, {});

      let statesAndCities = {};

      // Loop through V2 venues to construct statesAndCities
      v2Data.forEach((venue) => {
        const stateName =
          newStateNameToAbbMap[venue.state.toString()] || "Unknown State";
        if (!statesAndCities[stateName]) {
          statesAndCities[stateName] = ["Select"];
        }

        const formattedCity = venue.city;

        if (!statesAndCities[stateName].includes(formattedCity)) {
          statesAndCities[stateName].push(formattedCity);
        }
      });

      // Sort cities and states
      Object.keys(statesAndCities).forEach((state) => {
        const cities = statesAndCities[state]
          .filter((city) => city !== "Select")
          .sort();
        statesAndCities[state] = ["Select", ...cities];
      });

      const stateOptions = ["Select", ...Object.keys(statesAndCities).sort()];
      setStates(stateOptions);

      let selectedState = "";

      selectedState =
        newStateIdToNameMap[
          venueInfo?.event?.state?.saved ||
            venueInfo?.event?.state?.value ||
            "Select"
        ];

      // setSelectedState(selectedState);
    }
  }, [v2Data, statesData]);

  useEffect(() => {
    if (selectedState && v2Data) {
      const newStateIdToNameMap = statesData.reduce((acc, state) => {
        acc[state.stateId.toString()] = state.state;
        return acc;
      }, {});

      const newStateNameToAbbMap = statesData.reduce((acc, state) => {
        acc[state.abbreviation] = state.state.toString();
        return acc;
      }, {});

      let statesAndCities = {};

      // Loop through V2 venues to construct statesAndCities
      v2Data.forEach((venue) => {
        const stateName = newStateNameToAbbMap[venue.state] || "Unknown State";

        if (!statesAndCities[stateName]) {
          statesAndCities[stateName] = [];
        }

        const formattedCity = capitalizeCityName(venue.city);

        if (!statesAndCities[stateName].includes(formattedCity)) {
          statesAndCities[stateName].push(formattedCity);
        }
      });

      // Sort and add "All" option
      Object.keys(statesAndCities).forEach((state) => {
        const cities = statesAndCities[state]
          .filter((city) => city !== "All")
          .sort();
        statesAndCities[state] = ["All", ...cities];
      });

      // Get city options for the selected state
      const cityOptions =
        selectedState &&
        selectedState !== "Select" &&
        statesAndCities[selectedState]
          ? statesAndCities[selectedState]
          : ["Select"];
      setCities(cityOptions);
    }
  }, [selectedState, v2Data]);

  useEffect(() => {
    const savedCompany = venueInfo.venue?.company?.saved?.venueName;
    const valueCompany = venueInfo.venue?.company?.value?.venueName;

    if (savedCompany === null) {
      //This makes it impossible for State to be initially set while company is null
      // setSelectedState("");
      // setSelectedCity("");
      setSelectedVenue(valueCompany);
    } else {
      setSelectedVenue(savedCompany || valueCompany || "");
    }
    if (
      savedCompany ||
      valueCompany ||
      venueInfo.venue?.company?.saved?.noVenue ||
      venueInfo.venue?.company?.saved?.noVenue
    ) {
      setIsLoadingVenue(false);
    }
  }, [venueInfo]);

  const specificRuleset = venueInfo?.event?.ruleset?.value;

  //Filter state & city
  useEffect(() => {
    //Filter venues by specificRuleset
    const filteredVenues = v2Data?.filter(
      (venue) =>
        venue.ruleset === specificRuleset &&
        (venue.venueId || venue.venueName || venue.roomId)
    );

    //Extract unique states
    const uniqueStateIds = [
      ...new Set(filteredVenues?.map((venue) => venue.stateId)),
    ];
    const stateOptions = uniqueStateIds
      ?.map(
        (stateId) =>
          statesData?.find((state) => state.stateId === stateId)?.state
      )
      ?.filter(Boolean)
      .sort((a, b) => a.localeCompare(b));

    // setStates([...stateOptions]);

    // Only reset city and venue if the selected state has changed
    if (
      selectedState !== prevSelectedStateRef.current &&
      selectedVenue === null
    ) {
      setSelectedCity("");
      setSelectedVenue("");
    }

    //Filter cities based on the selected state
    const stateId = statesData?.find(
      (state) => state.state === selectedState
    )?.stateId;
    let cities = filteredVenues
      ?.filter((venue) => venue.stateId === stateId)
      .map((venue) => venue.city.trim().toUpperCase())
      .map((city) => (city === "VANCOUVER WA" ? "VANCOUVER" : city))
      .map((city) =>
        city
          .toLowerCase()
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ")
      );

    const uniqueCities = Array.from(new Set(cities)).sort((a, b) =>
      a.localeCompare(b)
    );

    // setCities([...uniqueCities]);

    prevSelectedStateRef.current = selectedState;
  }, [v2Data, specificRuleset, statesData, selectedState]);

  useEffect(() => {
    // Define a method to extract venue and room names safely
    const getVenueAndRoom = (venueInfo) => {
      const company =
        venueInfo.venue?.venueName?.saved?.company ||
        venueInfo.venue?.venueName?.value?.company ||
        "";
      const room =
        venueInfo.venue?.room?.saved?.room ||
        venueInfo.venue?.room?.value?.room ||
        "";
      return room ? `${company} (${room})` : `${company}`;
    };

    if (
      !userHasSelected &&
      (venueInfo.venue?.venueName?.saved?.venueName ||
        venueInfo.venue?.venueName?.value?.venueName ||
        venueInfo.venue?.room?.saved?.room ||
        venueInfo.venue?.room?.value?.room)
    ) {
      const initialVenueAndRoom = getVenueAndRoom(venueInfo);
      setSelectedVenue(initialVenueAndRoom);
    }

    let roomList = [];
    if (selectedState && selectedCity) {
      const stateId = statesData.find(
        (state) => state.state.toLowerCase() === selectedState.toLowerCase()
      )?.stateId;

      const newStateIdToAbbMap = statesData.reduce((acc, state) => {
        acc[state.abbreviation] = state.stateId.toString();
        return acc;
      }, {});

      const filteredVenues = v2Data?.filter((venue) => {
        const matchesState = newStateIdToAbbMap[venue.state] == stateId;
        const matchesCity =
          selectedCity.toLowerCase() == "all" ||
          venue.city.trim().toLowerCase() == selectedCity.trim().toLowerCase();
        return matchesState && matchesCity;
      });

      // Populate roomList with venues that match the filter
      filteredVenues?.forEach((venue) => {
        // Add venue without room first
        const venueOnlyIdentifier = venue.venueName;
        if (!roomList.includes(venueOnlyIdentifier)) {
          roomList.push(venueOnlyIdentifier);
        }

        // Add each room of the venue if it has rooms
        if (venue.rooms.length > 0) {
          venue.rooms.forEach((room) => {
            const roomIdentifier = `${venue.venueName} (${room.name})`;
            if (!roomList.includes(roomIdentifier)) {
              roomList.push(roomIdentifier);
            }
          });
        }
      });

      // Check if selectedVenue is in the list; if not, set to "Select"
      if (
        !userHasSelected &&
        !filteredVenues?.find(
          (venue) => getVenueAndRoom({ venue }) === selectedVenue
        )
      ) {
        setSelectedVenue("Select");
      }
    }

    setVenueNames(roomList);
  }, [
    venueInfo,
    selectedState,
    selectedCity,
    v2Data,
    specificRuleset,
    statesData,
    userHasSelected,
  ]);

  function transformVenueData(data) {
    const currentRoomId = venueInfo.venue?.room?.saved?.roomId
      ? venueInfo.venue.room?.saved?.roomId
      : venueInfo.venue.room?.value?.roomId;
    const selectedRoom = data?.rooms?.find((r) => r.roomId == currentRoomId);

    const formattedVenue = {
      company: {
        saved: {
          netSuiteId: data.netSuiteId,
          venueName: data.venueName || "",
          street1: data.street1 || "",
          street2: data.street2 || "",
          city: data.city || "",
          stateAbbr: data.state || "",
          zip: data.zip || "",
          url: data.url || "N/A",
          phone: data.phone || "N/A",
          fax: data.fax || "N/A",
          email: data.email || "N/A",
        },
      },
      room: { saved: selectedRoom || {} },
    };

    return formattedVenue;
  }

  function isDifferent(obj1, obj2) {
    if (obj1 && obj2) {
      const keys1 = Object.keys(obj1);

      // console.log(obj1, obj2);
      for (const key of keys1) {
        if (!(key in obj2)) {
          continue;
        }

        let val1 = obj1[key];
        let val2 = obj2[key];

        if (key === "zip") {
          val1 = typeof val1 === "string" ? val1.slice(0, 5) : val1;
          val2 = typeof val2 === "string" ? val2.slice(0, 5) : val2;
        }

        const bothFalsy =
          (val1 === null || val1 === undefined || val1 === "") &&
          (val2 === null || val2 === undefined || val2 === "");

        if (!bothFalsy && val1 !== val2) {
          // console.log(val1, val2);
          return true;
        }
      }

      return false;
    } else {
      return false;
    }
  }

  const [isVenuesDifferent, setIsVenuesDifferent] = useState(false);

  useEffect(() => {
    if (
      v2Data &&
      venueInfo &&
      venueInfo.venue &&
      venueInfo.venue.company &&
      (venueInfo.venue.company?.saved || venueInfo.venue.company?.value)
    ) {
      const useSavedValues = !!venueInfo.venue.company?.saved?.netSuiteId;

      const companyData = useSavedValues
        ? venueInfo.venue.company.saved
        : venueInfo.venue.company.value;

      const roomData = useSavedValues
        ? venueInfo.venue.room.saved
        : venueInfo.venue.room.value;

      setIsVenuesDifferent(
        isDifferent(
          companyData,
          v2Data.find(
            (v) =>
              v.netSuiteId ==
              (venueInfo.venue.company?.saved?.netSuiteId ||
                venueInfo.venue.company?.value?.netSuiteId)
          )
        ) ||
          isDifferent(
            roomData,
            v2Data
              .find(
                (v) =>
                  v.netSuiteId ==
                  (venueInfo.venue.company?.saved?.netSuiteId ||
                    venueInfo.venue.company?.value?.netSuiteId)
              )
              ?.rooms?.find((r) => r.name == roomData.name) || {
              roomId: null,
              name: null,
              capacity: null,
            }
          )
      );
    }
  }, [venueInfo, v2Data]);

  const handleVenueChange = (selectedValue) => {
    setIsLoadingVenue(true);
    const [selectedCompany, selectedRoomWithParens] = selectedValue.split(" (");
    const selectedRoom = selectedRoomWithParens
      ? selectedRoomWithParens.slice(0, -1)
      : null;

    const newAbbToStateIdMap = statesData.reduce((acc, state) => {
      acc[state.abbreviation] = state.stateId.toString();
      return acc;
    }, {});

    const newAbbToStateNameMap = statesData.reduce((acc, state) => {
      acc[state.abbreviation] = state.state.toString();
      return acc;
    }, {});

    const venueData = v2Data?.find(
      (venue) => venue.venueName === selectedCompany.trim()
    );

    const payload = {
      venue: {
        company: {
          ...venueData,
          fax: venueData.fax || null,
          zip: venueData.zip || null,
          noVenue: null,
        },
        room: selectedRoom
          ? venueData?.rooms.find((room) => room.name === selectedRoom)
            ? {
                roomId: venueData.rooms.find(
                  (room) => room.name === selectedRoom
                ).roomId,
                room: selectedRoom,
                maxCapacity: venueData.rooms.find(
                  (room) => room.name === selectedRoom
                ).capacity,
              }
            : {
                roomId: null,
                name: null,
                capacity: null,
              }
          : {
              roomId: null,
              name: null,
              capacity: null,
            },
      },
    };

    // Function to recursively loop through and convert falsy values to null
    const sanitizePayload = (data) => {
      if (typeof data === "object" && data !== null) {
        for (const key in data) {
          if (data[key] && typeof data[key] === "object") {
            sanitizePayload(data[key]); // Recursive call for nested objects
          } else if (!data[key]) {
            data[key] = null; // Convert falsy value to null
          }
        }
      }
    };

    sanitizePayload(payload); // Sanitize the payload object

    setSelectedCity(venueData?.city || "");
    onVenueChange(payload);
    setSelectedVenue(selectedValue);
    setUserHasSelected(true);
    // setIsLoadingVenue(false);
  };

  const handleVenueEdit = (newVenueData, newRoomData) => {
    const payload = {
      venue: {
        company: newVenueData,
        room: newRoomData,
      },
    };

    onVenueChange(payload);
  };

  const handleUpdate = () => {
    const selectedVenue = v2Data.find(
      (v) =>
        v.netSuiteId ==
        (venueInfo.venue.company.saved.netSuiteId ||
          venueInfo.venue.company.value.netSuiteId)
    );

    const selectedVenueName = selectedVenue.venueName;

    const currentRoomId = venueInfo.venue.room.saved.roomId
      ? venueInfo.venue.room.saved.roomId
      : venueInfo.venue.room.value.roomId;

    const selectedVenueRoomName = selectedVenue.rooms.find(
      (r) => r.roomId == currentRoomId
    )?.name;

    const payload = `${selectedVenueName}${
      selectedVenueRoomName ? ` (${selectedVenueRoomName})` : ""
    }`;
    handleVenueChange(payload);
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
    setSelectedCity("");
    setSelectedVenue("");
  };

  const handleCityChange = (value) => {
    setSelectedCity(value == "All" ? "all" : value);
    setSelectedVenue("");
  };

  //Update no venue
  const handleNoVenue = async () => {
    setIsLoadingVenue(true);

    const newValue = !noVenue;
    setNoVenue(newValue);
    if (!newValue) {
      setIsLoadingVenue(false);
    }
    if (newValue) {
      // setIsLoadingVenue(true);
      // const hasSavedVenue =
      //   venueInfo.venue &&
      //   venueInfo.venue.company.saved &&
      //   (venueInfo.venue.company.saved.id ||
      //     venueInfo.venue.company.saved.venueName ||
      //     venueInfo.venue.company.saved.netSuiteId);

      // if (!hasSavedVenue) {
      //   setIsLoadingVenue(false);
      //   return;
      // }

      const resetVenue = {
        venue: {
          company: {
            city: null,
            country: null,
            email: null,
            fax: null,
            isVerified: null,
            latitude: null,
            longitude: null,
            netSuiteId: null,
            phone: null,
            state: null,
            stateId: null,
            street1: null,
            street2: null,
            url: null,
            venueId: null,
            venueName: null,
            zip: null,
            noVenue: true,
          },
          room: {
            roomId: null,
            name: null,
            capacity: null,
          },
        },
      };

      try {
        await onVenueChange(resetVenue);
        // setIsLoadingVenue(false);
      } catch (error) {
        console.error("Error resetting venue:", error);
        // setIsLoadingVenue(false);
      }
    } else {
      if (venueInfo.venue?.company?.value?.netSuiteId) {
        const resetVenue = {
          venue: {
            company: {
              ...venueInfo.venue?.company.saved,
              noVenue: null,
            },
            room: {
              ...venueInfo.venue?.room?.saved,
            },
          },
        };

        try {
          await onVenueChange(resetVenue);
        } catch (error) {
          console.error("Error resetting venue:", error);
        }
      }

      setSelectedState("");
      setSelectedCity("");
      setSelectedVenue("");
    }

    // setIsLoadingVenue(false);
  };

  const renderVenueCard = () => {
    const companyPermission = venueInfo?.venue?.company?.permission;

    const permission = pageMode === "view" ? "read" : companyPermission;

    if (pageMode === "edit") {
      return (
        <Card
          children={
            <section>
              <div className={styles.title}>VENUE</div>
              <hr className={styles.hr} />

              {isV2Loading ? (
                <div className={styles.loader}>
                  <Loader />
                </div>
              ) : (
                <>
                  <div className={styles.row}>
                    <div className={styles.leftCol}>
                      <span className={styles.label}>No Event Venue</span>
                      <span className={styles.checkbox}>
                        <Checkbox
                          onChange={handleNoVenue}
                          checked={!!noVenue}
                        />
                      </span>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.leftCol}>
                      <span className={styles.label}>Filter</span>
                      <span className={styles.filterDropdown}>
                        <Select
                          placeholder={selectedState || "State"} // Placeholder fallback
                          value={selectedState || "Select"} // Ensures controlled behavior
                          options={states}
                          onChange={(value) =>
                            handleStateChange(value || "Select")
                          } // Prevent null/undefined
                          disabled={noVenue}
                          className={noVenue ? styles.disabled : ""}
                        />
                      </span>
                      <span className={styles.filterDropdown}>
                        <Select
                          placeholder={
                            selectedCity === "all"
                              ? "All"
                              : selectedCity || "City"
                          } // Placeholder fallback
                          value={selectedCity || "Select"} // Ensures controlled behavior
                          options={cities}
                          onChange={(value) =>
                            handleCityChange(value || "Select")
                          } // Prevent null/undefined
                          disabled={
                            !selectedState ||
                            selectedState === "Select" ||
                            noVenue
                          }
                          className={
                            !selectedState ||
                            selectedState === "Select" ||
                            noVenue
                              ? styles.disabled
                              : ""
                          }
                        />
                      </span>
                    </div>
                  </div>

                  <div className={styles.row}>
                    <div className={styles.rightCol}>
                      <span className={styles.label}>Venue *</span>
                      <span className={styles.venueDropdown}>
                        <Select
                          placeholder={selectedVenue || "Select"} // Fallback to "Select" if selectedVenue is undefined or null
                          value={selectedVenue || "Select"} // Fallback ensures no uncontrolled behavior
                          options={["Select", ...venueNames]} // Ensure "Select" is always an option
                          onChange={(value) => {
                            setSelectedVenue(value || "Select"); // Safeguard the state update
                            handleVenueChange(value);
                          }}
                          disabled={!selectedState || !selectedCity || noVenue}
                          className={
                            !selectedState || !selectedCity || noVenue
                              ? styles.disabled
                              : ""
                          }
                        />
                      </span>
                      {isVenuesDifferent &&
                        !isLoadingVenue &&
                        venueInfo &&
                        !noVenue &&
                        selectedVenue &&
                        selectedVenue !== "Select" && (
                          <span className={styles.warningText}>
                            This venue data differs from the data in Netsuite,
                            do you want to update?
                            <Button
                              className={styles.updateBtn}
                              children="Update"
                              onClick={() => handleUpdate()}
                            />
                          </span>
                        )}
                    </div>
                  </div>

                  <div className={styles.row}>
                    {isLoadingVenue ? (
                      <div className={styles.loader}>
                        <Loader />
                      </div>
                    ) : (
                      <div className={styles.leftCol}>
                        <span className={styles.label} />

                        {venueInfo &&
                        !noVenue &&
                        selectedVenue &&
                        selectedVenue !== "Select" ? (
                          <div className={styles.infoCard}>
                            {isVenuesDifferent ? (
                              <>
                                <VenueInfoCard
                                  venueInfo={venueInfo.venue}
                                  isV2={true}
                                  onEdit={(newVenueData, newRoomData) => {
                                    setIsLoadingVenue(true);
                                    handleVenueEdit(newVenueData, newRoomData);
                                  }}
                                />
                                <VenueInfoCard
                                  venueInfo={transformVenueData(
                                    v2Data
                                      ? v2Data.find(
                                          (v) =>
                                            v?.netSuiteId ==
                                            (venueInfo.venue?.company?.saved
                                              ?.netSuiteId ||
                                              venueInfo.venue?.company?.value
                                                ?.netSuiteId)
                                        ) || {}
                                      : {}
                                  )}
                                  redBorder={true}
                                />
                              </>
                            ) : (
                              <VenueInfoCard
                                venueInfo={venueInfo.venue}
                                isV2={true}
                                onEdit={(newVenueData, newRoomData) => {
                                  setIsLoadingVenue(true);
                                  handleVenueEdit(newVenueData, newRoomData);
                                }}
                              />
                            )}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                </>
              )}
            </section>
          }
        />
      );
    }

    if (pageMode === "view") {
      return <VenueCard venueInfo={venueInfo} />;
    }

    if (permission === "hidden") {
      return null;
    }
  };

  return <>{renderVenueCard()}</>;
}

VenueV2Form.propTypes = {
  //Value of state select
  state: PropTypes.string,
  //sets the state select
  setState: PropTypes.func,
  //value of city select
  city: PropTypes.string,
  //sets the city select
  setCity: PropTypes.func,
  //value of venue select
  venue: PropTypes.string,
  //sets the venue select
  setVenue: PropTypes.func,
  //Boolean that decides if there is no event venue
  noVenue: PropTypes.bool,
  //sets noVenue to true/false
  setNoVenue: PropTypes.func,
  //Function called when add new venue is clicked
  addNewVenue: PropTypes.func,
};
