import Card from "shared/Card";
import { Column, LabelGroup, Row, Section } from "shared/layout";
import { TabContainer, Tab } from "shared/TabContainer";
import Button, { ButtonPurpose, ButtonSize } from "shared/Button";
import Table from "shared/Table";

import styles from "./index.module.css";
import Dinero from "dinero.js";
import formatDate from "shared/@utils/formatDate";
import Security from "./Security/Security";
import Session from "./Session/Session";
import Location from "./Location/Location";
import EventInfo from "./EventInfo/EventInfo";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import OnSite from "./OnSite/OnSite";
dayjs.extend(utc);
dayjs.extend(timezone);

export default function Details({ eventInfo }) {
  const {
    eventId,
    displayName,
    city,
    strAbbr,
    eventStartDate,
    eventDates,
    eventPricing,
    timeZone,
    // company: { address },
    company: { name: companyName, address },
    isUnderConstruction,
    isWebAvailable,
    isAvailableForPurchase,
    isQA,
    platinumCapacity,
    guestCapacity,
    scanningGuid,
    scanningSessions,
    scanningLocations,
    scanningSecurities,
  } = eventInfo;

  const location = `${city}${strAbbr ? `, ${strAbbr}` : ""}`;
  const checkInStartsOn = eventInfo.eventDates.find((a) => a.dateTypeId === 22);
  const checkInEndsOn = eventDates.find((a) => a.dateTypeId === 23);

  const format = "$0,0.00";

  var timezoneAbbr = "";
  switch (timeZone) {
    case "America/Los_Angeles":
      timezoneAbbr = "PT";
      break;
    case "America/Boise":
      timezoneAbbr = "MT";
      break;
    case "America/Chicago":
      timezoneAbbr = "CT";
      break;
    case "America/New_York":
      timezoneAbbr = "ET";
      break;
    case "America/Anchorage":
      timezoneAbbr = "AKT";
      break;
    case "America/Adak":
      timezoneAbbr = "HAT";
      break;
    case "Pacific/Honolulu":
      timezoneAbbr = "HT";
      break;

    default:
      timezoneAbbr = "PT";
      break;
  }

  function formatDateTime(utcDate) {
    return dayjs(utcDate)
      .tz(timeZone)
      .format(`MM/DD/YY hh:mm A [${timezoneAbbr}]`);
  }

  function formatCurrency(amount) {
    //dinero amount is always 1000 for $10
    return Dinero({ amount: amount * 100, currency: "USD" }).toFormat(format);
  }

  function formatYesOrNo(isTrue) {
    return isTrue ? "YES" : "NO";
  }

  return (
    <div>
      <header className={styles.eventHeader}>
        <span className={styles.headerEventType}>{displayName}</span>
        <h1>
          {city}
          {strAbbr ? ` ${strAbbr}` : ""} - {eventStartDate}
        </h1>
      </header>
      <Card>
        <EventInfo eventInfo={eventInfo}></EventInfo>
      </Card>
      <Card>
        <Section>
          <header>
            <h3>Dates</h3>
          </header>
          <Table>
            <thead>
              <tr>
                <th>Date Type</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {eventDates &&
                eventDates.length > 0 &&
                eventDates.map((a) => {
                  return (
                    <tr key={a.dateTypeId || a.dateType}>
                      <td>{a.dateType}</td>
                      <td>{formatDateTime(a.startsOn)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Section>
      </Card>
      <Card>
        <Section>
          <header>
            <h3>Pricing</h3>
          </header>
          <Table>
            <thead>
              <tr>
                <th>Price Type</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {eventPricing &&
                eventPricing.length > 0 &&
                eventPricing.map((a) => {
                  return (
                    <tr key={a.priceType}>
                      <td>{a.priceType}</td>
                      <td>{formatCurrency(a.price)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Section>
      </Card>
      <TabContainer>
        <Tab title="Web/Mobile" value="online">
          <Section>
            <header>
              <h3>Event Info</h3>
              {/* <div>
                <Button size="small">Edit</Button>
              </div> */}
            </header>
            <Row>
              <Column>
                <LabelGroup title="Display Name">{location}</LabelGroup>
              </Column>
              <Column>
                <LabelGroup title="Options">
                  Under Construction: {formatYesOrNo(isUnderConstruction)}
                  <br />
                  Web Available: {formatYesOrNo(isWebAvailable)}
                  <br />
                  Available For Purchase:{" "}
                  {formatYesOrNo(isAvailableForPurchase)}
                  {/*                   
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
                    <label class="form-check-label"> Web Available</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
                    <label class="form-check-label"> Under Construction</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
                    <label class="form-check-label"> Available For Purchase</label>
                  </div> */}
                </LabelGroup>
              </Column>
              <Column>
                <LabelGroup title="Capacity">
                  Sold Out: {formatYesOrNo(!isAvailableForPurchase)}
                  <br />
                  Comp PM Capacity: ????
                  <br />
                  Platinum Capacity: {platinumCapacity}
                  {/* <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
                    <label class="form-check-label"> Sold Out</label>
                  </div>
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="0" />
                    <span class="input-group-text"> Comp PM Capacity</span>
                  </div>
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="0" />
                    <span class="input-group-text"> Platinum Capacity</span>
                  </div> */}
                </LabelGroup>
              </Column>
            </Row>
          </Section>
        </Tab>
        <Tab title="Onsite" value="onsite">
          <OnSite
            // eventId={eventId}
            // scanningGuid={scanningGuid}
            // checkInStartsOn={checkInStartsOn}
            // checkInEndsOn={checkInEndsOn}
            // formatDateTime={formatDateTime}
            // timezone={timeZone}
            // // lastDateId={lastDateId}

            eventId={eventId}
            scanningGuid={scanningGuid}
            checkInStartsOn={checkInStartsOn}
            checkInEndsOn={checkInEndsOn}
            formatDateTime={formatDateTime}
            timezone={timeZone}
          ></OnSite>

          <Session
            eventId={eventId}
            scanningSessions={scanningSessions}
            formatDateTime={formatDateTime}
          ></Session>
          <Location
            eventId={eventId}
            scanningLocations={scanningLocations}
          ></Location>
        </Tab>
        <Tab title="Security" value="security">
          <Security
            eventId={eventId}
            scanningSecurities={scanningSecurities}
          ></Security>
        </Tab>
      </TabContainer>
    </div>
  );
}
